import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-hardware-key',
  templateUrl: './hardware-key.component.html',
  styleUrls: ['./hardware-key.component.scss']
})
export class HardwareKeyComponent{

  @Output("previousStep") previousStep = new EventEmitter();
  @Input() data: any;
  hardwareKey = '';
  disabled = false;
  

  constructor(private _snackBar: MatSnackBar, private router: Router, private encryptDecrypt: EncryptDecryptService, private containerService: ContainerService, private dexieService: DexieService, private localstorage: LocalStorageService) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next() {
    if(this.hardwareKey.trim().length>0){
        //! here we add create container
        let hkSliced = this.hardwareKey.trim().slice(0, 12);
        let ownerKey = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), hkSliced));
        let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(ownerKey), hkSliced);
        let user = JSON.parse(this.localstorage.getUser());

        this.containerService.addContainer({ ...this.data, ownerEncryptionMode: '3', ownerKey: JSON.stringify(ownerKey) }).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any) => {
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
              this.containerService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
            });
            this.openSnackBar('Container added successfully!');
            this.disabled = false;
            this.router.navigate(['containers']);
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabled = false;
          },
        });
    }else{
      this.openSnackBar('Hardware Key is required!');
    }

  }

  back(){
      this.previousStep.emit();
  }
}
