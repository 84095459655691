import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { OrganizationService } from 'src/app/services/organization/organization.service'
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-organization-simple',
  templateUrl: './organization-simple.component.html',
  styleUrl: './organization-simple.component.scss',
   providers: [DatePipe]
})
export class OrganizationSimpleComponent {
  isHidden = true
  selectedFilter = "TeamMates"
  organization: any
  currentUser: any

  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }
  get dark(): any {
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private route: ActivatedRoute, private _snackBar: MatSnackBar, private sanitizer: DomSanitizer, private organizationService: OrganizationService, private localStorage: LocalStorageService, private datePipe: DatePipe) {
    this.currentUser = JSON.parse(this.localStorage.getUser())
    this.getOrganizationDetails()
  }


  formatDate(date: Date) {
    return this.datePipe.transform(date, 'dd MMM YYYY');
  }


  toggleHideOrgIdentifier() {
    this.isHidden = !this.isHidden
  }

  getOrganizationDetails() {
    this.organizationService.getOrgDetailsAsSimpleUser(this.currentUser.id, this.selectedOrganization.id)
      .subscribe({
        next: (res: any) => {
          this.organization = res.data
        },
        error: (error: HttpErrorResponse) => {
          console.error('getting Organization failed error : ', error);

        }
      });
  }

}
