import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerService } from 'src/app/services/container/container.service';
import { DeadManSwitchService } from 'src/app/services/dead-man-switch/dead-man-switch.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-backup-person',
  templateUrl: './backup-person.component.html',
  styleUrls: ['./backup-person.component.scss']
})
export class BackupPersonComponent implements OnInit {

  decrypted: any;
  timerList: any;
  reminderList: any;
  frequencyList: any;
  passphrase = '';
  confirmPassphrase = '';
  errorLength = true;
  errorMatch = true;
  disabledButton = false;
  containerSearch = '';
  recipient = '';
  hardwareKey = '';
  message = '';
  binary = '';
  recipientKey = '';
  encryptionMode = '';
  password = '';
  email = '';
  messageToEdit = '';
  recipientOfEdit = '';
  timer = '';
  frequency = '';
  reminder = '';
  index = 0;
  show = false;
  activated = false;
  activeNone = true;
  activeP = false;
  activeH = false;
  iconColor = '#344056';
  @ViewChild('passphraseDialog') passphraseDialog: TemplateRef<any>;
  @ViewChild('hardwareKeyDialog') hardwareKeyDialog: TemplateRef<any>;
  @ViewChild('masterPasswordDialog') masterPasswordDialog: TemplateRef<any>;
  @ViewChild('recipientPassphraseDialog') recipientPassphraseDialog: TemplateRef<any>;
  @ViewChild('recipientHardwareKeyDialog') recipientHardwareKeyDialog: TemplateRef<any>;
  @ViewChild('recipientMessageDialog') recipientMessageDialog: TemplateRef<any>;
  @ViewChild('recipientEncryptionModeDialog') recipientEncryptionModeDialog: TemplateRef<any>;
  @ViewChild('recipientEditMessageDialog') recipientEditMessageDialog: TemplateRef<any>;
  @ViewChild('timerDialog') timerDialog: TemplateRef<any>;
  
  get dark(): any{
    if(this.theme.dark) this.iconColor = '#5E6A74';
    else this.iconColor = '#344056';
    return this.theme.dark;
  }

  get ownContainers(){
    return this.containerService.ownContainers;
  }

  constructor(private theme: ThemeService, private localstorage: LocalStorageService, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, public dialog: MatDialog, private deadManSwitch: DeadManSwitchService, private containerService: ContainerService, private dexieService: DexieService, private userService: UserService) {
    this.email = this.localstorage.getEmail();
  }

  ngOnInit(): void {
    let defaultTimer = JSON.parse(this.localstorage.getTimerData());
    this.timerList = defaultTimer.timerList;
    this.frequencyList = defaultTimer.frequencyList;
    this.reminderList = defaultTimer.reminderList;
    let userData = JSON.parse(this.localstorage.getUser());
    this.reminder = userData.reminder.toString();
    this.frequency = userData.frequency.toString();
    this.timer = userData.timer.toString();
  }

  toggleActive(a: any) {
    switch(a){
      case 'P': this.activeP = true; this.activeH = false; this.activeNone = false; break;
      case 'H': this.activeH = true; this.activeP = false; this.activeNone = false; break;
      // case 'None': this.activeNone = true; this.activeP = false; this.activeH = false; break;
    }
  }

  onKey(event: any) {
    this.recipient = event.target.value;
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }

  openDialog(element: any, maxWidth: any, height: any){
    this.dialog.open(element, {
      width: '90%',
      maxWidth,
      minWidth: '350px',
      autoFocus: false,
      height: height
    });
  }

  async updateContainer(index: any, activated: any){
    this.activated = activated;
    this.index = index;
    if(activated){
      if(this.recipient==""){
        this.openSnackBar('A recipient is missing! Please provide a valid email address for your recipient.');
      }else if(this.localstorage.getEmail()==this.recipient.trim()){
        this.openSnackBar('It should be a different email not yours!');
      }else if (!this.recipient.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
        this.openSnackBar('Please enter a valid email!');
      }else if(this.ownContainers[index].ownerEncryptionMode===2){ // verify passphrase
          this.dialog.open(this.passphraseDialog, { width: '450px' });
      }else if(this.ownContainers[index].ownerEncryptionMode===3){ // verify hardware key
          this.dialog.open(this.hardwareKeyDialog, { width: '450px' });
      }else{ // standard

          if(this.localstorage.getMasterPassword()){
            this.decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data), this.localstorage.getMasterPassword());
            this.openDialog(this.timerDialog, '850px', '730px');
          }else{
            this.dialog.open(this.masterPasswordDialog, { width: '450px' });
          }
      }
    }else{
      this.disabledButton = true;
      let data = {containerID: this.ownContainers[index].id, recipient: "", activated: false, active: false, message: "", encryptionMode: "", encryptionKey: "", timer: null, frequency: null, reminder: null, startDate: null, expirationDate: null};
      this.deadManSwitch.addDeadManSwitch(data)
          .subscribe({
            next: (res: any)=>{
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let d = data;
                  d[this.index] = {...d[this.index], timer: null, frequency: null, reminder: null, deadManSwitch: false, backUpPerson: '', backUpPersonActive: false, backUpPersonEncryptionMode: 0, message: ''}
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.disabledButton = false;
                  this.openSnackBar("Backup person set up successfully!");  
              });
            },
            error: (error: HttpErrorResponse)=>{
              this.disabledButton = false;
              this.openSnackBar('Some problem occured!');
            }
          });
    }
  }

  async updateRSAKeysForUsersThatHasAlreadyMP(userData: any){
    if(!userData.rsaKeysUpdated){
        const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
        let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
        let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
        this.localstorage.setPublicKey(base64PublicKey);
        this.localstorage.setPrivateKey(base64PrivateKey);
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
        let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
        let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true};
        this.userService.updateProfile(data, this.localstorage.getEmail())
          .subscribe(async (res: any)=>{
            this.localstorage.setUser(JSON.stringify(res.user)); 
          }); 
    }else{
      // do nothing
    }
  }
  async verifyMasterPassword(){
    try{
      let userData = JSON.parse(this.localstorage.getUser());
      let hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(userData['publicKey']);
      await this.updateRSAKeysForUsersThatHasAlreadyMP(userData);
      this.decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.ownContainers[this.index].ownerKey).data), this.localstorage.getMasterPassword());
      this.password = '';
      this.dialog.closeAll();
      this.openDialog(this.timerDialog, '850px', '730px');
    }catch(err){
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
      this.openSnackBar('Wrong Master Password!');
    }
  }

  async verifyPassphrase(){
    try{
      this.decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.ownContainers[this.index].ownerKey).data), await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
      this.passphrase = "";
      this.dialog.closeAll();
      this.openDialog(this.timerDialog, '850px', '730px');
    }catch(err){
      this.openSnackBar('Wrong passphrase!');
    }
  }

  async verifyHardwareKey(){
    try{
      this.decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.ownContainers[this.index].ownerKey).data), this.hardwareKey.trim().slice(0,12));
      this.hardwareKey = "";
      this.dialog.closeAll();
      this.openDialog(this.timerDialog, '850px', '730px');
    }catch(err){
      this.openSnackBar('Wrong hardware key!');
    }
  }

  choose(){
    if(this.activeP){
      this.dialog.closeAll();
      this.dialog.open(this.recipientPassphraseDialog, { width: '450px'});
    }else if(this.activeH){
      this.dialog.closeAll();
      this.dialog.open(this.recipientHardwareKeyDialog, { width: '450px'});
    }
  }

  async addPassphrase(){
    if(this.passphrase.trim().length>0){
      let encrypted = await this.encryptDecrypt.encryptKey(this.decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
      this.recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
      this.encryptionMode = "2";
      this.dialog.open(this.recipientMessageDialog, { width: '90%', maxWidth: '600px', minWidth: '350px', height: '610px'});
      this.passphrase = '';
      this.confirmPassphrase = '';
    }else{
      this.openSnackBar('Please enter a passphrase!');
    }
  }

  async addHardwareKey(){
    if(this.hardwareKey.trim().length>0){
      let encrypted = await this.encryptDecrypt.encryptKey(this.decrypted, this.hardwareKey.trim().slice(0, 12));
      this.recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
      this.encryptionMode = "3";
      this.dialog.open(this.recipientMessageDialog, { width: '90%', maxWidth: '600px', minWidth: '350px', height: '610px'});
      this.hardwareKey = '';
    }else{
      this.openSnackBar('Please enter a hardware key!');
    }
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }

  onChangeConfirm(event: any){
    if((this.passphrase.trim()===this.confirmPassphrase.trim()) && (this.passphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.passphrase.trim()!==this.confirmPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  add(){
    this.disabledButton = true;
    let data = {containerID: this.ownContainers[this.index].id, recipient: this.recipient, activated: true, active: false, message: this.message, encryptionMode: this.encryptionMode, encryptionKey: this.recipientKey, timer: this.timer, frequency: this.frequency, reminder: this.reminder};
    this.deadManSwitch.addDeadManSwitch(data)
        .subscribe({
          next: (res: any)=>{
              this.dexieService.getOwnContainers().then((data: any)=>{
                  let d = data;
                  d[this.index] = {...d[this.index], timer: this.timer, frequency: this.frequency, reminder: this.reminder, deadManSwitch: true, backUpPerson: this.recipient, backUpPersonActive: false, backUpPersonEncryptionMode: Number(this.encryptionMode), message: this.message};
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.disabledButton = false;
                  this.dialog.closeAll();
                  this.openSnackBar("Backup person set up successfully!"); 
              });
          },
          error: (error: HttpErrorResponse)=>{
            this.disabledButton = false;
            this.openSnackBar('Some problem occured!');
          }
        });
  }

  openEditMessage(i: any){
    this.index = i;
    this.messageToEdit = this.ownContainers[this.index].message;
    this.recipientOfEdit = this.ownContainers[this.index].backUpPerson;
    this.dialog.open(this.recipientEditMessageDialog, { width: '90%', maxWidth: '600px', minWidth: '350px', height: '500px'});
  }

  editMessage(){
    this.disabledButton = true;
    this.deadManSwitch.updateDeadManSwitch(this.ownContainers[this.index].id, {message: this.messageToEdit})
    .subscribe({
      next: (res: any) => {
        this.dexieService.getOwnContainers().then((data: any)=>{
          let d = data;
          d[this.index] = {...d[this.index], message: this.messageToEdit};
          this.dexieService.setOwnContainers(d);
          this.containerService.setOwnContainers(d);
          this.disabledButton = false;
          this.dialog.closeAll();
          this.openSnackBar("Message updated successfully!"); 
      });
      },
      error: (error: HttpErrorResponse)=>{
        this.disabledButton = false;
        this.openSnackBar('Some problem occured!');
      }
    })

  }

  openRecipientEncryptionMode(){
    this.dialog.closeAll();
    this.openDialog(this.recipientEncryptionModeDialog, '850px', '730px');
  }
}
