<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="selectedSidebar" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">

        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div class="password-details-wrapper">
            <button class="back-button-wrapper" (click)="goBack()">
                <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
                <span>Back</span>
            </button>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="full-width">
                <div class="title">Password Details</div>
                <div *ngIf="user.id==selectedPasswordData?.owner" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" [ngClass]="{'opaque': disabled, '': !disabled}">
                    <button class="delete-button" (click)="deleteItem()"> Move To Trash</button>
                    <button class="new-container-button" (click)="(disabled || disableEdit) ? editItem() : save()">{{(disabled || disableEdit) ? "Update" : "Save"}}</button>
                </div>
            </div>

            <div class="containers-devider"></div>

            <div class="content-wrapper">
                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Icon</span>
                        <div class="content">
                            <div class="group" for="myfile">
                                <label for="myfile" class="file-label">
                                    <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                                        <img *ngIf="preview!=null" class="profile-photo" [src]="preview" alt="container-icon">
                                        <img *ngIf="preview==null" class="profile-photo" [src]="selectedPasswordData?.icon?.data" alt="default-icon">
                                        <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" *ngIf="!disabled && !disableEdit" />
                                    </div>
                                </label>

                                <input type="file" id="myfile" (change)="onIconChange($event)" accept="image/*" [disabled]="disabled || disableEdit" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Name</span>
                        <div class="content">
                            <input *ngIf="disabled || disableEdit" autocomplete="off" class="dialog-input"
                                placeholder="Name" [ngModel]="selectedPasswordData?.name" [disabled]="true" />
                            <input *ngIf="!disabled && !disableEdit" autocomplete="off" class="input-highlighted"
                                placeholder="Name" [(ngModel)]="name" [disabled]="false" />
                        </div>

                    </div>


                </div>
                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">User Name</span>
                        <div class="content">
                            <input *ngIf="disabled || disableEdit" autocomplete="off" class="dialog-input"
                                placeholder="User Name" [ngModel]="selectedPasswordData?.userName" [disabled]="true" />
                            <input *ngIf="!disabled && !disableEdit" autocomplete="off" class="input-highlighted"
                                placeholder="User Name" [(ngModel)]="userName" [disabled]="false" />
                        </div>

                    </div>


                </div>
                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Url</span>
                        <div class="url-wrapper content">
                            <div class="extract-wrapper">
                                <label class="form-label"
                                    (click)="(disabled || disableEdit) ? '' : extractFavicon()">extract icon
                                </label>
                            </div>
                            <input *ngIf="disabled || disableEdit" autocomplete="off" class="dialog-input password"
                                placeholder="Url" [ngModel]="selectedPasswordData?.url" [disabled]="true" />
                            <input *ngIf="!disabled && !disableEdit" autocomplete="off"
                                class="input-highlighted password" placeholder="Url" [(ngModel)]="url"
                                [disabled]="false" />
                        </div>
                    </div>

                </div>
                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Password</span>
                        <div class="dialog-input-wrapper content">
                            <input *ngIf="disabled || disableEdit" [type]="show ? 'text' : 'password'"
                                autocomplete="off" class="dialog-input" placeholder="Password"
                                [ngModel]="selectedPasswordData?.password" [disabled]="true" />
                            <input *ngIf="!disabled && !disableEdit" [type]="show ? 'text' : 'password'"
                                autocomplete="off" class="input-highlighted" placeholder="Password"
                                [(ngModel)]="password" [disabled]="false" />
                            <img class="hide-show-button"
                                [src]="show ? 'assets/images/show.svg': 'assets/images/hide.svg'" alt="show-hide"
                                (click)="toggleShow()" />
                        </div>
                    </div>

                </div>

                <div class="containers-devider"></div>

                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Notes</span>
                        <div class="content">
                            <quill-editor class="full-width" *ngIf="disabled || disableEdit" [ngModel]="selectedPasswordData?.notes?.data" [modules]="modules" [readOnly]="true"></quill-editor>
                            <quill-editor class="full-width" *ngIf="!disabled && !disableEdit" [(ngModel)]="notesData" [modules]="modules" (onContentChanged)="onContentChanged($event)" [readOnly]="false"></quill-editor>
                        </div>
                    </div>
                </div>

                <div class="containers-devider"></div>


                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Files</span>
                        <div class="content files-wrapper" *ngIf="!disabled && !disableEdit">
                            <label for="fileInput" class="import-container" fxLayout="column" fxLayoutGap="5px"
                                fxLayoutAlign="center center">
                                <img src="assets/images/dashboard/container-content/import-icon.svg" alt="alt">
                                <p class="file-input">Import File</p>

                                <input id="fileInput" type="file" style="display: none;" (change)="onFileChange($event)"
                                    [disabled]="disabled || disableEdit" />
                            </label>

                            <div *ngIf="filesToShow.length>0" fxLayout="column" fxLayoutGap="15px" style="width: 100%;">
                                <div *ngFor="let file of filesToShow; let i = index;" fxLayout="row"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" class="file-container">
                                    <div fxLayoutAlign="start center" fxLayoutGap="8px">
                                        <img [src]="file.icon" alt="file-icon" width="30px" />
                                        <a class="form-label" [href]="file.data"
                                            [download]="file.name + file.extension">{{file.name}}{{file.extension}}</a>
                                    </div>

                                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon"
                                        (click)="removeFile(i)" class="pointer" />
                                </div>
                            </div>
                        </div>
                        <div class="content files-wrapper" *ngIf="disabled || disableEdit">
                            <div *ngIf="selectedPasswordData?.files?.length>0" fxLayout="column" fxLayoutGap="15px" style="width: 100%;">
                                <div *ngFor="let file of selectedPasswordData?.files; let i = index;" fxLayout="row"
                                    fxLayoutAlign="space-between center" fxLayoutGap="5px" class="file-container">
                                    <div fxLayoutAlign="start center" fxLayoutGap="8px">
                                        <img [src]="file.icon.data" alt="file-icon" width="30px" />
                                        <span class="form-label">{{file.name}}{{file.extension}}</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedPasswordData?.files?.length==0" class="label">
                                No files added yet
                            </div>
                        </div>
                    </div>
                </div>

                <div class="containers-devider"></div>

                <div class="password-section">
                    <div class="label-content-wrapper">
                        <span class="label">Comments</span>
                        <div class="content">
                            <div fxLayout="row" class="comment-box">
                                <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                                    <span fxLayout="row"
                                        *ngIf="(!user.profilePicture || user.profilePicture.trim()==='-' || user.profilePicture.trim()==='_' || user.profilePicture.trim().length===0) && (user.firstName!=='' || user.lastName!=='')"
                                        fxLayoutAlign="center center"><span>{{user.firstName!.toUpperCase().slice(0,1)}}{{user.lastName!.toUpperCase().slice(0,1)}}</span>
                                    </span>
                                    <span fxLayout="row"
                                        *ngIf="(!user.profilePicture || user.profilePicture.trim()==='-' || user.profilePicture.trim()==='_' || user.profilePicture.trim().length===0) && (user.firstName==='' && user.lastName==='')"
                                        fxLayoutAlign="center center"><span>{{user.email.toUpperCase().slice(0,1)}}{{user.email.toUpperCase().slice(0,1)}}</span>
                                    </span>
                                    <img *ngIf="user.profilePicture && user.profilePicture.trim() !== '-' && user.profilePicture.trim() !== '_'"
                                        alt="profile" [src]="user.profilePicture" referrerpolicy="no-referrer" />
                                </div>

                                <div style="position: relative; flex: 1;">
                                    <input autocomplete="off" class="dialog-input input-comment" type="text" placeholder="Add a comment..." [(ngModel)]="comment" />
                                    <img class="add-comment-button" src="assets/images/dashboard/container-content/sendcomment.svg" alt="alt" (click)="addComment()" />
                                </div> 
                            </div>

                            <div *ngIf="selectedPasswordData?.comments?.length>0" fxLayout="column" fxLayoutGap="10px">
                                <div *ngFor="let c of selectedPasswordData?.comments; let i=index;">
                                    <div fxLayout="row" class="comment-section" >
                                        <div class="img-wrapper" fxLayout="row" fxLayoutAlign="center center">
                                            <span fxLayout="row" fxLayoutAlign="center center"
                                                *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName==null && c.owner.lastName==null)"
                                                [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}"><span>{{c.owner.email.toUpperCase().slice(0,1)}}{{c.owner.email.toUpperCase().slice(0,1)}}</span></span>
                                            <span fxLayout="row" fxLayoutAlign="center center"
                                                *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName==null && c.owner.lastName!=null)"
                                                [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}"><span>{{c.owner.lastName!.toUpperCase().slice(0,1)}}</span></span>
                                            <span fxLayout="row" fxLayoutAlign="center center"
                                                *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName!=null && c.owner.lastName==null)"
                                                [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}"><span>{{c.owner.firstName!.toUpperCase().slice(0,1)}}</span></span>
                                            <span fxLayout="row" fxLayoutAlign="center center"
                                                *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName!=null && c.owner.lastName!=null)"
                                                [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}"><span>{{c.owner.firstName!.toUpperCase().slice(0,1)}}{{c.owner.lastName!.toUpperCase().slice(0,1)}}</span></span>
                                            <img *ngIf="c.owner.profilePicture && c.owner.profilePicture.trim()!=='-' && c.owner.profilePicture.trim()!=='_' && c.owner.profilePicture.trim().length!==0"
                                                class="comment-user-1" alt="profile" [src]="c.owner.profilePicture" />
                                        </div>

                                        <div class="comment-content">
                                            <div class="comment-top">
                                                <span class="comment-username"
                                                    *ngIf="c.owner.firstName!==null || c.owner.lastName!==null">
                                                    {{c.owner.firstName}} {{c.owner.lastName}}
                                                </span>
                                                <div class="comment-top-right">
                                                    <span class="comment-date">
                                                        {{timeDifference(c.createdAt)}}
                                                    </span>

                                                    <div class="dropdown-container" *ngIf="c.owner.id==user.id">
                                                        <button mat-button [matMenuTriggerFor]="menu">
                                                            <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                                        </button>
                                                        <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                                                            <button mat-menu-item>
                                                                <div class=" option" (click)="editComment(i)">
                                                                    <img [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                                        alt="edit" />
                                                                    <a>Edit</a>
                                                                </div>
                                                            </button>
                                                            <button mat-menu-item (click)="deleteComment(i)">
                                                                <div class=" option">
                                                                    <img [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                                        alt="delete" />
                                                                    <a>Delete</a>
                                                                </div>
                                                            </button>
                                                        </mat-menu>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="comment-text" *ngIf="!editC[i]"> {{c.comment}} </div>
                                            <div *ngIf="editC[i]" fxLayout="column" fxLayoutGap="10px">
                                                <input autocomplete="off" *ngIf="editC[i]" class="comment-edit-input" [(ngModel)]="newComment" />
                                                <div  fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
                                                    <button class="comment-cancel-btn" (click)="cancelEditComment()">Cancel</button>
                                                    <button class="comment-save-btn" (click)="saveEditComment(i)">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="containers-devider"></div>

            </div>

        </div>

    </mat-sidenav-content>
</mat-sidenav-container>


<!-- delete item -->
<ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content">
        <div *ngIf="disabled" class="flex  justify-center items-center justify-items">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
        <div *ngIf="!disabled" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <img src="assets/images2/dashboard/delete-icon.svg" />

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    (click)="cancelDeleteItem()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
            </div>
            <span class="delete-dialog-title">Move Item to Trash</span>
            <span class="delete-dialog-description">Are you sure to move this item to trash</span>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
            [ngClass]="{'opaque': disabled, '': !disabled}">
            <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabled">Cancel</button>
            <button class="save-btn delete full-width" (click)="moveToTrash()" [disabled]="disabled">Move to Trash</button>
            </div>
        </div>

        </div>
    </mat-dialog-content>
</ng-template>