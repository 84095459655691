import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-master-password',
  templateUrl: './add-master-password.component.html',
  styleUrls: ['./add-master-password.component.scss']
})
export class AddMasterPasswordComponent{

  masterPassword = '';
  confirmMasterPassword = '';
  show = false;
  disabled = false;
  @Input() isNew: any;
  @Input() data: any;
  @Output("previousStep") previousStep = new EventEmitter();
  
  get dark(): any{
    return this.theme.dark;
  }

  constructor(private router: Router, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private theme: ThemeService, private localstorage: LocalStorageService, private userService: UserService, private containerService: ContainerService, private dexieService: DexieService) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	} 

  isPassword(){
    this.show = !this.show;
  }

  async updateRSAKeysForUsersThatHasAlreadyMP(userData: any){
    if(!userData.rsaKeysUpdated){
        const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
        let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
        let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
        this.localstorage.setPublicKey(base64PublicKey);
        this.localstorage.setPrivateKey(base64PrivateKey);
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
        let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
        let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true};
          this.userService.updateProfile(data, this.localstorage.getEmail())
          .subscribe(async (res: any)=>{
            this.localstorage.setUser(JSON.stringify(res.user)); 
          }); 
    }else{
      // do nothing
    }
  }

  async next() {
    if(this.isNew){

      // here we add master password, as well as a public and private keys to the user, and then we generate a key and add it to the data
      if(this.masterPassword.trim().length===0){
            this.openSnackBar('Please enter a password!');
      }else if(this.masterPassword.trim().length<6){
            this.openSnackBar('Master Password should be at least 6 characters!');
      }else if(this.masterPassword.trim()!==this.confirmMasterPassword.trim()){
          this.openSnackBar('Passwords are not matching!');
      }else{
        let user = JSON.parse(this.localstorage.getUser());
        // here we are generating the private and public keys, encrypt the private key, and convert the publick key to base64 format
        const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
        let masterPassHashed = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim());
        // saving the private and public key to the user profile and the localstorage, and then we add the owner key to the data
        let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
        let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
        this.localstorage.setPublicKey(base64PublicKey);
        this.localstorage.setPrivateKey(base64PrivateKey);
        this.localstorage.setMasterPassword(masterPassHashed);

        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(masterPassHashed));
        let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
        let data = { publicKey: btoa(JSON.stringify(extractedPublicKey)), privateKey: privateKeyEncrypted };
        this.userService.updateProfile(data, this.localstorage.getEmail())
              .subscribe(async (res: any) => {
                this.localstorage.setUser(JSON.stringify(res.user));
        });

        //! here we add create container
        let ownerKey = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), masterPassHashed));
        let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(ownerKey), masterPassHashed);

        this.containerService.addContainer({ ...this.data, ownerEncryptionMode: '1', ownerKey: JSON.stringify(ownerKey) }).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any) => {
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: 'own', ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
              this.containerService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: 'own', ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture}}]);
            });
            this.openSnackBar('Container added successfully!');
            this.disabled = false;
            this.router.navigate(['containers']);
          //   if (this.containerService.ownContainers.length == 0) {
          //     this.router.navigate(['containers', 'start-migration']);
          //   } else {
          //     this.router.navigate(['containers']);
          //   }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabled = false;
          },
        });
      }

    }else{

      // here we verify the master password, if it is true we add the owner key to the data 
      try{
        let user = JSON.parse(this.localstorage.getUser());
        let masterPassHashed = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim());
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(masterPassHashed));
        let privateKeyDecrypted = await this.encryptDecrypt.decryptData(user['privateKey'], binarykey);
        this.localstorage.setMasterPassword(masterPassHashed);
        this.localstorage.setPrivateKey(privateKeyDecrypted);
        this.localstorage.setPublicKey(user['publicKey']);
        await this.updateRSAKeysForUsersThatHasAlreadyMP(user);
        
        //! here we add create container
        let ownerKey = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), masterPassHashed));
        let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(ownerKey), masterPassHashed);

        this.containerService.addContainer({ ...this.data, ownerEncryptionMode: '1', ownerKey: JSON.stringify(ownerKey) }).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any) => {
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
              this.containerService.setOwnContainers([...data, { ...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true, type: "own", ownerData: {id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profilePicture: user.profilePicture} }]);
            });
            this.openSnackBar('Container added successfully!');
            this.disabled = false;
            this.router.navigate(['containers']);
            // if (this.containerService.ownContainers.length == 0) {
            //   this.router.navigate(['containers', 'start-migration']);
            // } else {
            //   this.router.navigate(['containers']);
            // }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabled = false;
          },
        });

      }catch(err){
          this.openSnackBar('Wrong master password!');
      }

    }
  }

  back(){
      this.previousStep.emit();
  }

}
