import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { TrashService } from 'src/app/services/trash/trash.service';

@Component({
  selector: 'app-trash-content',
  templateUrl: './trash-content.component.html',
  styleUrls: ['./trash-content.component.scss']
})
export class TrashContentComponent {

  loading = true;
  selectMany = false;
  disabledButton = false;
  @ViewChild('deleteDialog') deleteDialog: any;

  searchString = '';
  trash: any;

  get dark(): any {
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private trashService: TrashService, private _snackBar: MatSnackBar, public dialog: MatDialog, private sanitizer: DomSanitizer, private localstorage: LocalStorageService, private dexieService: DexieService, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private notebookService: ContainerNoteService) {
    this.loading = true;
    this.trashService.getTrash().subscribe({
      next: async (result: any) => {
        this.trash = result.trash;
        this.trash.containers = result?.trash?.containers.map((container) => {
          if (container.icon.data.includes('https://www.google.com/s2/favicons?sz=64') === false && container.icon.data.includes('assets/images/predefined-icons') === false) {
            let my_icon_data = new Uint8Array(JSON.parse(container.icon.data).data);
            let string_icon_char = my_icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let iconBase64String = btoa(string_icon_char);
            let iconData = container.icon.type == 'application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:' + container.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
            return { ...container, icon: { id: container.icon.id, data: iconData } };
          } else {
            return { ...container, icon: { ...container.icon } };
          }
        });
        this.trash.passwords = result.trash.passwords.map((password) => {
          if (password.icon.data.includes('https://www.google.com/s2/favicons?sz=64') === false && password.icon.data.includes('assets/images/predefined-icons') === false) {
            let my_icon_data = new Uint8Array(JSON.parse(password.icon.data).data);
            let string_icon_char = my_icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let iconBase64String = btoa(string_icon_char);
            let iconData = password.icon.type == 'application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:' + password.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
            return { ...password, icon: { id: password.icon.id, data: iconData } };
          } else {
            return { ...password, icon: { ...password.icon } };
          }
        });
        this.trash.notebooks = result.trash.notebooks.map((notebook) => {
          if (notebook.icon.data.includes('https://www.google.com/s2/favicons?sz=64') === false && notebook.icon.data.includes('assets/images/predefined-icons') === false) {
            let my_icon_data = new Uint8Array(JSON.parse(notebook.icon.data).data);
            let string_icon_char = my_icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let iconBase64String = btoa(string_icon_char);
            let iconData = notebook.icon.type == 'application/octet-stream' ? this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:' + notebook.icon.type + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
            return { ...notebook, icon: { id: notebook.icon.id, data: iconData } };
          } else {
            return { ...notebook, icon: { ...notebook.icon } };
          }
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
      }
    });
  }

  // onNotesDeletedFromView(noteId: number) {
  //   this.trash.notes = this.trash.notes.filter(n => n.notebook.id !== noteId);
  // }

  // onNotebookDeletedFromView(notebookId: number) {
  //   this.trash.notebooks = this.trash.notebooks.filter(n => n.id !== notebookId);
  // }

  // onPasswordDeletedFromView(passwordId: number) {
  //   this.trash.passwords = this.trash.passwords.filter(p => p.id !== passwordId);
  // }

  // onContainerDeletedFromView(containerId: number) {
  //   this.trash.containers = this.trash.containers.filter(c => c.id !== containerId);
  // }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }


  toggleSelect() {
    this.selectMany = !this.selectMany;
  }

  emptyTrash() {
    this.disabledButton = true;
    this.trashService.emptyTrash(this.trash.id).subscribe({
      next: async (result: any) => {
        this.trash = { ...this.trash, containers: [], passwords: [], notebooks: [], notes: [] };
        this.openSnackBar('Trash is emptied successfully!');
        this.disabledButton = false;
        this.dialog.closeAll();
      },
      error: (error: HttpErrorResponse) => {
        this.disabledButton = false;
        this.openSnackBar('Error emptying the trash!');
      }
    });
  }

  openDialog() {
    this.dialog.open(this.deleteDialog, { width: '400px' })
  }

  cancelDelete() {
    this.dialog.closeAll();
  }

  restoreContainer(container){
    this.trashService.restoreContainer(this.trash.id, container.id).subscribe({
          next: async (result: any)=>{
              if(container.owner===JSON.parse(this.localstorage.getUser())['id']){
                // we need to ad container data to dexie database
                this.dexieService.getOwnContainers().then((data: any)=>{
                    this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(result.container), type: 'own'}]);
                    this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(result.container), type: 'own'}]);
                });
              }else{
                // we need to ad container data to dexie database
                this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                    this.dexieService.setDeadManSwitchContainers([...data, {...this.containerService.setContainer(result.container), type: 'backup'}]);
                    this.containerService.setDeadManSwitchContainers([...data, {...this.containerService.setContainer(result.container), type: 'backup'}]);
                });
              }
              this.openSnackBar('Container restored successfully!');
              this.trash.containers = this.trash.containers.filter(c => c.id !== container.id);
            },
            error: (error: HttpErrorResponse)=>{
                this.openSnackBar('Error restoring the container!');
            }
        });
  }

  deleteContainer(container){
    this.trashService.deleteContainer(this.trash.id, container.id).subscribe({
      next: async (result: any)=>{
          this.openSnackBar('Container deleted successfully!');
          this.trash.containers = this.trash.containers.filter(c => c.id !== container.id);
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting the container!');
        }
    });
  }

  restorePassword(password){
    this.trashService.restorePassword(this.trash.id, password.id).subscribe({
      next: async (result: any)=>{
          this.openSnackBar('Password restored successfully!');
          let user = JSON.parse(this.localstorage.getUser());
          this.trash.passwords = this.trash.passwords.filter(p => p.id !== password.id);
          if(password.containerID==null){
            this.dexieService.getOwnPasswords().then((data: any)=> {
              let passwords = data;
              passwords = [...passwords, {...password, ownership: 'own', type: 'Password', deleted: false, decrypted: false, containerID: null, container: null, iconData: password.icon, icon: this.containerService.setIcon(password.icon), ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
              this.dexieService.setOwnPasswords(passwords);
              this.containerService.setOwnPasswords(passwords);
            });
          }else{
              // here we should update the data in dexie database 
              this.dexieService.getOwnContainers().then(async(data: any)=>{
                    let index = data.findIndex((el)=> el.id==password.containerID);
                    if(index!=-1){
                      let passwords = data[index].passwords;
                      passwords = [...passwords, {...password, deleted: false, icon: password.icon.id, iconData: password.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
                      data[index] = {...data[index], passwords};
                      this.dexieService.setOwnContainers(data);
                      this.containerService.setOwnContainers(data);
                    }else{
                      this.dexieService.getSharedContainers().then(async (data: any)=>{
                          let index = data.findIndex((el)=> el.id==password.containerID);
                          if(index!=-1){
                            let passwords = data[index].passwords;
                            passwords = [...passwords, {...password, deleted: false, icon: password.icon.id, iconData: password.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
                            data[index] = {...data[index], passwords};
                            this.dexieService.setSharedContainers(data);
                            this.containerService.setSharedContainers(data);  
                          }else{
                            this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                            let index = data.findIndex((el)=> el.id==password.containerID);
                              if(index!=-1){
                                  let passwords = data[index].passwords;
                                  passwords = [...passwords, {...password, deleted: false, icon: password.icon.id, iconData: password.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
                                  data[index] = {...data[index], passwords};
                                  this.dexieService.setDeadManSwitchContainers(data);
                                  this.containerService.setDeadManSwitchContainers(data); 
                              }else{
                                  // The container of this password no longer exist, will add it to own passwords
                                  this.dexieService.getOwnPasswords().then((data: any)=> {
                                    let passwords = data;
                                    passwords = [...passwords, {...password, ownership: 'own', type: 'Password', deleted: false, decrypted: false, containerID: null, container: null, icon: password.icon.id, iconData: password.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
                                    this.dexieService.setOwnPasswords(passwords);
                                    this.containerService.setOwnPasswords(passwords);
                                  });
                              }
                            });
                          }
                      });
                    }
              });
          }
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring the password!');
        }
    });
  }

  deletePassword(password){
    this.trashService.deletePassword(this.trash.id, password.id).subscribe({
      next: async (result: any)=>{
          this.openSnackBar('Password deleted successfully!');
          this.trash.passwords = this.trash.passwords.filter(p => p.id !== password.id);
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error deleting the password!');
        }
    });
  }

  async setNotes(binary: any, notes: any){    
    if(notes.length>0){
      let notesData = notes.map(async (n: any)=>{
        let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
        let nDecrypted = JSON.parse(decryption);
        let size = JSON.parse(n.size);
        return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: n.ownerData, recipients: n.recipients ?? []}
      })
      return await Promise.all(notesData);
    }else{
      return [];
    }
  }

  restoreNotebook(notebook){
    console.log(notebook.icon);
    this.trashService.restoreNotebook(this.trash.id, notebook.id).subscribe({
      next: async (result: any)=>{
          this.openSnackBar('Notebook restored successfully!');
          let user = JSON.parse(this.localstorage.getUser());
          this.trash.notebooks = this.trash.notebooks.filter(n => n.id !== notebook.id);
          if(notebook.containerID==null){
            this.dexieService.getOwnNotebooks().then((data: any)=> {
              let notebooks = data;
              notebooks = [...notebooks, {...notebook, ownership: 'own', type: 'Notebook', deleted: false, decrypted: false, containerID: null, container: null, iconData: notebook.icon, icon: this.containerService.setIcon(notebook.icon), ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
              this.dexieService.setOwnNotebooks(notebooks);
              this.containerService.setOwnNotebooks(notebooks);
            });
          }else{
              // here we should update the data in dexie database 
              this.dexieService.getOwnContainers().then(async(data: any)=>{
                    let index = data.findIndex((el)=> el.id==notebook.containerID);
                    if(index!=-1){
                      let notebooks = data[index].notebooks;
                      notebooks = [...notebooks, {...notebook, deleted: false, icon: notebook.icon.id, iconData: notebook.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}]; 
                      data[index] = {...data[index], notebooks};
                      this.dexieService.setOwnContainers(data);
                      this.containerService.setOwnContainers(data);  
                    }else{
                      this.dexieService.getSharedContainers().then(async (data: any)=>{
                          let index = data.findIndex((el)=> el.id==notebook.containerID);
                          if(index!=-1){
                                let notebooks = data[index].notebooks;
                                notebooks = [...notebooks, {...notebook, deleted: false, icon: notebook.icon.id, iconData: notebook.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}]; 
                                data[index] = {...data[index], notebooks};
                                this.dexieService.setSharedContainers(data);
                                this.containerService.setSharedContainers(data);
                          }else{
                            this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                            let index = data.findIndex((el)=> el.id==notebook.containerID);
                              if(index!=-1){
                                let notebooks = data[index].notebooks;
                                notebooks = [...notebooks, {...notebook, deleted: false, icon: notebook.icon.id, iconData: notebook.icon, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}]; 
                                data[index] = {...data[index], notebooks};
                                this.dexieService.setDeadManSwitchContainers(data);
                                this.containerService.setDeadManSwitchContainers(data);  
                              }else{
                                  // The container of this notebook no longer exist, will add it to own notebooks
                                  this.dexieService.getOwnNotebooks().then((data: any)=> {
                                    let notebooks = data;
                                    notebooks = [...notebooks, {...notebook, ownership: 'own', type: 'Notebook', deleted: false, decrypted: false, containerID: null, container: null, iconData: notebook.icon, icon: this.containerService.setIcon(notebook.icon), ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}}];
                                    this.dexieService.setOwnNotebooks(notebooks);
                                    this.containerService.setOwnNotebooks(notebooks);
                                  });
                              }
                            });
                          }
                      });
                    }
              });
          }
        },
        error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Error restoring the notebook!');
        }
    });
  }

  deleteNotebook(notebook){
    this.trashService.deleteNotebook(this.trash.id, notebook.id).subscribe({
        next: async (result: any)=>{
            this.openSnackBar('Notebook deleted successfully!');
            this.trash.notebooks = this.trash.notebooks.filter(n => n.id !== notebook.id);
          },
          error: (error: HttpErrorResponse)=>{
              this.openSnackBar('Error deleting the notebook!');
          }
      });
  }

  restoreNote(note){
    this.trashService.restoreNote(this.trash.id, note.id).subscribe({
          next: async (result: any)=>{
              this.openSnackBar('Note restored successfully!');
              this.trash.notes = this.trash.notes.filter(n => n.id !== note.id);
              if(note.containerID==null){
                  this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                    let notebooks = data;
                    let notebookIndex = notebooks.findIndex((notebook)=> notebook.id==note.notebookID);
                    notebooks[notebookIndex] = {...notebooks[notebookIndex], notes: [...notebooks[notebookIndex].notes, note.id]};
                    this.dexieService.setOwnNotebooks(notebooks);
                    this.containerService.setOwnNotebooks(notebooks);
                  });
                }else{
                    // here we should update the data in dexie database 
                    this.dexieService.getOwnContainers().then(async(data: any)=>{
                          let index = data.findIndex((el)=> el.id==note.containerID);
                          if(index!=-1){
                            let notebooks = data[index].notebooks;
                            let notebookIndex = notebooks.findIndex((notebook)=> notebook.id==note.notebookID);
                            notebooks[notebookIndex] = {...notebooks[notebookIndex], notes: [...notebooks[notebookIndex].notes, note.id]};
                            data[index] = {...data[index], notebooks};
                            this.dexieService.setOwnContainers(data);
                            this.containerService.setOwnContainers(data); 
                    
                          }else{
                            this.dexieService.getSharedContainers().then(async (data: any)=>{
                                let index = data.findIndex((el)=> el.id==note.containerID);
                                if(index!=-1){
                                    let notebooks = data[index].notebooks;
                                    let notebookIndex = notebooks.findIndex((notebook)=> notebook.id==note.notebookID);
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], notes: [...notebooks[notebookIndex].notes, note.id]};
                                    data[index] = {...data[index], notebooks};
                                    this.dexieService.setSharedContainers(data);
                                    this.containerService.setSharedContainers(data);
                                }else{
                                  this.dexieService.getDeadManSwitchContainers().then(async(data: any)=>{
                                  let index = data.findIndex((el)=> el.id==note.containerID);
                                    if(index!=-1){
                                      let notebooks = data[index].notebooks;
                                      let notebookIndex = notebooks.findIndex((notebook)=> notebook.id==note.notebookID);
                                      notebooks[notebookIndex] = {...notebooks[notebookIndex], notes: [...notebooks[notebookIndex].notes, note.id]};
                                      data[index] = {...data[index], notebooks};
                                      this.dexieService.setDeadManSwitchContainers(data);
                                      this.containerService.setDeadManSwitchContainers(data); 
                                    }else{
                                        // The container of this notebook no longer exist, will add it to own notebooks
                                        this.dexieService.getOwnNotebooks().then(async (data: any)=> {
                                          let notebooks = data;
                                          let notebookIndex = notebooks.findIndex((notebook)=> notebook.id==note.notebookID);
                                          notebooks[notebookIndex] = {...notebooks[notebookIndex], notes: [...notebooks[notebookIndex].notes, note.id]};
                                          this.dexieService.setOwnNotebooks(notebooks);
                                          this.containerService.setOwnNotebooks(notebooks);
                                        });
                                    }
                                  });
                                }
                            });
                          }
                    });
                }
            },
            error: (error: HttpErrorResponse)=>{
                this.openSnackBar('Error restoring the note!');
            }
        });
  }

  deleteNote(note){
    this.trashService.deleteNote(this.trash.id, note.id).subscribe({
        next: async (result: any)=>{
            this.openSnackBar('Note deleted successfully!');
            this.trash.notes = this.trash.notes.filter(n => n.id !== note.id);
          },
          error: (error: HttpErrorResponse)=>{
              this.openSnackBar('Error deleting the note!');
          }
      });
  }
}

