
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { Router } from '@angular/router';
import { RecipientItemService } from 'src/app/services/recipient-item/recipient-item.service';
import { UserService } from 'src/app/services/user/user.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-container-content',
  templateUrl: './container-content.component.html',
  styleUrls: ['./container-content.component.scss']
})
export class ContainerContentComponent implements OnInit{  

  @ViewChild('editor') editor: any;
  @ViewChild('start') start: any;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;
  @ViewChild('deleteDialog') deleteContainerDialog: any;
  @ViewChild('addPasswordDialog') addPasswordDialog: any;
  @ViewChild('addNotebookDialog') addNotebookDialog: any;
  @ViewChild('moveDialog') moveDialog: any;
  @ViewChild('passphrase') passphraseDialog: any;
  @ViewChild('masterPassword') masterPasswordDialog: any;
  @ViewChild('hardwareKey') hardwareKeyDialog: any;
  @ViewChild('questionAnswer') questionAnswerDialog: any;
  @ViewChild('confirmDialog') confirmDialog: any;
  @ViewChild('IconsDialog') IconsDialog: any; 
  @ViewChild('assignmentDialog') assignmentDialog: any;
  @ViewChild('addPassphraseDialog') addPassphraseDialog: any;
  @ViewChild('addHardwareKeyDialog') addHardwareKeyDialog: any;
  @ViewChild('addAnswerQuestionDialog') addAnswerQuestionDialog: any;
  @ViewChild('editDialog') editDialog: any;
  @ViewChild('shareDialog') shareDialog: any;
  @ViewChild('timerDialog') timerDialog: any;
  @ViewChild('changePassphraseDialog') changePassphraseDialog: any;
  @ViewChild('shareItemDialog') shareItemDialog: any;

  iconsArray = [
    {icon: 832, src: 'assets/images/predefined-icons/social-media.svg', name: 'Social Media'},
    {icon: 833, src: 'assets/images/predefined-icons/work.svg', name: 'Work'},
    {icon: 834, src: 'assets/images/predefined-icons/home.svg', name: 'Home'},
    {icon: 835, src: 'assets/images/predefined-icons/emails.svg', name: 'Emails'},
    {icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg', name: 'Credit Cards'},
    {icon: 837, src: 'assets/images/predefined-icons/bank.svg', name: 'Bank'},
    {icon: 838, src: 'assets/images/predefined-icons/admission-tickets.svg', name: 'Admission Tickets'},
    {icon: 839, src: 'assets/images/predefined-icons/backpack.svg', name: 'Backpacks'},
    {icon: 840, src: 'assets/images/predefined-icons/basketball.svg', name: 'Basketball'},
    {icon: 841, src: 'assets/images/predefined-icons/candy.svg', name: 'Candy'},
    {icon: 843, src: 'assets/images/predefined-icons/chestnut.svg', name: 'Chestnut'},
    {icon: 844, src: 'assets/images/predefined-icons/coffee.svg', name: 'Coffee'},
    {icon: 845, src: 'assets/images/predefined-icons/envelope.svg', name: 'Envelope'},
    {icon: 846, src: 'assets/images/predefined-icons/jack-o-lantern.svg', name: 'Jack O Lantern'},
    {icon: 847, src: 'assets/images/predefined-icons/maple-leaf.svg', name: 'Maple Leaf'},
    {icon: 848, src: 'assets/images/predefined-icons/wood.svg', name: 'Wood'},
    {icon: 849, src: 'assets/images/predefined-icons/moon-cake.svg', name: 'Moon Cake'},
    {icon: 850, src: 'assets/images/predefined-icons/mushroom-2.svg', name: 'Mushroom 2'},
    {icon: 851, src: 'assets/images/predefined-icons/mushroom-3.svg', name: 'Mushroom 3'},
    // {icon: 852, src: 'assets/images/predefined-icons/mushroom.svg', name: 'Mushroom'},
    {icon: 853, src: 'assets/images/predefined-icons/skull.svg', name: 'Skull'},
    {icon: 854, src: 'assets/images/predefined-icons/soccer-ball.svg', name: 'Soccer ball'},
    {icon: 855, src: 'assets/images/predefined-icons/spider-web.svg', name: 'Spider Web'},
    {icon: 856, src: 'assets/images/predefined-icons/spider.svg', name: 'Spider'},
    {icon: 857, src: 'assets/images/predefined-icons/teacup.svg', name: 'Teacup'},
  ];

  dialogRef: any;
  indexOfContainer: any;
  type: any;
  key: any;
  container: any;
  userPlan: any;
  preview: any;
  selectedItem: any;
  timer = 0;
  reminder = 0;
  frequency = 0;
  container_timer = 0;
  container_reminder = 0;
  container_frequency = 0;
  timerList: any;
  reminderList: any;
  frequencyList: any;
  errorMatch = false;
  errorLength = false;
  confirm = false;
  loadingRecipients = false;
  toggleTimer: boolean = false;
  show: boolean = false;
  disabledButton: boolean = false;
  rsaEncryption = false;
  addNotebook = true;
  moving = false;
  copying = false;
  activeA = true;
  activeB = false;
  activeC = false;
  oldPassphrase = '';
  newPassphrase = '';
  confirmNewPassphrase = '';
  passphraseForRecipientValue = '';
  confirmPassphraseForRecipientValue = '';
  hardwareKeyForRecipientValue = '';
  answerForRecipientValue = '';
  newRecipientEmail = '';
  container_name = '';
  container_icon = 1;
  containerID = 0;
  container_description = '';
  icon = 1;
  notebook_icon = 1;
  newContainer = '';
  question = '3';
  searchString = '';
  newComment = '';
  notesData = '';
  comment = '';
  fileName = '';
  name = '';
  userName = '';
  password = '';
  url = '';
  notebook_name = '';
  passphraseValue = '';
  masterPasswordValue = '';
  hardwareKeyValue = '';
  answerValue = '';
  selectedFilter: string = 'all';
  notes = { data: '', deltaJson: [] };
  files = [];
  filesToShow = [];
  comments = [];
  recipients = [];
  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };
  user: any = { firstName: '', lastName: '', profilePicture: '', email: '', id: 0 };
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', 'large'] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link']
    ]
  };

  get dark() {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get containerContentData(): any {
    return this.containerService.containerContentData;
  }

  constructor( public dialog: MatDialog, private theme: ThemeService, private location: Location, private route: ActivatedRoute, private localstorage: LocalStorageService, private media: MediaService, private sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private containerPassword: ContainerPasswordService, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private dexieService: DexieService, private notebookService: ContainerNoteService, private router: Router, private recipientItemService: RecipientItemService, private userService: UserService) {
    this.selectedFilter = 'all';
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };
    this.user = JSON.parse(this.localstorage.getUser());
    this.type = this.route.snapshot.paramMap.get('type');
    this.indexOfContainer = this.route.snapshot.paramMap.get('index');
    this.setData();
    let defaultTimer = JSON.parse(this.localstorage.getTimerData());
    this.timerList = defaultTimer.timerList;
    this.frequencyList = defaultTimer.frequencyList;
    this.reminderList = defaultTimer.reminderList;
  }

    ngOnInit() {
      this.setData();
      this.selectedFilter = 'all';
    }

    timerToggle() {
      if(this.type=='own'){
        if (this.ownContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.ownContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.ownContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.ownContainers[this.indexOfContainer].timer==el.value)['name'];
      } else if(this.type=='shared'){
        if (this.sharedContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.sharedContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.sharedContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.sharedContainers[this.indexOfContainer].timer==el.value)['name'];
      } else {
        if (this.deadManSwitchContainers[this.indexOfContainer].backUpPerson != null)
            this.toggleTimer = !this.toggleTimer;
            this.reminder = this.reminderList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.deadManSwitchContainers[this.indexOfContainer].timer==el.value)['name'];
      }
    }

    async filterData($event){
      await this.setData();
      if (this.selectedFilter === 'passwords') {
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
      if (this.selectedFilter === 'notebooks') {
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

  async setPasswords(passwords: any) {
    if (passwords.length === 0) return [];

    const passwordsData = [];

    for (const p of passwords) {
      const icon = p.iconData;
      let iconData = icon.data;

      if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons') && !icon.data.includes(';base64,')) {
        const passData = new Uint8Array(JSON.parse(iconData).data);
        const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        iconData = this.sanitizeIcon(passBase64String, icon.type);
      }

      let size = JSON.parse(p.size);
      // Prepare the result object
      passwordsData.push({
        ...p,
        icon: { id: icon.id, data: iconData },
        size: { ...size, memory: Number(size.memory) },
        owner: p.owner,
      });
    }
    return passwordsData;
  }

  async setNotebooks(notebooks: any) {
    if (notebooks.length === 0) return [];

    const notebooksData = [];

    for (const n of notebooks) {
      const icon = n.iconData;
      const size = JSON.parse(n.size);
      const sizeWithMemory = { ...size, memory: Number(size.memory) };

      // Check if icon needs to be sanitized
      if (!icon.data.includes('assets/images/predefined-icons') && !icon.data.includes(';base64,')) {
        const noteData = new Uint8Array(JSON.parse(icon.data).data);
        const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const iconData = this.sanitizeIcon(noteBase64String, icon.type);

        notebooksData.push({
          ...n,
          createdAt: new Date(n.createdAt),
          icon: { id: icon.id, data: iconData },
          size: sizeWithMemory,
          owner: n.owner,
        });
      } else {
        notebooksData.push({
          ...n,
          createdAt: new Date(n.createdAt),
          icon: icon,
          size: sizeWithMemory,
          owner: n.owner,
        });
      }
    }
    return notebooksData;
  }

  sanitizeIcon(base64String: string, mediaType: string) {
    return mediaType === 'application/octet-stream'
      ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
      : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
  }

    async setData() {
      if (this.type == 'own') {
        this.container = this.ownContainers[this.indexOfContainer];
        const passwords = await this.setPasswords(this.ownContainers[this.indexOfContainer]?.passwords?.map(item => ({ ...item, type: 'Password' })) || []);
        const notebooks =  await this.setNotebooks(this.ownContainers[this.indexOfContainer]?.notebooks?.map(item => ({ ...item, type: 'Notebook' })) || []);
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
      } else if (this.type == 'shared') {
        this.container = this.sharedContainers[this.indexOfContainer];
        const passwords = await this.setPasswords(this.ownContainers[this.indexOfContainer]?.passwords?.map(item => ({ ...item, type: 'Password' })) || []);
        const notebooks =  await this.setNotebooks(this.ownContainers[this.indexOfContainer]?.notebooks?.map(item => ({ ...item, type: 'Notebook' })) || []);
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.container = this.deadManSwitchContainers[this.indexOfContainer];
        const passwords = await this.setPasswords(this.ownContainers[this.indexOfContainer]?.passwords?.map(item => ({ ...item, type: 'Password' })) || []);
        const notebooks =  await this.setNotebooks(this.ownContainers[this.indexOfContainer]?.notebooks?.map(item => ({ ...item, type: 'Notebook' })) || []);
        this.containerService.setContainerContentData([...passwords, ...notebooks].sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

    async setNotes(binary: any, notes: any){    
      if(notes.length>0){
        let notesData = notes.map(async (n: any)=>{
          let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
          let nDecrypted = JSON.parse(decryption);
          let size = JSON.parse(n.size);
          return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: n.ownerData, recipients: n.recipients ?? []}
        })
        return await Promise.all(notesData);
      }else{
        return [];
      }
    }

    selectNotebook(id: number) {
        this.router.navigate(['/notebook-details', this.type, this.container.id, id]);
    }

    selectPassword(id: number) {
      this.router.navigate(['/password-details', this.type, this.container.id, id]);
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    onFileChange(e: any) {
      const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.json', '.env', '.mp3', '.dmg', '.webp', '.webm'];
      let file = e.target.files[0];
      if (file) {
        if (file.size > 8000000) {
          this.openSnackBar('File should be at most 8 MB!');

        } else {
          let fileName = file.name;
          let dotIndex = fileName.indexOf('.');
          let name = fileName.slice(0, dotIndex);
          let extension = fileName.slice(dotIndex);
          if (extensions.includes(extension)) {
            this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
          } else {
            this.media.getIconByName(extension)
              .subscribe({
                next: (res: any) => {
                  let icon = res.icon;
                  const formData = new FormData();
                  formData.append("file", file);
                  this.containerPassword.getFileData(formData)
                    .subscribe({
                      next: (res: any) => {
                        this.files.push({ name, icon: { id: icon.id, data: icon.data }, size: file.size, data: res.data, extension, type: file.type });
                        this.filesToShow.push({ name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                      },
                      error: (error: HttpErrorResponse) => {
                      }
                    });
                },
                error: (error: HttpErrorResponse) => {
                }
              });
          }
        }
      }
    }

    onIconChange(event: any, isNotebook: boolean) {
      const file: File = event.target.files[0];
      const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

      const validFileType = (type) => fileTypes.includes(type);

      const validSize = (size) => size < 10000

      if (file) {
        if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

        if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

        this.fileName = file.name;
        const formData = new FormData();
        formData.append("icon", file);
        this.media.saveIcon(formData)
          .subscribe({
            next: (res: any) => {
              isNotebook ? this.notebook_icon = res.id :
              this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Cannot save icon!');
            }
          });
      }
    }

    extractFavicon() {
      // update this to not
        if (this.url === '') {
          this.openSnackBar('You need to enter an url first!');
        } else {
          this.containerPassword.extractFavicon(this.url)
            .subscribe({
              next: (res: any) => {
                if (res.faviconUrl.includes('https://www.google.com/s2/favicons?sz=64&domain_url=') || res.faviconUrl.includes('assets/images/predefined-icons')) {
                  this.preview = res.faviconUrl;
                } else {
                  let icon_data = new Uint8Array(JSON.parse(res.faviconUrl).data);
                  let string_icon_char = icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                  let iconBase64String = btoa(string_icon_char);
                  this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
                }
                this.icon = res.id;
                this.openSnackBar(res.message);
              },
              error: (error: HttpErrorResponse) => {
                this.openSnackBar('Favicon cannot be extracted!');
              }
            });
        }
    }

    calculateMemory(dataToSave: any, icon: any, data: any, isNotebook: boolean = false) {
      let memory;

      if (isNotebook) {
        memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon }));
      } else {
        // General memory calculation
        memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon, files: [] }));
        data.files.forEach((file: any) => {
          memory += file.size;
        });
      }

      let size = {};

      if (memory < 999) {
        size = { memory, unit: 'B' };

      } else if ((memory >= 1000) && (999999 > memory)) {
        size = { memory: (memory / 1000), unit: 'KB' };

      } else if ((memory >= 1000000) && (999999999 > memory)) {
        size = { memory: (memory / 1000000), unit: 'MB' };

      } else if ((memory >= 1000000000) && (999999999999 > memory)) {
        size = { memory: (memory / 1000000000), unit: 'GB' };

      } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
        size = { memory: (memory / 1000000000000), unit: 'TB' };

      }

      const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
      let restStorage = totalMemory - memory;
      this.ownContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.sharedContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.deadManSwitchContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      return { size, restStorage }
    }

    toggleShow() {
      this.show = !this.show;
    }

    createNotebook() {
      this.setData();
      if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
      else {
        this.disabledButton = true;
        let data = { name: this.notebook_name, icon: this.notebook_icon, notes: [] };

        this.media.getIcon(this.notebook_icon)
          .subscribe({
            next: async (res: any) => {
              let iconData: any;
              if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
                iconData = res.icon;
              } else {
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              const { size, restStorage } = this.calculateMemory(data, res.icon, null, true);

              if (restStorage > 0) {
                this.notebookService.addNoteBook(data, size, containerID, this.user.id, null)
                  .subscribe({
                    next: async (result: any) => {
                      let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type == 'shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                      let notebooks = this.container.notebooks;
                      notebooks.push({id: result.notebook, ...data, createdAt: new Date(), updatedAt: new Date(), iconData, size: JSON.stringify(size), containerID, owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                      let newMemory = JSON.parse(result.newMemory);
                      currentData[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks };

                      if (this.type == 'own') {
                          this.dexieService.setOwnContainers(currentData);
                          this.containerService.setOwnContainers(currentData);
                          this.successAdd(true);

                      } else if (this.type == 'shared') {
                          this.dexieService.setSharedContainers(currentData);
                          this.containerService.setSharedContainers(currentData);
                          this.successAdd(true);

                      } else {
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.containerService.setDeadManSwitchContainers(currentData);
                          this.successAdd(true);
                      }

                    },
                    error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Cannot create the notebook!');
                      this.disabledButton = false;
                    }
                  });
              } else {
                this.disabledButton = false;
                this.openSnackBar('Notebook cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
              }
            },
            error: (error: HttpErrorResponse) => {
              this.disabledButton = false;
            }
          });
      }
    }

    updateNotebook(){
      if (this.notebook_name.length == 0) this.openSnackBar('Name is required!');
      else {
        this.disabledButton = true;

        let data = { name: this.notebook_name, icon: this.notebook_icon };
              
        let containerID = this.container.id;

        this.notebookService.updateNoteBook(data, this.selectedItem.id, containerID)
              .subscribe({
                next: async (res)=> {
                  let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type =='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                  let containerIndex = currentData.findIndex((cont: any)=> cont.id==containerID);
                  let notebooks = currentData[containerIndex].notebooks;
                  let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.selectedItem.id);

                  this.media.getIcon(this.notebook_icon)
                        .subscribe({
                            next: async (res: any)=>{
                                if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData: res.icon, icon: res.icon};
                                    currentData[containerIndex] = {...currentData[containerIndex], notebooks: notebooks};
                                    if(this.type=='own'){
                                        this.dexieService.setOwnContainers(currentData);
                                        this.containerService.setOwnContainers(currentData);
                                        this.successEdit(true);
                                    } else if (this.type=='shared') {
                                        this.dexieService.setSharedContainers(currentData);
                                        this.containerService.setSharedContainers(currentData);
                                        this.successEdit(true);
                                    } else if(this.type=='backup'){
                                        this.dexieService.setDeadManSwitchContainers(currentData);
                                        this.containerService.setDeadManSwitchContainers(currentData);
                                        this.successEdit(true);
                                    }
                                }else{ 
                                    let my_note_data = new Uint8Array(JSON.parse(res.icon.data).data);
                                    let string_pass_char = my_note_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                                    let passBase64String = btoa(string_pass_char);
                                    let iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
                                    notebooks[notebookIndex] = {...notebooks[notebookIndex], name: this.notebook_name, iconData, icon: iconData};
                                    currentData[containerIndex] = {...currentData[containerIndex], notebooks: notebooks};
                                    if(this.type=='own'){
                                        this.dexieService.setOwnContainers(currentData);
                                        this.containerService.setOwnContainers(currentData);
                                        this.successEdit(true);
                                    } else if (this.type=='shared') {
                                        this.dexieService.setSharedContainers(currentData);
                                        this.containerService.setSharedContainers(currentData);
                                        this.successEdit(true);
                                    } else if(this.type=='backup') {
                                        this.dexieService.setDeadManSwitchContainers(currentData);
                                        this.containerService.setDeadManSwitchContainers(currentData);
                                        this.successEdit(true);
                                    }
                                }
                            }});
                }, error: (err) => {
                  this.disabledButton = false;
                  this.openSnackBar('Some error occured, please try again!');
                }
              });
      }
    }

    createPassword() {
      this.setData();
      if (this.name.length == 0) this.openSnackBar('Name is required!');
      else if (this.userName.length == 0) this.openSnackBar('Username is required!');
      else if (this.password.length == 0) this.openSnackBar('Password is required!');
      else {
        this.disabledButton = true;
        let data = { comments: this.comments, files: this.files, notes: this.notes};
        this.media.getIcon(this.icon)
          .subscribe({
            next: async (res: any) => {
              let iconData: any;
              if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
                iconData = res.icon;
              } else {
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              let passEncryption = '';
              let dataEncryption = '';

              if (this.type == 'shared') {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedRecipientKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey)
              } else if (this.type == 'own') {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedOwnerKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey)
              } else {
                passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedBackUpPersonKey);
                dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey)
              }
              let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption };
              const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data, false);

              if (restStorage > 0) {

                this.containerPassword.addPassword(dataToSave, size, containerID, this.user.id, null)
                  .subscribe({
                    next: async (result: any) => {
                        let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type =='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                        let pass = this.container.passwords;
                        pass.push({id: result.password, recipients: [], deleted: false, createdAt: new Date(), updatedAt: new Date(), size: JSON.stringify(size), containerID, iconData, owner: this.user.id, passData: dataEncryption, ...dataToSave, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                        let newMemory = JSON.parse(result.newMemory);
                        currentData[this.indexOfContainer] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass };

                      if (this.type == 'own') {
                          this.dexieService.setOwnContainers(currentData);
                          this.containerService.setOwnContainers(currentData);
                          this.successAdd(false);

                      } else if (this.type == 'shared') {
                          this.dexieService.setSharedContainers(currentData);
                          this.containerService.setSharedContainers(currentData);
                          this.successAdd(false);

                      } else {
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.containerService.setDeadManSwitchContainers(currentData);
                          this.successAdd(false);
                      }

                    },
                    error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                    }
                  });
              } else {
                this.disabledButton = false;
                this.openSnackBar('Password cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
              }
            },
            error: (error: HttpErrorResponse) => {
              this.disabledButton = false;
            }
          });
      }
    }

    toggleSidebar() {
      this.start.toggle();
    }

    goBack() {
      this.location.back();
    }

    onContentChanged($event: any) {
      this.notes.deltaJson = $event.content['ops'];
      this.notes.data = this.notesData;
    }

    timeDifference(date: any) {
      let time_difference = new Date().getTime() - new Date(date).getTime();
      if (time_difference < 1000) {
        return `${time_difference} milliseconds ago`;
      } else if ((time_difference / 1000) < 60) {
        return `${Math.floor(time_difference / 1000)} seconds ago`;
      } else if ((time_difference / 60000) < 60) {
        return `${Math.floor(time_difference / 60000)} minutes ago`;
      } else if ((time_difference / 3600000) < 24) {
        return `${Math.floor(time_difference / 3600000)} hours ago`;
      } else if ((time_difference / 86400000) < 7) {
        return `${Math.floor(time_difference / 86400000)} days ago`;
      } else if ((time_difference / 604800000) < 4) {
        return `${Math.floor(time_difference / 604800000)} weeks ago`;
      } else {
        return new Date(date).toISOString().split('T')[0];
      }
    }

    passwordDialog() {
      this.dialog.open(this.addPasswordDialog, { width: '650px' });
    }

    notebookDialog() {
      this.addNotebook = true;
      this.dialog.open(this.addNotebookDialog, { width: '500px' });
    }

    cancelDeleteItem() {
      this.dialog.closeAll();
      this.notebook_icon = 1;
      this.notebook_name = '';
      this.name = '';
      this.userName = '';
      this.url = '';
      this.icon = 1;
      this.files = [];
      this.comments = [];
      this.notes = { data: '', deltaJson: [] };
      this.notesData = '';
      this.disabledButton = false;
      this.newRecipientEmail = '';
    }

    removeFile(index: any) {
      this.files.splice(index, 1);
      this.filesToShow.splice(index, 1);
    }

    movePasswordToTrash() {
      this.disabledButton = true;
      this.containerPassword.moveToTrashPassword(this.selectedItem.id, this.selectedItem.containerID)
        .subscribe({
          next: async (res: any) => {
            let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type =='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
            let passIndex = this.container.passwords.findIndex(pass=> pass.id==this.selectedItem.id);
            this.container.passwords.splice(passIndex, 1);
            currentData[this.indexOfContainer] = this.container;
            if (this.type == 'own') {
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
                this.successDelete(false);

            } else if (this.type == 'shared') {
                this.dexieService.setSharedContainers(currentData);
                this.containerService.setSharedContainers(currentData);
                this.successDelete(false);
            } else {
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
                this.successDelete(false);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.dialog.closeAll();
            this.disabledButton = false;
            this.openSnackBar('Cannot delete password!');
          }
        });
    }

    moveNotebookToTrash() {
      this.disabledButton = true;
      this.notebookService.moveToTrashNotebook(this.selectedItem.id, this.selectedItem.containerID)
        .subscribe({
          next: async (res: any)=>{
              let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type =='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
              let notebookIndex = this.container.notebooks.findIndex(notebook=> notebook.id==this.selectedItem.id);
              this.container.notebooks.splice(notebookIndex, 1);
              currentData[this.indexOfContainer] =  this.container;

              if(this.type=='own'){
                  this.dexieService.setOwnContainers(currentData);
                  this.containerService.setOwnContainers(currentData);
                  this.successDelete(true);

              }else if(this.type=='shared'){
                  this.dexieService.setSharedContainers(currentData);
                  this.containerService.setSharedContainers(currentData);
                  this.successDelete(true);

              }else{
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.containerService.setDeadManSwitchContainers(currentData);
                  this.successDelete(true);

              }
          },
          error: (error: HttpErrorResponse)=>{
            this.dialog.closeAll();
            this.disabledButton = false;
            this.openSnackBar('Cannot delete notebook!');
          }
        });
    }

    moveToTrash() {
      if(this.selectedItem.type == 'Password'){
          this.movePasswordToTrash();
      }else if(this.selectedItem.type == 'Notebook'){
          this.moveNotebookToTrash();
      }
    }

    successDelete(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook moved to trash!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password moved to trash!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successMove(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook moved successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password moved successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successAdd(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successEdit(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook updated successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password updated successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    successClone(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.preview = 'assets/images/dashboard/container-content/photo.svg';
      if (isNotebook) {
        this.notebook_icon = 1;
        this.notebook_name = '';
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Notebook cloned successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      } else {
        this.name = '';
        this.userName = '';
        this.url = '';
        this.icon = 1;
        this.files = [];
        this.comments = [];
        this.notes = { data: '', deltaJson: [] };
        this.notesData = '';
        this.selectedFilter = 'passwords';
        this.openSnackBar('Password cloned successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

      }
    }

    selectItem(item: any) {
      this.moving = false;
      this.copying = false;
      this.setData();
      item.type == 'Password' ? this.selectPassword(item.id) : this.selectNotebook(item.id);
    }

    editItem(item){
      this.moving = false;
      this.copying = false;
      this.setData();
      if(item.type == 'Password'){
          this.selectPassword(item.id);
      }else{
          this.selectedItem = item;
          this.notebookDialogFoEdit(item);
      }
    }

    notebookDialogFoEdit(item) {
      this.addNotebook = false;
      this.notebook_name = item.name;
      this.preview = item.icon.data;
      this.notebook_icon = item.icon.id;
      this.dialog.open(this.addNotebookDialog, { width: '500px' });
    }

    deleteItem(item: any) {
      this.moving = false;
      this.copying = false;
      this.selectedItem = item;
      this.setData();
      this.dialog.open(this.deleteItemDialog, { width: '400px' });
    }

    async moveItem(item){
      this.setData();
      this.moving = true;
      this.copying = false;
      if(this.type=='own'){
        let decryptedKey = this.container.decryptedOwnerKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });
        
        } else if(item.type=='Notebook') {
          let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
          let notes = await this.setNotes(decryptedKey, res['notesData']);
          this.selectedItem = {...item, notes: notes};
          this.dialog.open(this.moveDialog, { width: '450px' });

        }
      } else if(this.type=='shared'){
        let decryptedKey = this.container.decryptedRecipientKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });

        } else if(item.type=='Notebook') {
            let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
            let notes = await this.setNotes(decryptedKey, res['notesData']);
            this.selectedItem = {...item, notes: notes};
            this.dialog.open(this.moveDialog, { width: '450px' });

        }
      } else{
        let decryptedKey = this.container.decryptedBackUpPersonKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });

        } else if(item.type=='Notebook') {
            let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
            let notes = await this.setNotes(decryptedKey, res['notesData']);
            this.selectedItem = {...item, notes: notes};
            this.dialog.open(this.moveDialog, { width: '450px' });

        }
      }
    }

    async copyItem(item){
      this.setData();
      this.copying = true;
      this.moving = false;
            if(this.type=='own'){
        let decryptedKey = this.container.decryptedOwnerKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });
        
        } else if(item.type=='Notebook') {
          let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
          let notes = await this.setNotes(decryptedKey, res['notesData']);
          this.selectedItem = {...item, notes: notes};
          this.dialog.open(this.moveDialog, { width: '450px' });

        }
      } else if(this.type=='shared'){
        let decryptedKey = this.container.decryptedRecipientKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });

        } else if(item.type=='Notebook') {
            let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
            let notes = await this.setNotes(decryptedKey, res['notesData']);
            this.selectedItem = {...item, notes: notes};
            this.dialog.open(this.moveDialog, { width: '450px' });

        }
      } else{
        let decryptedKey = this.container.decryptedBackUpPersonKey;
        if(item.type=='Password') {
            const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
            const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
            const dataDecrypted = JSON.parse(dataDecryption);
            this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
            this.dialog.open(this.moveDialog, { width: '450px' });

        } else if(item.type=='Notebook') {
            let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
            let notes = await this.setNotes(decryptedKey, res['notesData']);
            this.selectedItem = {...item, notes: notes};
            this.dialog.open(this.moveDialog, { width: '450px' });

        }
      }
    }

    async verifyQuestionAnswer(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async verifyMasterPassword(){
      try{
            const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
            if(this.rsaEncryption){
                let binaryMP = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
                let privateKeyDecrypted = await this.encryptDecrypt.decryptData(this.user['privateKey'], binaryMP);
                this.localstorage.setMasterPassword(hashedMasterPassword);
                this.localstorage.setPrivateKey(privateKeyDecrypted);
                this.localstorage.setPublicKey(this.user['publicKey']);
                if(this.selectedItem.type == 'Password'){
                    if(this.moving) {
                      let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                      this.movePassword(binaryKey);
                    }
                    else if(this.copying) {
                      if(this.newContainer=='') this.copyPasswordRSA();
                      else {
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        this.copyPassword(binaryKey);
                      }
                      
                    }
                }else if(this.selectedItem.type == 'Notebook'){
                    if(this.moving){
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        this.moveNotebook(binaryKey);
                    }
                    else if(this.copying) {
                      if(this.newContainer=='') this.copyNotebookRSA();
                      else {
                          let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                          this.copyNotebook(binaryKey);
                      }
                    }
                }
            }else{

                if(this.selectedItem.type == 'Password'){
                    if(this.moving) {
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        this.movePassword(binaryKey);
                    } else if(this.copying) {
                      if(this.newContainer=='') this.copyPasswordRSA();
                      else {
                        let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                        this.copyPassword(binaryKey);
                      }
                    }
                }else if(this.selectedItem.type == 'Notebook'){
                    if(this.moving) {
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                      this.moveNotebook(binaryKey);
                    } else if(this.copying) {
                        if(this.newContainer=='') this.copyNotebookRSA();
                        else {
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          this.copyNotebook(binaryKey);
                        }
                    }
                }
            }
      }catch(error){
        console.log(error);
        this.disabledButton = false;
      }
    }

    async verifyHardwareKey(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.slice(0, 12));
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async verifyPassphrase(){
        let decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
        let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
        if(this.selectedItem.type == 'Password'){
            if(this.moving) this.movePassword(binaryKey);
            else if(this.copying) this.copyPassword(binaryKey);
        }else if(this.selectedItem.type == 'Notebook'){
            if(this.moving) this.moveNotebook(binaryKey);
            else if(this.copying) this.copyNotebook(binaryKey);
        }
    }

    async movePassword(binaryKey: any) {
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
      let passIndex = this.container.passwords.findIndex((pass)=> pass.id==this.selectedItem.id);
      let passToMove = this.container.passwords[passIndex];
      this.containerPassword.movePassword(this.selectedItem.id, newCont[0], {password: passEncryption, passData: dataEncryption })
      .subscribe({
        next: async (res: any)=> {
          let newRecipientsKeys = [];
          for(const rec of res.recipients){
            let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
            newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
          }
          this.recipientItemService.updateRecipientsPassword(this.selectedItem.id, newRecipientsKeys).subscribe({
            next: (result)=> {
                // we need to update local old container
                let newMemory = JSON.parse(res.oldContainerMemory);
                if(this.type==='own'){
                      let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                      this.dexieService.getOwnContainers().then(async (dt: any) => {
                            let d = dt; 
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass };
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                            let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                            let passwords = currentData[containerIndex2].passwords;
                            let newContainerMemory = JSON.parse(res.newContainerMemory);
                            passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];

                            // we need to update local new container
                            if(newCont[1]==='own'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedOwnerKey: binaryKey, decrypted: true };
                                this.dexieService.setOwnContainers(currentData);
                                this.containerService.setOwnContainers(currentData);
                                this.successMove(false);
                    
                            } else if(newCont[1]==='shared'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedRecipientKey: binaryKey, decrypted: true };
                                this.dexieService.setSharedContainers(currentData);
                                this.containerService.setSharedContainers(currentData);
                                this.successMove(false);
                    
                            } else if (newCont[1]==='backup'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.containerService.setDeadManSwitchContainers(currentData);
                                this.successMove(false);
                            }
                        });
                } else if(this.type==='shared'){
                      let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                      this.dexieService.getSharedContainers().then(async (dt: any) => {
                            let d = dt;
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                            let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                            let passwords = currentData[containerIndex2].passwords;
                            let newContainerMemory = JSON.parse(res.newContainerMemory);
                            passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];

                            // we need to update local new container
                            if(newCont[1]==='own'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedOwnerKey: binaryKey, decrypted: true };
                                this.dexieService.setOwnContainers(currentData);
                                this.containerService.setOwnContainers(currentData);
                                this.successMove(false);
                    
                            } else if(newCont[1]==='shared'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedRecipientKey: binaryKey, decrypted: true };
                                this.dexieService.setSharedContainers(currentData);
                                this.containerService.setSharedContainers(currentData);
                                this.successMove(false);
                    
                            } else if (newCont[1]==='backup'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.containerService.setDeadManSwitchContainers(currentData);
                                this.successMove(false);
                            }
                        });
                } else if (this.type==='backup'){
                        let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                        this.dexieService.getDeadManSwitchContainers().then(async (dt: any) => {
                            let d = dt;
                            let pass = this.container.passwords.filter((p)=>p.id!=this.selectedItem.id);
                            d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            
                            let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                            let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                            let passwords = currentData[containerIndex2].passwords;
                            let newContainerMemory = JSON.parse(res.newContainerMemory);
                            passwords = [...passwords, {...passToMove, containerID: Number(newCont[0]), password: passEncryption, passData: dataEncryption}];

                            // we need to update local new container
                            if(newCont[1]==='own'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedOwnerKey: binaryKey, decrypted: true };
                                this.dexieService.setOwnContainers(currentData);
                                this.containerService.setOwnContainers(currentData);
                                this.successMove(false);
                    
                            } else if(newCont[1]==='shared'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedRecipientKey: binaryKey, decrypted: true };
                                this.dexieService.setSharedContainers(currentData);
                                this.containerService.setSharedContainers(currentData);
                                this.successMove(false);
                    
                            } else if (newCont[1]==='backup'){
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, passwords, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                this.dexieService.setDeadManSwitchContainers(currentData);
                                this.containerService.setDeadManSwitchContainers(currentData);
                                this.successMove(false);
                            }
                        });
                }
            }, error: (err)=> {
              console.log(err);
            }
          })

        }, error: (err)=> {
          console.log(err);
          this.openSnackBar('An error occurred while moving this item, please try again!');
        }
      })
    }

    async moveNotebook(binaryKey: any){
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let notebookIndex = this.container.notebooks.findIndex((notebook)=> notebook.id==this.selectedItem.id);
      let notebookToMove = this.container.notebooks[notebookIndex];
      let notes = [];
      for(const note of notebookToMove.notes){
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(note.data), binaryKey);
        notes.push({...note, data: newEncryptedData});
      }
      this.notebookService.moveNotebook(this.selectedItem.id, newCont[0], {notes})
        .subscribe({
          next: async (res: any)=> {
            let newRecipientsKeys = [];
            for(const rec of res.recipients){
              let newKey = await this.encryptDecrypt.encryptDataRSA(binaryKey, JSON.parse(atob(rec['publicKey'])));
              newRecipientsKeys.push({id: rec['id'], email: rec['email'], key: newKey}); 
            }
            this.recipientItemService.updateRecipientsNotebook(this.selectedItem.id, newRecipientsKeys).subscribe({
                next: (result)=>{
                    // we need to update local old container
                    let newMemory = JSON.parse(res.oldContainerMemory);
                    if(this.type==='own'){
                          let containerIndex = this.ownContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getOwnContainers().then(async (dt: any) => {
                                let d = dt; 
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks };
                                this.dexieService.setOwnContainers(d);
                                this.containerService.setOwnContainers(d);
                                let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                                let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                                let notes = currentData[containerIndex2].notebooks;
                                let newContainerMemory = JSON.parse(res.newContainerMemory);
                                notes = [...notes, {...notebookToMove, containerID: Number(newCont[0])}];

                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedOwnerKey: binaryKey, decrypted: true };
                                    this.dexieService.setOwnContainers(currentData);
                                    this.containerService.setOwnContainers(currentData);
                                    this.successMove(true);
                        
                                } else if(newCont[1]==='shared'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedRecipientKey: binaryKey, decrypted: true };
                                    this.dexieService.setSharedContainers(currentData);
                                    this.containerService.setSharedContainers(currentData);
                                    this.successMove(true);
                        
                                } else if (newCont[1]==='backup'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                    this.dexieService.setDeadManSwitchContainers(currentData);
                                    this.containerService.setDeadManSwitchContainers(currentData);
                                    this.successMove(true);

                                }
                            });
                    } else if(this.type==='shared'){
                          let containerIndex = this.sharedContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                          this.dexieService.getSharedContainers().then(async (dt: any) => {
                                let d = dt;
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks };
                                this.dexieService.setSharedContainers(d);
                                this.containerService.setSharedContainers(d);
                                let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                                let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                                let notes = currentData[containerIndex2].notebooks;
                                let newContainerMemory = JSON.parse(res.newContainerMemory);
                                notes = [...notes, {...notebookToMove, containerID: Number(newCont[0])}];

                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedOwnerKey: binaryKey, decrypted: true };
                                    this.dexieService.setOwnContainers(currentData);
                                    this.containerService.setOwnContainers(currentData);
                                    this.successMove(true);
                        
                                } else if(newCont[1]==='shared'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedRecipientKey: binaryKey, decrypted: true };
                                    this.dexieService.setSharedContainers(currentData);
                                    this.containerService.setSharedContainers(currentData);
                                    this.successMove(true);
                        
                                } else if (newCont[1]==='backup'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                    this.dexieService.setDeadManSwitchContainers(currentData);
                                    this.containerService.setDeadManSwitchContainers(currentData);
                                    this.successMove(true);

                                }
                            });
                    } else if (this.type==='backup'){
                            let containerIndex = this.deadManSwitchContainers.findIndex((cont)=>cont.id==Number(this.selectedItem.containerID));
                            this.dexieService.getDeadManSwitchContainers().then(async (dt: any) => {
                                let d = dt;
                                let notebooks = this.container.notebooks.filter((p)=>p.id!=this.selectedItem.id);
                                d[containerIndex] = { ...this.container, usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks };
                                this.dexieService.setDeadManSwitchContainers(d);
                                this.containerService.setDeadManSwitchContainers(d);
                                let currentData: any = newCont[1]==='own' ? await this.dexieService.getOwnContainers() : (newCont[1]==='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                                let containerIndex2 = currentData.findIndex((cont: any)=>cont.id==Number(newCont[0]));
                                let notes = currentData[containerIndex2].notebooks;
                                let newContainerMemory = JSON.parse(res.newContainerMemory);
                                notes = [...notes, {...notebookToMove, containerID: Number(newCont[0])}];
                                currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedOwnerKey: binaryKey, decrypted: true };

                                // we need to update local new container
                                if(newCont[1]==='own'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedOwnerKey: binaryKey, decrypted: true };
                                    this.dexieService.setOwnContainers(currentData);
                                    this.containerService.setOwnContainers(currentData);
                                    this.successMove(true);
                        
                                } else if(newCont[1]==='shared'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedRecipientKey: binaryKey, decrypted: true };
                                    this.dexieService.setSharedContainers(currentData);
                                    this.containerService.setSharedContainers(currentData);
                                    this.successMove(true);
                        
                                } else if (newCont[1]==='backup'){
                                    currentData[containerIndex2] = { ...currentData[containerIndex2], usedMemory: { ...newContainerMemory, memory: Number(newContainerMemory.memory) }, notebooks: notes, decryptedBackUpPersonKey: binaryKey, decrypted: true };
                                    this.dexieService.setDeadManSwitchContainers(currentData);
                                    this.containerService.setDeadManSwitchContainers(currentData);
                                    this.successMove(true);

                                }
                            });
                    }
                }, error: (err)=> {
                  console.log(err);
                }
              });
          }, error: (err) => {
            console.log(err);
            this.openSnackBar('An error occured while moving this item, please try again!');
          }
        }) 
    }
  
    async moveSelectedItem(){
      const newCont = this.newContainer.split(',');
      if(Number(newCont[0])==this.selectedItem.containerID){
        this.openSnackBar('Item already in this container, please select another one!');
      } else if (this.newContainer==''){
        this.openSnackBar('Please select a container!');
      } else{
          if(newCont[1]=='own'){
            let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.ownContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.ownContainers[index].decryptedOwnerKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.ownContainers[index].decryptedOwnerKey);
                }
            }else{
                if(this.ownContainers[index].ownerEncryptionMode==1){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  if(this.localstorage.getMasterPassword()){
                      this.rsaEncryption = false;
                      // ! we should decrypt using aes here
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                      // this.newContainerData = this.ownContainers[index];
                      if(this.selectedItem.type == 'Password'){
                          this.movePassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.moveNotebook(binaryKey);
                      }
                  }else{
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
                } else if (this.ownContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
                } else if(this.ownContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                }
            }
          }else if(newCont[1]=='shared'){
            let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.sharedContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.sharedContainers[index].decryptedRecipientKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.sharedContainers[index].decryptedRecipientKey);
                }
            }else{
              if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                  if(this.localstorage.getMasterPassword()){
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                        this.rsaEncryption = true;
                        // ! we should decrypt using rsa here
                        this.key = this.sharedContainers[index].recipientKey;
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        if(this.selectedItem.type == 'Password'){
                            this.movePassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.moveNotebook(binaryKey);
                        }
                      }else{
                        // ! we should decrypt using aes here
                          this.rsaEncryption = false;
                          this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          if(this.selectedItem.type == 'Password'){
                              this.movePassword(binaryKey);
                          }else if(this.selectedItem.type == 'Notebook'){
                              this.moveNotebook(binaryKey);
                          }
                      }

                  }else{
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                        this.key = this.sharedContainers[index].recipientKey;
                        this.rsaEncryption = true;
                      }else{
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        this.rsaEncryption = false;
                      } 
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
              } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.questionAnswerDialog, {width: '400px'});

              } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }else if(newCont[1]=='backup'){
            let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.deadManSwitchContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.movePassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.moveNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }
            }else{
              if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});

              } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }

      }
    }

    async copySelectedItem(){
      this.disabledButton = false;
      if(this.newContainer==''){
          this.dialog.open(this.confirmDialog, {width: '400px'});
      }else{
          const newCont = this.newContainer.split(',');
          if(newCont[1]=='own'){
            let index = this.ownContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.ownContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.ownContainers[index].decryptedOwnerKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.ownContainers[index].decryptedOwnerKey);
                }
            }else{
                if(this.ownContainers[index].ownerEncryptionMode==1){
                  this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                  if(this.localstorage.getMasterPassword()){
                      this.rsaEncryption = false;
                      // ! we should decrypt using aes here
                      let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                      let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);                    
                      if(this.selectedItem.type == 'Password'){
                          this.copyPassword(binaryKey);
                      }else if(this.selectedItem.type == 'Notebook'){
                          this.copyNotebook(binaryKey);
                      }
                  }else{
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
                } else if (this.ownContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
                } else if(this.ownContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.ownContainers[index].ownerKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                }
            }
          }else if(newCont[1]=='shared'){
            let index = this.sharedContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.sharedContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.sharedContainers[index].decryptedRecipientKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.sharedContainers[index].decryptedRecipientKey);
                }
            }else{
              if(this.sharedContainers[index].recipientEncryptionMode=='-'){
                  if(this.localstorage.getMasterPassword()){
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')) {
                        this.rsaEncryption = true;
                        // ! we should decrypt using rsa here
                        this.key = this.sharedContainers[index].recipientKey;
                        let binaryKey = await this.encryptDecrypt.decryptDataRSA(this.key, JSON.parse(atob(this.localstorage.getPrivateKey())));
                        if(this.selectedItem.type == 'Password'){
                            this.copyPassword(binaryKey);
                        }else if(this.selectedItem.type == 'Notebook'){
                            this.copyNotebook(binaryKey);
                        }
                      }else{
                        // ! we should decrypt using aes here
                          this.rsaEncryption = false;
                          this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                          let decrypted = await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword());
                          let binaryKey = this.encryptDecrypt.bufferToBinary(decrypted);
                          if(this.selectedItem.type == 'Password'){
                              this.copyPassword(binaryKey);
                          }else if(this.selectedItem.type == 'Notebook'){
                              this.copyNotebook(binaryKey);
                          }
                      }

                  }else{
                      if(!this.sharedContainers[index].recipientKey.includes('type') && !this.sharedContainers[index].recipientKey.includes('Buffer') && !this.sharedContainers[index].recipientKey.includes('data')){
                        this.key = this.sharedContainers[index].recipientKey;
                        this.rsaEncryption = true;
                      }else{
                        this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                        this.rsaEncryption = false;
                      } 
                      this.dialog.open(this.masterPasswordDialog, {width: '400px'});
                  }
              } else if(this.sharedContainers[index].recipientEncryptionMode=='passphrase'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});
              } else if(this.sharedContainers[index].recipientEncryptionMode=='questionAnswer'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.questionAnswerDialog, {width: '400px'});

              } else if(this.sharedContainers[index].recipientEncryptionMode=='hardwareKey'){
                    this.key = new Uint8Array(JSON.parse(this.sharedContainers[index].recipientKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }else if(newCont[1]=='backup'){
            let index = this.deadManSwitchContainers.findIndex((cont)=> cont.id ==Number(newCont[0]));
            if(this.deadManSwitchContainers[index].decrypted){
                if(this.selectedItem.type == 'Password'){
                    this.copyPassword(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }else if(this.selectedItem.type == 'Notebook'){
                    this.copyNotebook(this.deadManSwitchContainers[index].decryptedBackUpPersonKey);
                }
            }else{
              if (this.deadManSwitchContainers[index].ownerEncryptionMode==2){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.passphraseDialog, {width: '400px'});

              } else if(this.deadManSwitchContainers[index].ownerEncryptionMode==3){
                    this.key = new Uint8Array(JSON.parse(this.deadManSwitchContainers[index].backUpPersonKey).data);
                    this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
              }
            }
          }
      }
    }

    copyRSA() {
      this.disabledButton = false;
      if(this.localstorage.getMasterPassword()){
          if(this.selectedItem.type == 'Password'){
              this.copyPasswordRSA();
          } else if (this.selectedItem.type == 'Notebook') {
              this.copyNotebookRSA();
          }
      }else{
          this.dialog.open(this.masterPasswordDialog, {width: '400px'});
      }
    }

    async copyPasswordRSA(){
      this.disabledButton = true;
      let randomKey = await this.encryptDecrypt.getRandom();
      let binary = this.encryptDecrypt.bufferToBinary(randomKey);
      const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binary);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binary);

      let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };
      this.containerPassword.addPassword(dataToSave, JSON.parse(this.selectedItem.size), undefined, this.user.id, encryptedKey)
            .subscribe({
                next: async (result: any) => {
                    this.dexieService.getOwnPasswords().then((dt: any) => {
                          let pass = dt;
                          pass.push({...this.selectedItem, key: encryptedKey, decryptedKey: binary, id: result.password, icon: this.containerService.setIcon(this.selectedItem.iconData), containerID: undefined, container: undefined, recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), size: this.selectedItem.size, owner: this.user.id, passData: dataEncryption, password: passEncryption, type: 'Password', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                          this.dexieService.setOwnPasswords(pass);
                          this.containerService.setOwnPasswords(pass);
                          this.successClone(false);
                        });
                },
                error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                }
          });
    }
  
    async copyNotebookRSA(){
      this.disabledButton = true;
      let notes = [];
      let randomKey = await this.encryptDecrypt.getRandom();
      let binary = this.encryptDecrypt.bufferToBinary(randomKey);
      const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, JSON.parse(atob(this.user['publicKey'])));
      for(const note of this.selectedItem.notes){
        let data = {
              created_at: (new Date()).toDateString(),
              wysiwygData: note.wysiwygData,
              markdownData: note.markdownData
        }
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);
        notes.push({...note, data: newEncryptedData});
      }
      let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };
  
      this.notebookService.addNoteBook(data, JSON.parse(this.selectedItem.size), undefined, this.user.id, encryptedKey)
          .subscribe({
            next: async (result: any) => {
                for(const [index, note] of Object.entries(notes)){
                  this.notebookService.addNote(note.title, note.data, note.size, undefined, result.notebook, this.user.id)
                        .subscribe({
                          next: async (result: any) => {
                            notes[index] = {...note, id: result.note};
                          }, error: (err)=> {
                            console.log(err);
                          }
                  });
                }
                this.dexieService.getOwnNotebooks().then((dt: any) => {
                      let notebooks = dt;
                      notebooks.push({id: result.notebook, key: encryptedKey, decryptedKey: binary,  ...data, notes: notes.map((e)=>e.id), createdAt: new Date(), updatedAt: new Date(), icon: this.containerService.setIcon(this.selectedItem.iconData), iconData: this.selectedItem.iconData, size: this.selectedItem.size, containerID: undefined, container: undefined, owner: this.user.id, recipients: [], deleted: false, decrypted: true, type: 'Notebook', ownership: 'own', ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                      this.dexieService.setOwnNotebooks(notebooks);
                      this.containerService.setOwnNotebooks(notebooks);
                      this.successClone(true);
                });
            },
            error: (error: HttpErrorResponse) => {
                this.openSnackBar('Cannot clone the notebook!');
                this.disabledButton = false;
            }
      });
    }
  
    async copyPassword(binaryKey: any) {
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let passEncryption = await this.encryptDecrypt.encryptData(this.selectedItem.password, binaryKey);
      let dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify({ comments: this.selectedItem.comments, files: this.selectedItem.files, notes: this.selectedItem.notes}), binaryKey);
      let dataToSave = { url: this.selectedItem.url, name: this.selectedItem.name, icon: this.selectedItem.iconData.id, userName: this.selectedItem.userName, password: passEncryption, passData: dataEncryption };
  
      this.containerPassword.addPassword(dataToSave, JSON.parse(this.selectedItem.size), newCont[0], this.user.id, null)
            .subscribe({
                next: async (result: any) => {

                    let currentData: any = newCont[1] == 'own' ? await this.dexieService.getOwnContainers() : ( newCont[1] == 'shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                    let containerIndex = currentData.findIndex((cont: any)=> cont.id==Number(newCont[0]));
                    let pass = currentData[containerIndex].passwords;
                    pass.push({...this.selectedItem, id: result.password, recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), size: this.selectedItem.size, containerID: Number(newCont[0]), iconData: this.selectedItem.iconData, owner: this.user.id, passData: dataEncryption, password: passEncryption, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                    let newMemory = JSON.parse(result.newMemory);
              
                    if (newCont[1] == 'own') {
                          currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedOwnerKey: binaryKey, decrypted: true };
                          this.dexieService.setOwnContainers(currentData);
                          this.containerService.setOwnContainers(currentData);
                          this.successClone(false);
  
                      } else if (newCont[1] == 'shared') {
                          currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedRecipientKey: binaryKey, decrypted: true};
                          this.dexieService.setSharedContainers(currentData);
                          this.containerService.setSharedContainers(currentData);
                          this.successClone(false);
  
                      } else {
                          currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedBackUpPersonKey: binaryKey, decrypted: true};
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.containerService.setDeadManSwitchContainers(currentData);
                          this.successClone(false);
                      }
                },
                error: (error: HttpErrorResponse) => {
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                }
          });
    }
    
    async copyNotebook(binaryKey: any){
      this.disabledButton = true;
      const newCont = this.newContainer.split(',');
      let notes = [];
      for(const note of this.selectedItem.notes){
        let data = {
              created_at: (new Date()).toDateString(),
              wysiwygData: note.wysiwygData,
              markdownData: note.markdownData
        }
        let newEncryptedData = await this.encryptDecrypt.encryptData(JSON.stringify(data), binaryKey);
        notes.push({...note, data: newEncryptedData});
      }
      let data = { name: this.selectedItem.name, icon: this.selectedItem.iconData.id, notes: [] };
      let newNotes = [];
      this.notebookService.addNoteBook(data, JSON.parse(this.selectedItem.size), newCont[0], this.user.id, null)
          .subscribe({
            next: async (result: any) => {
                for(const [index, note] of Object.entries(notes)){
                  this.notebookService.addNote(note.title, note.data, note.size, newCont[0], result.notebook, this.user.id)
                        .subscribe({
                          next: async (result: any) => {
                            newNotes.push(result.note);
                          }, error: (err)=> {
                            console.log(err);
                          }
                  });
                }
                let currentData: any = newCont[1] == 'own' ? await this.dexieService.getOwnContainers() : ( newCont[1] == 'shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                let containerIndex = currentData.findIndex((cont: any)=> cont.id==Number(newCont[0]));
                let notebooks = currentData[containerIndex].notebooks;
                notebooks.push({id: result.notebook, ...data, notes: newNotes, createdAt: new Date(), updatedAt: new Date(), iconData: this.selectedItem.iconData, size: this.selectedItem.size, containerID: Number(newCont[0]), owner: this.user.id, recipients: null, deleted: false, ownerData: {firstName: this.user.firstName, lastName: this.user.lastName, email: this.user.email, id: this.user.id, profilePicture: this.user.profilePicture}});
                let newMemory = JSON.parse(result.newMemory);

                if (newCont[1] == 'own') {
                    currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedOwnerKey: binaryKey, decrypted: true };
                    this.dexieService.setOwnContainers(currentData);
                    this.containerService.setOwnContainers(currentData);
                    this.successClone(true);
  
                } else if (newCont[1] == 'shared') {
                    currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decryptedRecipientKey: binaryKey, decrypted: true };
                    this.dexieService.setSharedContainers(currentData);
                    this.containerService.setSharedContainers(currentData);
                    this.successClone(true);
  
                } else {
                    currentData[containerIndex] = { ...currentData[containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, notebooks: notebooks, decrypteBackUpPersonKey: binaryKey, decrypted: true };
                    this.dexieService.setDeadManSwitchContainers(currentData);
                    this.containerService.setDeadManSwitchContainers(currentData);
                    this.successClone(true);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.openSnackBar('Cannot clone the notebook!');
                this.disabledButton = false;
            }
      });
      
    }

  openMoveContainerToTrash(){
    this.setData();
    this.dialog.open(this.deleteContainerDialog, { width: '400px' });
  }

  moveToTrashContainer(){
      this.disabledButton = true;
      this.containerService.moveToTrashContainer(this.container.id)
        .subscribe({
          next: async (res: any) => {
            let currentData = this.type == 'own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getDeadManSwitchContainers();
            currentData.splice(this.indexOfContainer, 1);

            if(this.type == 'own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);

            }else if(this.type == 'backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
            }   
            this.dialog.closeAll();
            this.openSnackBar('Container moved to trash!');
            this.disabledButton = false;
            this.goBack();         
          },
          error: (error: HttpErrorResponse) => {
            this.disabledButton = false;
            this.openSnackBar('Cannot delete container!');
          }
        });
  }

  openIconsDialog(){
    this.dialogRef = this.dialog.open(this.IconsDialog);
  }

  closeIconDialog(){
    if(this.dialogRef) this.dialogRef.close();
  }

  selectIcon(icon: any): void {
    this.preview = icon.src;
    // this.src = icon.src;
    this.container_icon = icon.icon;
  }

  openEditDialog(){
    if(this.type=='own'){
        this.container_name = this.ownContainers[this.indexOfContainer].name;
        this.container_description = this.ownContainers[this.indexOfContainer].description;
        this.container_icon = this.ownContainers[this.indexOfContainer].icon;
        this.containerID = this.ownContainers[this.indexOfContainer].id
        this.preview = this.ownContainers[this.indexOfContainer].iconData;
        this.dialog.open(this.editDialog, { width: '500px' });
    } else if (this.type=='backup'){
        this.container_name = this.deadManSwitchContainers[this.indexOfContainer].name;
        this.container_description = this.deadManSwitchContainers[this.indexOfContainer].description;
        this.container_icon = this.deadManSwitchContainers[this.indexOfContainer].icon;
        this.containerID = this.deadManSwitchContainers[this.indexOfContainer].id
        this.preview = this.deadManSwitchContainers[this.indexOfContainer].iconData;
        this.dialog.open(this.editDialog, { width: '500px' });
    }
  }
  
  openShareDialog() {
        this.dialog.open(this.shareDialog, { width: '500px' });
        this.loadingRecipients = true;
        if (this.type == 'own') {
          this.containerService.getRecipientsData(this.ownContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        } else if (this.type == 'backup') {
          this.containerService.getRecipientsData(this.deadManSwitchContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        }     
  }

  saveEditInfoContainer(){
    this.disabledButton = true;
    this.containerService
        .updateContainerInfo({containerID: this.containerID, name: this.container_name, description: this.container_description, icon: this.container_icon })
        .subscribe({
          next: async (res: any)=> {
            let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getSharedContainers();
            currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], name: this.container_name, description: this.container_description, iconData: this.containerService.setIcon(res.icon), icon: res.icon.id};
            if(this.type=='own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
            }else if (this.type=='backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
            }
            this.openSnackBar('Container Info updated successfully!');
            this.dialog.closeAll();
            this.disabledButton = false;
          }, 
          error: (err: any) => {
              console.log(err);
              this.openSnackBar('Some error occured when updating container timer!');
              this.disabledButton = false;
          }
        });
  }

  addRecipient(){
    if(this.newRecipientEmail.length==0){
        this.openSnackBar('Please enter an email address');
    }else if (!this.newRecipientEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
        this.openSnackBar('Please enter a valid email for recipient!');

    } else if(this.recipients.findIndex((e)=>e.email==this.newRecipientEmail)!=-1){
        this.openSnackBar('Already added as a recipient!');

    } else if(this.newRecipientEmail===this.user.email){
        this.openSnackBar('You cannot add yourself as a recipient!');

    } else if(this.userPlan.recipients !== 'unlimited' && this.recipients.length + 1 > Number(this.userPlan.recipients)) {
        this.openSnackBar( `You can only add ${this.userPlan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!` );
    } else {
        if(this.type=='own'){
          if(this.ownContainers[this.indexOfContainer].ownerEncryptionMode==1){
            this.disabledButton = true;
            this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, null, '-')
              .subscribe({
                next: (res)=> {
                    this.dexieService.getOwnContainers().then((data: any) => {
                      let d = data;
                      d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                      this.dexieService.setOwnContainers(d);
                      this.containerService.setOwnContainers(d);
                      this.newRecipientEmail = '';
                      this.disabledButton = false;
                      this.dialog.closeAll();
                    });
                    if(res!=null){
                      this.recipients.push(res);
                    }
                }, error: (err) => {
                  console.log(err);
                }
              })
          }else{
              this.dialog.open(this.assignmentDialog, {width: '500px'});
          }

        }else if(this.type=='backup'){
              this.dialog.open(this.assignmentDialog, {width: '500px'});
        }
      }
  } 

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
  }

  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
  }

  toggleActiveC(): void {
    this.activeC = true;
    this.activeA = false;
    this.activeB = false;
  }

  selectAssignment(){
    this.dialog.closeAll();
    if(this.activeA){
      this.dialog.open(this.addHardwareKeyDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });

    }else if(this.activeB){
      this.dialog.open(this.addPassphraseDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });

    }else{
      this.dialog.open(this.addAnswerQuestionDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabledButton = true;
          this.confirm = false;
        }
      });
    }
  }

  async addPassphrase(){
    if(this.passphraseForRecipientValue.length<6){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.passphraseForRecipientValue!=this.confirmPassphraseForRecipientValue ){
        this.openSnackBar('Passphrases are not matching!');
    } else {
        this.disabledButton = true;
        if(this.type=='own'){
          let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
          let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
          this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'passphrase')
          .subscribe({
            next: (res)=> {
                this.dexieService.getOwnContainers().then((data: any) => {
                  let d = data;
                  d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                  this.dexieService.setOwnContainers(d);
                  this.containerService.setOwnContainers(d);
                  this.dialog.closeAll();
                  this.disabledButton = false;
                  this.newRecipientEmail = '';
                  this.passphraseForRecipientValue = '';
                  this.confirmPassphraseForRecipientValue = '';
                  this.confirm = true;
                });
                if(res!=null){
                  this.recipients.push(res);
                }
            }, error: (err) => {
              console.log(err);
            }
          })
        }else if(this.type=='backup'){
          let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
          let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
          this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'passphrase')
          .subscribe({
            next: (res)=> {
                this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                  let d = data;
                  d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                  this.dexieService.setDeadManSwitchContainers(d);
                  this.containerService.setDeadManSwitchContainers(d);
                  this.dialog.closeAll();
                  this.disabledButton = false;
                  this.newRecipientEmail = '';
                  this.passphraseForRecipientValue = '';
                  this.confirmPassphraseForRecipientValue = '';
                  this.confirm = true;
                });
                if(res!=null){
                  this.recipients.push(res);
                }
            }, error: (err) => {
              console.log(err);
            }
          })
        }
    }
  }

  async addHardwareKey(){
    if(this.hardwareKeyForRecipientValue.length>0){
      this.disabledButton = true;
      if(this.type=='own'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'hardwareKey')
        .subscribe({
          next: (res)=> {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.hardwareKeyForRecipientValue = '';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }else if(this.type=='backup'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'hardwareKey')
        .subscribe({
          next: (res)=> {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.hardwareKeyForRecipientValue = '';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }
    }else{
      this.openSnackBar('Hardware Key is required!');
    }
  }

  async addAnswerQuestion(){
    if(this.answerForRecipientValue.length>0){
      this.disabledButton = true;
      if(this.type=='own'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.ownContainers[this.indexOfContainer].decryptedOwnerKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.answerForRecipientValue.trim().toLowerCase());
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.ownContainers[this.indexOfContainer].id, recipientKey, 'questionAnswer')
        .subscribe({
          next: (res)=> {
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.answerForRecipientValue = '';
                this.question = '3';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }else if(this.type=='backup'){
        let decrypted = this.encryptDecrypt.binaryToBuffer(this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
        let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, this.answerForRecipientValue.trim().toLowerCase());
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.deadManSwitchContainers[this.indexOfContainer].id, recipientKey, 'questionAnswer')
        .subscribe({
          next: (res)=> {
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.disabledButton = false;
                this.newRecipientEmail = '';
                this.answerForRecipientValue = '';
                this.question = '3';
                this.confirm = true;
              });
              if(res!=null){
                this.recipients.push(res);
              }
          }, error: (err) => {
            console.log(err);
          }
        })
      }
    }else{
      this.openSnackBar('Please answer the question!');
    }
  }

  async removeRecipient(recipient){
    let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getDeadManSwitchContainers();
    this.containerService.removeRecipient(recipient.email, currentData[this.indexOfContainer].id)
          .subscribe({
            next: (res: any) => {
                currentData[this.indexOfContainer].recipients = currentData[this.indexOfContainer].recipients.filter(r => r!= recipient.email);
                this.recipients = this.recipients.filter(r => r.email!= recipient.email);
                if(this.type=='own'){
                  this.dexieService.setOwnContainers(currentData);
                  this.containerService.setOwnContainers(currentData);
                } else if (this.type == 'backup') {
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.containerService.setDeadManSwitchContainers(currentData);
                }
                this.openSnackBar('Recipient removed');
            }, error: (err: any) => {
              console.log(err);
            }
          });
  }

  openTimerDialog() { 
    if(this.type=='own'){
        this.container_timer = this.ownContainers[this.indexOfContainer].timer;
        this.container_frequency = this.ownContainers[this.indexOfContainer].frequency;
        this.container_reminder = this.ownContainers[this.indexOfContainer].reminder;
        this.containerID = this.ownContainers[this.indexOfContainer].id
        this.dialog.open(this.timerDialog, {width: '500px'});
    } else if (this.type=='backup'){
        this.container_timer = this.deadManSwitchContainers[this.indexOfContainer].timer;
        this.container_frequency = this.deadManSwitchContainers[this.indexOfContainer].frequency;
        this.container_reminder = this.deadManSwitchContainers[this.indexOfContainer].reminder;
        this.containerID = this.deadManSwitchContainers[this.indexOfContainer].id
        this.dialog.open(this.timerDialog, {width: '500px'});
    }
  }

  saveEditTimer(){
    this.disabledButton = true;
    this.containerService
        .updateContainerTimer({containerID: this.containerID, timer: this.container_timer, frequency: this.container_frequency, reminder: this.container_reminder })
        .subscribe({
          next: async (res: any)=> {
            let currentData = this.type=='own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getDeadManSwitchContainers();
            currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], timer: this.container_timer, frequency: this.container_frequency, reminder: this.container_reminder};
            if(this.type=='own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
            }else if (this.type=='backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
            }
            this.reminder = this.reminderList.find((el)=> this.container_reminder==el.value)['name'];
            this.frequency = this.frequencyList.find((el)=> this.container_frequency==el.value)['name'];
            this.timer = this.timerList.find((el)=> this.container_timer==el.value)['name'];
            this.openSnackBar('Container Timer updated successfully!');
            this.dialog.closeAll();
            this.disabledButton = false;
          }, 
          error: (err: any) => {
              console.log(err);
              this.openSnackBar('Some error occured when updating container timer!');
              this.disabledButton = false;
          }
        });
  }
  
  openChangePassphrase(){
      this.dialog.open(this.changePassphraseDialog, {width: '500px'});
  }

  onChange(event: any){
    this.errorLength = event.length < 6;
  }
  
  onChangeNewConfirm(event: any){
    if((this.newPassphrase.trim()===this.confirmNewPassphrase.trim()) && (this.newPassphrase.trim().length>=6)){
      this.errorMatch = false;
    }else if(this.newPassphrase.trim()!==this.confirmNewPassphrase.trim()){
      this.errorMatch = true;
    }
  }

  async changePassphrase(){
    if(this.oldPassphrase.trim().length===0){
      this.openSnackBar('Please enter the current passphrase!');

    }else if(this.errorMatch){
      this.openSnackBar('Passphrases are not matching!');

    }else if(this.errorLength){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.oldPassphrase.trim()===this.newPassphrase.trim()){
        this.openSnackBar('Please a new passphrase, not the current used one!');
    }else{
        // we need to proceed with the updates
        if(this.type==='own'){ // it is an own container
          let currentKey = new Uint8Array(JSON.parse(this.ownContainers[this.indexOfContainer].ownerKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateOwnerKey(this.ownContainers[this.indexOfContainer].id, newKey)
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getOwnContainers().then((data: any)=>{
                    let d = data;
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], 'ownerKey': newKey};
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else if(this.type==='shared'){ // it is a shared container
          let currentKey = new Uint8Array(JSON.parse(this.sharedContainers[this.indexOfContainer].recipientKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateRecipientKey(this.sharedContainers[this.indexOfContainer].id, newKey, this.localstorage.getEmail())
              .subscribe({
                next: (res: any)=>{
                  this.dexieService.getSharedContainers().then((data: any)=>{
                    let d = data;
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], 'recipientKey': newKey};
                    this.dexieService.setSharedContainers(d);
                    this.containerService.setSharedContainers(d);
                    this.dialog.closeAll();
                    this.openSnackBar('Passphrase updated successfully!');
                  });
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }else{ // it is a backup person container
          let currentKey = new Uint8Array(JSON.parse(this.deadManSwitchContainers[this.indexOfContainer].backUpPersonKey).data);
          try{
            let decrypted = await this.encryptDecrypt.decryptKey(currentKey, await this.encryptDecrypt.getPBKDF2Hash1M(this.oldPassphrase.trim()));
            let encrypted = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.newPassphrase.trim()));
            let newKey = JSON.stringify(this.encryptDecrypt.toBuffer(encrypted));
            this.containerService.updateBackupPersonKey(this.deadManSwitchContainers[this.indexOfContainer].id, newKey)
              .subscribe({
                next: (res: any)=>{
                    this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
                      let d = data;
                      d[this.indexOfContainer] = {...d[this.indexOfContainer], 'backUpPersonKey': newKey};
                      this.dexieService.setDeadManSwitchContainers(d);
                      this.containerService.setDeadManSwitchContainers(d);
                      this.dialog.closeAll();
                      this.openSnackBar('Passphrase updated successfully!');
                    }); 
                },
                error: (error: HttpErrorResponse)=>{
                  this.openSnackBar('Cannot update Passphrase!');
                }
            });
          }catch(err){
            this.openSnackBar('Please verify the current passphrase entered!');
          }
        }
    }
  }

  async shareItem(item){
    await this.setData();
    if(this.type=='own'){
      let decryptedKey = this.container.decryptedOwnerKey;
      if(item.type=='Password') {
          const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
          const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
          const dataDecrypted = JSON.parse(dataDecryption);
          this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
                    .subscribe({
                      next: (res: any) => {
                        this.recipients = res.recipients;
                        this.loadingRecipients = false;
                      }, error: (err: any) => {
                        console.log(err);
                      }
                    });
      
      } else if(item.type=='Notebook') {
        let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
        let notes = await this.setNotes(decryptedKey, res['notesData']);
        this.selectedItem = {...item, notes: notes};
        this.loadingRecipients = true;
        this.dialog.open(this.shareItemDialog, {width: '500px'});
        this.containerService.getRecipientsData(this.selectedItem.recipients)
                  .subscribe({
                    next: (res: any) => {
                      this.recipients = res.recipients;
                      this.loadingRecipients = false;
                    }, error: (err: any) => {
                      console.log(err);
                    }
                  });

      }
    } else if(this.type=='shared'){
      let decryptedKey = this.container.decryptedRecipientKey;
      if(item.type=='Password') {
          const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
          const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
          const dataDecrypted = JSON.parse(dataDecryption);
          this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
                    .subscribe({
                      next: (res: any) => {
                        this.recipients = res.recipients;
                        this.loadingRecipients = false;
                      }, error: (err: any) => {
                        console.log(err);
                      }
                    });

      } else if(item.type=='Notebook') {
          let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
          let notes = await this.setNotes(decryptedKey, res['notesData']);
          this.selectedItem = {...item, notes: notes};
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
                    .subscribe({
                      next: (res: any) => {
                        this.recipients = res.recipients;
                        this.loadingRecipients = false;
                      }, error: (err: any) => {
                        console.log(err);
                      }
                    });

      }
    } else{
      let decryptedKey = this.container.decryptedBackUpPersonKey;
      if(item.type=='Password') {
          const passwordDecryption = await this.encryptDecrypt.decryptData(item.password, decryptedKey);
          const dataDecryption = await this.encryptDecrypt.decryptData(item.passData, decryptedKey);
          const dataDecrypted = JSON.parse(dataDecryption);
          this.selectedItem = {...item, password: passwordDecryption, ...dataDecrypted};
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
                    .subscribe({
                      next: (res: any) => {
                        this.recipients = res.recipients;
                        this.loadingRecipients = false;
                      }, error: (err: any) => {
                        console.log(err);
                      }
                    });

      } else if(item.type=='Notebook') {
          let res = await lastValueFrom(this.notebookService.getNotes(item.notes));
          let notes = await this.setNotes(decryptedKey, res['notesData']);
          this.selectedItem = {...item, notes: notes};
          this.loadingRecipients = true;
          this.dialog.open(this.shareItemDialog, {width: '500px'});
          this.containerService.getRecipientsData(this.selectedItem.recipients)
                    .subscribe({
                      next: (res: any) => {
                        this.recipients = res.recipients;
                        this.loadingRecipients = false;
                      }, error: (err: any) => {
                        console.log(err);
                      }
                    });

      }
    }    
  }

  async addNewRecipient(recipient){
      this.disabledButton = true;
      let decryptedKey = this.type =='own' ? this.ownContainers[this.indexOfContainer].decryptedOwnerKey : (this.type=='shared' ?  this.sharedContainers[this.indexOfContainer].decryptedRecipientKey :  this.deadManSwitchContainers[this.indexOfContainer].decryptedBackUpPersonKey);
      if(this.selectedItem.type=="Notebook"){
          let recipientKey = await this.encryptDecrypt.encryptDataRSA(decryptedKey, JSON.parse(atob(recipient['publicKey'])));
          this.recipientItemService.addRecipientNotebook(recipient.email, this.selectedItem.id, recipientKey)
                            .subscribe({
                              next: async (res)=> {
                                      this.recipients = [...this.recipients, {...recipient, publicKey: null}];
                                      let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                                      let notebooks = currentData[this.indexOfContainer].notebooks;
                                      let indexOfNotebook = notebooks.findIndex(notebook => notebook.id==this.selectedItem.id);
                                      notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook], recipients: [...notebooks[indexOfNotebook].recipients, recipient.email]};
                                      currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], notebooks};
                                      if(this.type=='own'){
                                            this.dexieService.setOwnContainers(currentData);
                                            this.containerService.setOwnContainers(currentData);
                                            this.successShare(true);
                                      }else if(this.type=='shared'){
                                            this.dexieService.setSharedContainers(currentData);
                                            this.containerService.setSharedContainers(currentData);
                                            this.successShare(true);
                                      }else if (this.type=='backup'){
                                            this.dexieService.setDeadManSwitchContainers(currentData);
                                            this.containerService.setDeadManSwitchContainers(currentData);
                                            this.successShare(true);
                                      }                                  
                              }, error: (err) => {
                                console.log(err); 
                                this.disabledButton = false;
                                this.openSnackBar('Failed to add recipient, please try again!');
                              }
                            });
            
        }else if(this.selectedItem.type=="Password"){
            let recipientKey = await this.encryptDecrypt.encryptDataRSA(decryptedKey, JSON.parse(atob(recipient['publicKey'])));
            this.recipientItemService.addRecipientPassword(recipient.email, this.selectedItem.id, recipientKey)
              .subscribe({
                next: async (res)=> {
                    this.recipients = [...this.recipients, {...recipient, publicKey: null}];
                    let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                    let passwords = currentData[this.indexOfContainer].passwords;
                    let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                    passwords[indexOfPassword] = {...passwords[indexOfPassword], recipients: [...passwords[indexOfPassword].recipients, recipient.email]};
                    currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], passwords};
                        if(this.type=='own'){
                              this.dexieService.setOwnContainers(currentData);
                              this.containerService.setOwnContainers(currentData);
                              this.successShare(false);
                        }else if(this.type=='shared'){
                              this.dexieService.setSharedContainers(currentData);
                              this.containerService.setSharedContainers(currentData);
                              this.successShare(false);
                        }else if (this.type=='backup'){
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.containerService.setDeadManSwitchContainers(currentData);
                              this.successShare(false);
                        }
                }, error: (err) => {
                  console.log(err); 
                  this.disabledButton = false;
                  this.openSnackBar('Failed to add recipient, please try again!');
                }
              })
        }
  }

  addRecipientItem(){
    this.userService.getUserData(this.newRecipientEmail)
      .subscribe({
        next: (res: any)=> {
              if(res['publicKey'] && res['rsaKeysUpdated']==true ){
                  this.addNewRecipient(res);
              }else{
                  this.openSnackBar('You cannot share with this user, please add another recipient!');
              }
        }, error: (err)=> {
            console.log(err);
            this.openSnackBar('User not found, please try another user!');
        }
      })
  }

  removeRecipientItem(recipient){

      this.disabledButton = true;
      if(this.selectedItem.type=="Notebook"){
          this.recipientItemService.removeRecipientNotebook(recipient.email, this.selectedItem.id)
            .subscribe({
              next: async (res)=> {

                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);
                  let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                  let notebooks = currentData[this.indexOfContainer].notebooks;
                  let indexOfNotebook = notebooks.findIndex(password => password.id==this.selectedItem.id);
                  notebooks[indexOfNotebook] = {...notebooks[indexOfNotebook],  recipients: notebooks[indexOfNotebook].recipients.filter((rec)=> rec!=recipient.email)};
                  currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], notebooks};
                        if(this.type=='own'){
                            this.dexieService.setOwnContainers(currentData);
                            this.containerService.setOwnContainers(currentData);
                            this.successRemoveShare(true);
                        }else if(this.type=='shared'){
                            this.dexieService.setSharedContainers(currentData);
                            this.containerService.setSharedContainers(currentData);
                            this.successRemoveShare(true);
                        }else if (this.type=='backup'){
                            this.dexieService.setDeadManSwitchContainers(currentData);
                            this.containerService.setDeadManSwitchContainers(currentData);
                            this.successRemoveShare(true);
                        }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }else if(this.selectedItem.type=="Password"){
          this.recipientItemService.removeRecipientPassword(recipient.email, this.selectedItem.id)
            .subscribe({
              next: async (res)=> {
                  this.recipients = this.recipients.filter((rec)=> rec.email!=recipient.email);
                  let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                  let passwords = currentData[this.indexOfContainer].passwords;
                  let indexOfPassword = passwords.findIndex(password => password.id==this.selectedItem.id);
                  passwords[indexOfPassword] = {...passwords[indexOfPassword],  recipients: passwords[indexOfPassword].recipients.filter((rec)=> rec!=recipient.email)};
                  currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], passwords};
                        if(this.type=='own'){
                              this.dexieService.setOwnContainers(currentData);
                              this.containerService.setOwnContainers(currentData);
                              this.successRemoveShare(false);
                        }else if(this.type=='shared'){
                              this.dexieService.setSharedContainers(currentData);
                              this.containerService.setSharedContainers(currentData);
                              this.successRemoveShare(false);
                        }else if (this.type=='backup'){
                              this.dexieService.setDeadManSwitchContainers(currentData);
                              this.containerService.setDeadManSwitchContainers(currentData);
                              this.successRemoveShare(false);
                        }
              }, error: (err) => {
                console.log(err); 
                this.disabledButton = false;
                this.openSnackBar('Failed to remove recipient, please try again!');
              }
            })
      }
  }

  successShare(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      this.newRecipientEmail = '';
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Recipient added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Recipient added successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }

  successRemoveShare(isNotebook: boolean) {
      this.dialog.closeAll();
      this.setData();
      this.disabledButton = false;
      if (isNotebook) {
        this.selectedFilter = 'notebooks';
        this.openSnackBar('Recipient removed successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Notebook').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      } else {
        this.selectedFilter = 'passwords';
        this.openSnackBar('Recipient removed successfully!');
        this.containerService.setContainerContentData(this.containerContentData.filter(item => item.type === 'Password').sort( (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      }
    }
}
