import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class HomeDataService {

    constructor(private http: HttpClient) { }

    getHomeData(email: any){
        return this.http.get(`${environment.apiUrl}/home/get-home-data?email=${email}`);
    }
}