<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'tools'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div class="tool-details-wrapper">
            <button class="back-button-wrapper" (click)="goBack()">
                <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
                <span>Back</span>
            </button>

            <div class="tool-details-top">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="pointer tool-info">
                    <img src="assets/images2/dashboard/repository-icon.svg" alt="repo icon" class="tool-icon">
                   <div class="key-input tool-title">
                    {{selectedRepository.name}}
                   </div> 
                </div>

                <button *ngIf="selectedRepository.creator == currentUser.id" mat-button [matMenuTriggerFor]="menuItems">
                    <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                </button>
              

                <mat-menu #menuItems="matMenu" class="dropdown-content custom-menu">
                    <button mat-menu-item (click)="openUpdateRepositoryDialog()">
                        <div class="flex option">
                            <img class="p-1"
                                [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                alt="edit" />
                            <a class="p-1">Edit</a>
                        </div>
                    </button>
                    <button mat-menu-item (click)="openRemoveRepositoryDialog()">
                        <div class="flex option">
                            <img class="p-1"
                                [src]="dark ? 'assets/images/dashboard/container-card/trash-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                alt="copy" />
                            <a class="p-1">Delete</a>
                        </div>
                    </button>
                </mat-menu>
            </div>

            <div class="tool-details-box-wrapper">
                <div class="tool-details-box">
                    <img src="assets/images/dashboard/container-content/toggle-arrow.svg" alt="toggle icon"
                        class="toggle-icon" [ngClass]="{ 'flipped': !toggleTimer }" (click)="timerToggle()">

                    <div class="box-row">
                        <h5>Description :</h5>
                        <p>{{selectedRepository.description}}</p>
                    </div>
                    <div class="box-row second" *ngIf="toggleTimer">
                        <h5>Shared With :</h5>
                        <p *ngIf="repository?.recipients.length === 0">Not shared</p>
                        <div class="viewer" fxLayout="row" *ngIf="repository?.recipients.length !== 0">
                            <div class="recipients-wrapper" *ngFor="let r of repository?.recipients; let i = index">
                                <div class="recipient">
                                    <div *ngIf="i<3" [ngClass]="{
                              'user-icon-yellow': i % 2 === 0,
                              'user-icon-blue': i % 2 !== 0
                              }" [matTooltip]="r">
                                        {{ r.slice(0, 1).toUpperCase() }}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  fxLayout="column" fxLayoutGap="10px">
                            <div fxLayout="row" fxLayoutGap="5px">
                                <div *ngFor="let group of selectedRepository.sharedGroups" class="groups-btn" [ngClass]="{'dark':dark}">
                            <img [src]="dark? 'assets/images2/dashboard/developers-tools/group-dark.svg' : 'assets/images2/dashboard/developers-tools/group-light.svg'" width="20"/>
                            {{group.name}}
                                </div>
                            
                            </div>
                            
                            <div class="member-list-overlap">
                                <div *ngFor="let member of selectedRepository.sharedUsers.slice(0, 4)"
                                    class="member-thumbnail-overlap">
                                    <img *ngIf="member.profilePicture && member.profilePicture.trim() !== '' && member.profilePicture.trim() !== '-' && member.profilePicture.trim() !== '_'"
                                        [src]="member.profilePicture" alt="Member profile image"
                                        class="member-profile-img" />
                                    <div *ngIf="!member.profilePicture || member.profilePicture.trim() === '' || member.profilePicture.trim() === '-' || member.profilePicture.trim() === '_'"
                                        class="member-fallback">
                                        {{ member.firstName?.charAt(0).toUpperCase() }}{{
                                        member.lastName?.charAt(0).toUpperCase() }}
                                    </div>
                                </div>
                                <span *ngIf="selectedRepository.sharedUsers.length > 4" class="more-members">
                                    +{{ selectedRepository.sharedUsers.length - 4 }} more
                                </span>
                            </div>
  
                        </div>

                        
                    </div>
                </div>
            </div>

            <div class="containers-devider"></div>

            <div *ngIf="selectedRepository.creator == currentUser.id" fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
                <div class="title"></div>
                <button mat-button [matMenuTriggerFor]="menu1" class="new-key-button ">
                    <img src="assets/images/dashboard/container-content/plus.svg" alt="plus">
                    <span style="color:#fff">Add</span></button>

                <mat-menu #menu1="matMenu" class="dropdown-content custom-menu menu1">
                    <button mat-menu-item (click)="openkeyDialog()">
                        <div class="option">
                            <img [src]="dark ? 'assets/images2/dashboard/add-box-icon-light.svg' : 'assets/images2/dashboard/add-box-icon.svg'"
                                alt="pass icon" style="width: 27px; height: 28px">
                            <a>Add Key</a>
                        </div>
                    </button>
                    <button mat-menu-item (click)="openFileDialog()">
                        <div class="option">
                            <img [src]="dark ? 'assets/images2/dashboard/upload-icon-light.svg' : 'assets/images2/dashboard/upload-icon.svg'"
                                alt="pass icon" style="width: 27px; height: 28px">
                            <a>Upload</a>
                        </div>
                    </button>

                </mat-menu>
            </div>


            <div>
                <div class="table-container">
                    <table aria-describedby="list of keys">
                        <thead>
                            <tr>
                                <th class="header-cell">Key</th>
                                <th class="header-cell">Value</th>
                                <th class="header-cell" *ngIf="selectedRepository.creator == currentUser.id"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let key of selectedRepository.repositoryKeys  | search :searchString; let i = index;">
                                <td class="table-column-name">
                                    <span>{{key.key}}</span>
                                </td>

                                <td class="table-column-value">
                                    <span>{{key.value}}</span>
                                </td>
                                <td *ngIf="selectedRepository.creator == currentUser.id">
                                    <div class="dropdown-container">
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                        </button>
                                        <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                                            <button mat-menu-item (click)="openUpdateKeyDialog(key)">
                                                <div class="flex option">
                                                    <img class="p-1"
                                                        [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                        alt="edit" />
                                                    <a class="p-1">Edit</a>
                                                </div>
                                            </button>
                                            <button mat-menu-item (click)="openRemoveKeyDialog(key)">
                                                <div class="flex option">
                                                    <img class="p-1"
                                                        [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                        alt="delete" />
                                                    <a class="p-1">Delete</a>
                                                </div>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>




<!--- update Repository -->
<ng-template #updateRepositoryDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px" class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row " fxLayoutAlign="center center" fxLayoutGap="8px">
                        <div class="img-wrapper">
                            <img src="assets/images2/dashboard/repository-icon.svg" alt="key icon" width="30">
                        </div>
                        <span class="dialog-title">Update Repository</span>
                    </div>
                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                        class="pointer" />
                </div>
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Name</span>
                        <input autocomplete="off" class="dialog-input" placeholder="key" [(ngModel)]="selectedRepository.name" />
                    </div>
                </div>
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Description</span>
                        <textarea row="5" autocomplete="off" class="dialog-input" placeholder="value" [(ngModel)]="selectedRepository.description" ></textarea>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn " (click)="updateRepository()">update</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<!-- add key dialog -->
<ng-template #addKeyDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px" class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row " fxLayoutAlign="center center" fxLayoutGap="8px">
                        <div class="img-wrapper">
                            <img src="assets/images2/dashboard/secret-key-icon.svg" alt="key icon">
                        </div>
                        <span class="dialog-title">Add Secret Key</span>
                    </div>
                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                        class="pointer" />
                </div>



                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Key</span>
                        <input autocomplete="off" class="dialog-input" placeholder="key" [(ngModel)]="key" />
                    </div>
                </div>
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Value</span>
                        <input autocomplete="off" class="dialog-input" placeholder="value" [(ngModel)]="value" />
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn " (click)="AddOneKey()">Add</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<!-- update Key -->
<ng-template #updateKeyDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px" class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row " fxLayoutAlign="center center" fxLayoutGap="8px">
                        <div class="img-wrapper">
                            <img src="assets/images2/dashboard/secret-key-icon.svg" alt="key icon">
                        </div>
                        <span class="dialog-title">Update Secret Key</span>
                    </div>
                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                        class="pointer" />
                </div>
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Key</span>
                        <input autocomplete="off" class="dialog-input" placeholder="key" [(ngModel)]="selectedKeyToUpdate.key" />
                    </div>
                </div>
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="input-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="input-item">
                        <span class="dialog-label">Value</span>
                        <input autocomplete="off" class="dialog-input" placeholder="value" [(ngModel)]="selectedKeyToUpdate.value" />
                    </div>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn " (click)="updateSelectedKey()">update</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<!-- upload file dialog -->
<ng-template #uploadFileDialog>
    <mat-dialog-content>
        <div *ngIf="isLoading" class="loading-overlay">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isLoading" class="dialog-content">
            <div fxLayout="column" fxLayoutGap="15px" class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row " fxLayoutAlign="center center" fxLayoutGap="8px">
                        <div class="img-wrapper">
                            <img src="assets/images2/dashboard/secret-key-icon.svg" alt="key icon">
                        </div>
                        <span class="dialog-title">Upload File</span>
                    </div>


                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()"
                        class="pointer" />
                </div>



                <div class=" files-wrapper">
                    <label for="fileInput" class="import-container" fxLayout="column" fxLayoutGap="5px"
                        fxLayoutAlign="center center">
                        <img src="assets/images/dashboard/container-content/import-icon.svg" alt="alt">
                        <p class="file-input">Import File</p>

                        <input id="fileInput" type="file" style="display: none;" (change)="onFileChange($event)" />
                    </label>

                    <div *ngIf="filesToShow.length>0 " fxLayout="column" fxLayoutGap="15px" style="width: 100%;">
                        <div *ngFor="let file of filesToShow; let i = index;" fxLayout="row"
                            fxLayoutAlign="space-between center" fxLayoutGap="5px" class="file-container">
                            <div fxLayoutAlign="start center" fxLayoutGap="8px" class="file-info">
                                <img [src]="file.icon" alt="file-icon" width="30px" />
                                <a class="form-label" [href]="file.data"
                                    [download]="file.name + file.extension">{{file.name}}{{file.extension}}</a>
                            </div>
                            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                                <img *ngIf="!file.uploaded" src="assets/images2/dashboard/developers-tools/x-close.svg"
                                    alt="x icon" (click)="removeFile(i)" class="pointer" />
                                <img *ngIf="!file.uploaded" src="assets/images2/dashboard/developers-tools/upload.svg"
                                    alt="upload icon" class="pointer" (click)="extractData(file,i)" />
                                <img *ngIf="file.uploaded" src="assets/images2/dashboard/developers-tools/checked.svg"
                                    alt="upload icon" class="pointer" />
                            </div>
                        </div>
                    </div>
                </div>


                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="btns">
                    <button class="cancel-btn" (click)="cancel()">Cancel</button>
                    <button class="save-btn" (click)="AddKeys()">Add</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #removeRepositoryDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title" [ngClass]="{'dark':dark}">remove Repository</span>
                <span class="delete-dialog-description" [ngClass]="{'dark':dark}">Are you sure to remove this Repository</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="deleteCurrentRepository()">remove Repository</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #removeSelectedKeyDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title" [ngClass]="{'dark':dark}">Remove Key</span>
                <span class="delete-dialog-description" [ngClass]="{'dark':dark}" >Are you sure to remove this Key</span>
                <div fxLayout="row" fxLayoutGap="10px" class="full-width">
                    <button class="cancel-btn full-width" (click)="cancel()">Cancel</button>
                    <button class="delete-btn full-width" (click)="removeKey()">remove Key</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>