<div class="content" fxLayout="column" fxLayoutGap="20px">
    <div class="title">Your Activity Logs</div>
    <div class="divider"></div>
    <div *ngIf="loading" class="flex  justify-center items-center justify-items">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <div *ngFor="let log of logs" fxLayout="column" fxLayoutGap="20px">
            <div class="device-item">
                <div class="device-left">
                    <div class="device-img">
                        <img *ngIf="!log.action.includes('Android') && !log.action.includes('iOS')" src="assets/images2/dashboard/settings/mac-icon.svg" alt="desktop">
                        <img *ngIf="log.action.includes('Android') || log.action.includes('iOS')" src="assets/images2/dashboard/settings/phone-icon.svg" alt="phone">
                    </div>
                    <div class="device-img">
                        <img class="browser" *ngIf="log.action.includes('Chrome')" src="assets/images2/dashboard/import-settings/chrome.svg" alt="chrome">
                        <img class="browser" *ngIf="log.action.includes('Edge')" src="assets/images2/dashboard/import-settings/edge.svg" alt="edge">
                        <img class="browser" *ngIf="log.action.includes('Firefox')" src="assets/images2/dashboard/import-settings/firefox.svg" alt="firefox">
                        <img class="browser" *ngIf="log.action.includes('Opera')" src="assets/images2/dashboard/import-settings/opera.svg" alt="opera">
                        <img class="browser" *ngIf="log.action.includes('Safari')" src="assets/images2/dashboard/import-settings/safari.png" alt="safari">
                        <img class="browser" *ngIf="log.action.includes('Android')" src="assets/images2/landing-page/android.svg" alt="android">
                        <img class="browser" *ngIf="log.action.includes('iOS')" src="assets/images2/landing-page/ios.png" alt="ios">
                    </div>
                    <div class="device-info">
                        <div class="info-top">
                            <span class="name">{{log.action}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="divider"></div>

        </div>
    </div>
</div>