import { Component ,ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { Location } from '@angular/common'
import { NetworksService } from 'src/app/services/networks/networks.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { RepositoryService } from 'src/app/services/repository/repository.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-network-member',
  templateUrl: './network-member.component.html',
  styleUrl: './network-member.component.scss',
   providers: [DatePipe]
})
export class NetworkMemberComponent {
  @ViewChild('removeRepositoryAccessDialog') updateRepositoryDialog: any;
  memberID: any;
  networkMember: any;
  user: any
  loading = true;
  disabled = false;
  searchString = '';
  selectedFilter: string = 'containers';
  tableData = [];
  containers = [];
  repositories = [];
  teams = [];
  selectedRepository:any

  get dark(): any {
    return this.theme.dark;
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'dd MMM YYYY');
  }


  constructor(private theme: ThemeService, private route: ActivatedRoute,public dialog: MatDialog, private location: Location, private networkService: NetworksService, private localstorageService: LocalStorageService, private containerService: ContainerService, private _snackBar: MatSnackBar, private groupsService: GroupsService, private dexieService: DexieService, private repositoryService: RepositoryService, private datePipe: DatePipe) {
    this.memberID = this.route.snapshot.paramMap.get('id');
    this.user = JSON.parse(this.localstorageService.getUser());
    this.networkService.getNetworkMemberByID(this.memberID, this.user.id).subscribe({
      next: (res: any) => {
        this.networkMember = res.member;
        this.containers = res.containers.map((c) => { return { ...c, icon: this.containerService.setIcon(c.iconData) } });
        this.tableData = res.containers.map((c) => { return { ...c, icon: this.containerService.setIcon(c.iconData) } });
        this.teams = res.groups;
        this.loading = false;
      }, error: (err: any) => {
        console.log('Network Member: ', err);
      }
    });
    this.getRepositoriesSharedWithMember()
  }

  goBack() {
    this.location.back();
  }

  filterData($event) {
    if (this.selectedFilter === 'containers') {
      this.tableData = this.containers;
    }
    else if (this.selectedFilter === 'repositories') {
      this.tableData = this.repositories;
    }
    else {
      this.tableData = this.teams;
    }
  }
  
  cancel() {
    this.dialog.closeAll();
  }

  removeMemberFromContainer(container) {
    this.disabled = true;
    this.containerService.removeRecipient(this.networkMember.email, container.id)
      .subscribe({
        next: (res: any) => {
          this.dexieService.getOwnContainers().then((data: any) => {
            let d = data;
            let index = d.findIndex((cont) => cont.id === container.id);
            d[index].recipients = d[index].recipients.filter(r => r != this.networkMember.email);
            let localIndex = this.containers.findIndex((cont) => cont.id === container.id);
            this.containers.splice(localIndex, 1);
            this.dexieService.setOwnContainers(d);
            this.containerService.setOwnContainers(d);
            this.disabled = false;
            this.tableData = this.containers;
            this.openSnackBar('Recipient removed successfully!');
          });
        }, error: (err: any) => {
          console.log(err);
          this.disabled = false;
          this.openSnackBar('An issue occured when removing this recipient, please try again later!');
        }
      })
  }

  removeMemberFromTeam(team) {
    this.disabled = true;
    this.groupsService.removeMemberFromGroup(team.id, this.memberID).subscribe({
      next: (res) => {
        let index = this.teams.findIndex(g => g.id == team.id);
        this.teams.splice(index, 1);
        this.tableData = this.teams;
        this.openSnackBar('Member removed successfully!');
        this.disabled = false;
      }, error: (err) => {
        this.disabled = false;
        this.openSnackBar('An issue occured when removing this member, please try again later!');
      }
    })
  }

  getRepositoriesSharedWithMember() {
    this.repositoryService.getRepositoriesSharedWithMember(this.user.id, this.memberID).subscribe({
      next: (res: any) => {
        this.repositories = res.data
      }, error: (error) => {
        console.error(error)
        this.openSnackBar('An issue occured when removing this member, please try again later!');
      }
    })
  }

  removeShareWithMemberFromRepository(){
    this.repositoryService.deleteShareRepoMember(this.selectedRepository.id,this.user.id,this.memberID).subscribe({
      next: (res: any) => {
        this.repositories = this.repositories.filter(repo=>{
          return repo.id!= res.data.id
        })
        this.cancel()
        this.openSnackBar('Access Removed Successfully');
      }, error: (error) => {
        console.error("error removing share from member  :", error)
        this.openSnackBar('An issue occured when removing Access from this member, please try again later!');
      }
    })
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  openRemoveShareRepositoryDialog(repository) {
    this.selectedRepository = repository
    this.dialog.open(this.updateRepositoryDialog, { width: '450px' });
  }

}
