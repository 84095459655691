import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';

@Component({
  selector: 'app-notebooks-details',
  templateUrl: './notebooks-details.component.html',
  styleUrls: ['./notebooks-details.component.scss']
})

export class NotebooksDetailsComponent implements OnInit {
    @ViewChild('start') start: any;
    @ViewChild('deleteItemDialog') deleteItemDialog: any;

    index: any;
    type: any;
    user: any;
    notebookId: any;
    containerId: any;
    isTableView = false;
    disabled = false;
    loading = true;
    noteID: number = 0;
    searchString = '';
    selectedSidebar = 'containers';
    email = '';

    get dark() {
      return this.theme.dark;
    }

    get ownContainers(): any {
      return this.containerService.ownContainers;
    }

    get sharedContainers(): any {
      return this.containerService.sharedContainers;
    }

    get deadManSwitchContainers(): any {
      return this.containerService.deadManSwitchContainers;
    }

    get selectedNotebookData () {
      return this.containerService.selectedNotebookData;
    }

    get ownNotebooks () {
      return this.containerService.ownNotebooks;
    }

    get sharedNotebooks () {
      return this.containerService.sharedNotebooks;
    }

    constructor(private route: ActivatedRoute, private router: Router, private theme: ThemeService, private location: Location, private _snackBar: MatSnackBar, private containerService: ContainerService, private localstorage: LocalStorageService, private dialog: MatDialog, private dexieService: DexieService, private notebookService: ContainerNoteService, private sanitizer: DomSanitizer, private encryptDecrypt: EncryptDecryptService) {
      this.email = this.localstorage.getEmail();
      this.user = JSON.parse(this.localstorage.getUser());
      this.type = this.route.snapshot.paramMap.get('type');
      this.containerId = Number(this.route.snapshot.paramMap.get('containerId'));
      this.notebookId = Number(this.route.snapshot.paramMap.get('notebook'));
      this.setData();
    }

    ngOnInit() {
      this.setData();
    }

    isString(value: any): boolean {
      return typeof value === 'string';
    }

    async setNotes(binary: any, notes: any){    
      if(notes.length>0){
        let notesData = notes.map(async (n: any)=>{
          let decryption = await this.encryptDecrypt.decryptData(n.data, binary);
          let nDecrypted = JSON.parse(decryption);
          let size = JSON.parse(n.size);
          return {title: n.title ?? nDecrypted.name, ...nDecrypted, id: n.id, notebookID: n.notebookID, containerID: n.containerID, size: {...size, memory: Number(size.memory)}, owner: n.owner, ownerData: n.ownerData, recipients: n.recipients ?? []}
        })
        return await Promise.all(notesData);
      }else{
        return [];
      }
    }

    sanitizeIcon(base64String: string, mediaType: string) {
      return mediaType === 'application/octet-stream'
        ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
        : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
    }

    async setNotebook(notebook, binary) {
        const icon = notebook?.iconData;
        const size = JSON.parse(notebook?.size);
        const sizeWithMemory = { ...size, memory: Number(size.memory) };

        let res = await lastValueFrom(this.notebookService.getNotes(notebook?.notes));

        let notes = await this.setNotes(binary, res['notesData']);

        // Check if icon needs to be sanitized
        if (!icon.data.includes('assets/images/predefined-icons') && !icon.data.includes(';base64,')) {
            const noteData = new Uint8Array(JSON.parse(icon.data).data);
            const noteBase64String = btoa(noteData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const iconData = this.sanitizeIcon(noteBase64String, icon.type);

            return { ...notebook, createdAt: new Date(notebook?.createdAt), icon: { id: icon.id, data: iconData }, size: sizeWithMemory, owner: notebook?.owner, notes };
        } else {
            return { ...notebook, createdAt: new Date(notebook?.createdAt), icon: icon, size: sizeWithMemory, owner: notebook?.owner, notes };
        }
    }

    async setData() {
      
      if(!Number.isNaN(this.containerId)){
        this.selectedSidebar = 'containers';
        if (this.type == 'own') {
          this.index = this.ownContainers.findIndex(container=>container.id==this.containerId);
          let decryptedNotebook = await this.setNotebook(this.ownContainers[this.index]?.notebooks?.find((notebook)=>notebook.id === this.notebookId), this.ownContainers[this.index]?.decryptedOwnerKey);
          this.containerService.setSelectedNotebookData(decryptedNotebook);
          this.loading = false;
        } else if (this.type == 'shared') {
          this.index = this.sharedContainers.findIndex(container=>container.id==this.containerId);
          let decryptedNotebook = await this.setNotebook(this.sharedContainers[this.index]?.notebooks?.find((notebook)=>notebook.id === this.notebookId), this.sharedContainers[this.index]?.decryptedRecipientKey);
          this.containerService.setSelectedNotebookData(decryptedNotebook);
          this.loading = false;
        } else {
          this.index = this.deadManSwitchContainers.findIndex(container=>container.id==this.containerId);
          let decryptedNotebook = await this.setNotebook(this.deadManSwitchContainers[this.index]?.notebooks?.find((notebook)=>notebook.id === this.notebookId), this.deadManSwitchContainers[this.index]?.decryptedBackUpPersonKey);
          this.containerService.setSelectedNotebookData(decryptedNotebook);
          this.loading = false;
        }
      }else{
        this.selectedSidebar = 'home';
        if (this.type == 'own') {
          let decryptedNotebook = await this.setNotebook(this.ownNotebooks?.find((notebook)=>notebook.id === this.notebookId), this.ownNotebooks?.find((notebook)=>notebook.id === this.notebookId)?.decryptedKey);
          this.containerService.setSelectedNotebookData(decryptedNotebook);
          this.loading = false;
        }else if (this.type == 'shared'){
          let decryptedNotebook = await this.setNotebook(this.sharedNotebooks?.find((notebook)=>notebook.id === this.notebookId), this.sharedNotebooks?.find((notebook)=>notebook.id === this.notebookId)?.decryptedKey);
          this.containerService.setSelectedNotebookData(decryptedNotebook);
          this.loading = false;
        }
      }
    }

    switchView(value) {
      this.isTableView = value;
    }

    goBack(): void {
      this.location.back();
    }

    toggleSidebar() {
      this.start.toggle();
    }

    async navigateToAdd() {
      await this.setData();
      this.router.navigate(['/note-details', this.type, this.index!=undefined ? this.index : 'none', this.selectedNotebookData.id, 0]);
    }

    async selectNote(index: number) {
      await this.setData();
      this.noteID = this.selectedNotebookData.notes[index].id;
      this.router.navigate(['/note-details', this.type, this.index!=undefined ? this.index : 'none', this.selectedNotebookData.id, this.noteID]);
    }

    async deleteNote(index: number) {
      await this.setData();
      this.noteID = this.selectedNotebookData.notes[index].id;
      this.dialog.open(this.deleteItemDialog, { width: '400px' });
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    cancelDeleteItem() {
      this.dialog.closeAll();
    }

    moveToTrashClassifiedNote(){
      this.disabled = true;
      this.notebookService.moveToTrashNote(this.containerId, this.notebookId, this.noteID)
        .subscribe({
          next: async (result: any) => {
            let currentData: any = this.type == 'own' ? await this.dexieService.getOwnContainers() : ( this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
            let notebooks = currentData[this.index].notebooks;
            let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookId);
            let noteIndex = notebooks[notebookIndex].notes.findIndex((note)=>note==this.noteID);
            notebooks[notebookIndex].notes.splice(noteIndex, 1);
            currentData[this.index] = { ...currentData[this.index], notebooks: notebooks };
            if (this.type == 'own') {
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
                this.successDelete();
            } else if (this.type == 'shared') {
                this.dexieService.setSharedContainers(currentData);
                this.containerService.setSharedContainers(currentData);
                this.successDelete();
            } else {
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
                this.successDelete();
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Error while updating the note!');
          }
        });
    }
  
    moveToTrashUnclassifiedNotebook(){
      this.disabled = true;
      this.notebookService.moveToTrashNote(undefined, this.notebookId, this.noteID)
        .subscribe({
          next: async (result: any) => {
            let notebooks: any = this.type=='own' ? await this.dexieService.getOwnNotebooks() : await this.dexieService.getSharedNotebooks()
            let notebookIndex = notebooks.findIndex((notebook)=>notebook.id==this.notebookId);
            let noteIndex = notebooks[notebookIndex].notes.findIndex((note)=>note==this.noteID);
            notebooks[notebookIndex].notes.splice(noteIndex, 1);

            if (this.type == 'own') {
                this.dexieService.setOwnNotebooks(notebooks);
                this.containerService.setOwnNotebooks(notebooks);
                this.successDelete();
            } else if (this.type == 'shared') {
                this.dexieService.setSharedNotebooks(notebooks);
                this.containerService.setSharedNotebooks(notebooks);
                this.successDelete();
            } 
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Error while updating the note!');
          }
        });
    }
  
    moveToTrash() {
        if(!Number.isNaN(this.containerId)){
          this.moveToTrashClassifiedNote();
        } else {
          this.moveToTrashUnclassifiedNotebook();
        }
    }
  
    async successDelete() {
        this.disabled = false;
        await this.setData();
        this.dialog.closeAll();
        this.openSnackBar('Note deleted successfully!');
    }
  
}
