import { NotebooksDetailsComponent } from './dashboard/container-content/notebooks-details/notebooks-details.component';
import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgOptimizedImage } from '@angular/common';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxImageCompressService } from "ngx-image-compress";
import { LandingPageComponent } from './external-pages/landing-page/landing-page.component';
import { FooterComponent } from './external-pages/landing-page/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { VerifyComponent } from './auth/verify/verify.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { FaqComponent } from './external-pages/landing-page/faq/faq.component';
import { DndDirective } from './directives/dnd/dnd.directive';
import { SearchPipe } from './pipes/search.pipe';
import { SearchByNamePipe } from './pipes/search-by-name.pipe';
import { SearchContainerPipe } from './pipes/search-container.pipe';
import { PrivacyPolicyComponent } from './external-pages/privacy-policy/privacy-policy.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TermsConditionsComponent } from './external-pages/terms-conditions/terms-conditions.component';
import { PlatformsComponent } from './external-pages/landing-page/platforms/platforms.component';
import { ForgotPasswordRequestComponent } from './auth/forgot-password-request/forgot-password-request.component';
import { SuccessPageComponent } from './external-pages/success-page/success-page.component';
import { ResponsiveSidebarDirective } from './directives/responsive-sidebar/responsive-sidebar.directive';
import { ContainersComponent } from './dashboard/containers/containers.component';
import { SidebarContentComponent } from './dashboard/sidebar-content/sidebar-content.component';
import { ContainerContentComponent } from './dashboard/container-content/container-content.component';
import { ListContainersComponent } from './dashboard/containers/list-containers/list-containers.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavbarContentComponent } from './dashboard/navbar-content/navbar-content.component';
import { ImportSettingsComponent } from './dashboard/settings/account-settings/import-settings/import-settings.component';
import { FilterEmailPipe } from './pipes/filter-email.pipe';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { ContainerCardComponent } from './dashboard/containers/list-containers/container-card/container-card.component';
import { ProfileSettingsComponent } from './dashboard/settings/profile-settings/profile-settings.component';
import { ProfileSettingsContentComponent } from './dashboard/settings/profile-settings/profile-settings-content/profile-settings-content.component';
import { AccountSettingsComponent } from './dashboard/settings/account-settings/account-settings.component';
import { AuthSettingsComponent } from './dashboard/settings/account-settings/auth-settings/auth-settings.component';
import { TimerConfigurationComponent } from './dashboard/settings/account-settings/timer-configuration/timer-configuration.component';
import { PlanSettingsComponent } from './dashboard/settings/account-settings/plan-settings/plan-settings.component';
import { ContainerInfoComponent } from './dashboard/create-container/container-info/container-info.component';
import { ContainerSecurityComponent } from './dashboard/create-container/container-security/container-security.component';
import { AddPassphraseComponent } from './dashboard/create-container/add-passphrase/add-passphrase.component';
import { HardwareKeyComponent } from './dashboard/create-container/hardware-key/hardware-key.component';
import { AddMasterPasswordComponent } from './dashboard/create-container/add-master-password/add-master-password.component';
import { WelcomeComponent } from './dashboard/welcome/welcome.component';
import { PasswordChangeComponent } from './dashboard/settings/profile-settings/password-change/password-change.component';
import { ChangeMasterPasswordComponent } from './dashboard/settings/account-settings/change-master-password/change-master-password.component';
import { NotificationsComponent } from './dashboard/settings/notifications/notifications.component';
import { NotificationsPopUpComponent } from './dashboard/notifications-pop-up/notifications-pop-up.component';
import { NotificationsContentComponent } from './dashboard/settings/notifications/notifications-content/notifications-content.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CreateContainerComponent } from './dashboard/create-container/create-container.component';
import { StartMigrationSettingsContentComponent } from './dashboard/settings/account-settings/import-settings/migration-settings-content/migration-settings-content.component';
import { MarkdownModule } from 'ngx-markdown';
import { TrashComponent } from './dashboard/settings/account-settings/trash/trash.component';
import { TrashContentComponent } from './dashboard/settings/account-settings/trash/trash-content/trash-content.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { UsersTableComponent } from './admin-page/users-table/users-table.component';
import { LineChartComponent } from './admin-page/line-chart/line-chart.component';
import { TooltipComponent } from './dashboard/tooltip/tooltip.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TutorialsPageComponent } from './external-pages/tutorials-page/tutorials-page.component';
import { TutorialCardComponent } from './external-pages/tutorials-page/tutorial-card/tutorial-card.component';
import { AdminAllUsersComponent } from './admin-page/admin-all-users/admin-all-users.component';
import { UsersOrderedBySessionsComponent } from './admin-page/users-ordered-by-sessions/users-ordered-by-sessions.component';
import { NotFoundPageComponent } from './external-pages/not-found-page/not-found-page.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharingCenterComponent } from './dashboard/sharing-center/sharing-center.component';
import { BackupPersonComponent } from './dashboard/sharing-center/backup-person/backup-person.component';
import { NetworkComponent } from './dashboard/sharing-center/network/network.component';
import { TeamsComponent } from './dashboard/sharing-center/teams/teams.component';
import { PermissionsComponent } from './dashboard/sharing-center/permissions/permissions.component';
import { NetworkMemberComponent } from './dashboard/sharing-center/network-member/network-member.component';
import { SharingCenterContentComponent } from './dashboard/sharing-center/sharing-center-content/sharing-center-content.component';
import { HomeContentComponent } from './dashboard/home-content/home-content.component';
import { FirstPageComponent } from './external-pages/first-page/first-page.component';
import { PasswordDetailsComponent } from './dashboard/container-content/password-details/password-details.component';
import { NoteDetailsComponent } from './dashboard/container-content/notebooks-details/note-details/note-details.component';
import { EnterpriseSsoComponent } from './auth/enterprise-sso/enterprise-sso.component';
import { LogginHistoryComponent } from './dashboard/settings/profile-settings/loggin-history/loggin-history.component';
import { TeamsContentComponent } from './dashboard/settings/profile-settings/teams-content/teams-content.component';
import { DevelopersToolsComponent } from './dashboard/developers-tools/developers-tools.component';
import { ToolsDetailsComponent } from './dashboard/developers-tools/tools-details/tools-details.component';
import { OrganisationsComponent } from './dashboard/organisations/organisations.component';
import { OrganisationContentComponent } from './dashboard/organisation-content/organisation-content.component';
import { ActionLogsComponent } from './dashboard/organisation-content/action-logs/action-logs.component';
import { SsoContentComponent } from './dashboard/organisation-content/sso-content/sso-content.component';
import { ImportDataComponent } from './dashboard/import-data/import-data.component';
import { NavbarComponent } from './external-pages/landing-page/navbar/navbar.component';
import { HeroComponent } from './external-pages/landing-page/hero/hero.component';
import { QuestionsComponent } from './external-pages/landing-page/questions/questions.component';
import { SecureMethodsComponent } from './external-pages/landing-page/secure-methods/secure-methods.component';
import { WhyUseComponent } from './external-pages/landing-page/why-use/why-use.component';
import { WorkStepsComponent } from './external-pages/landing-page/work-steps/work-steps.component';
import { PlansComponent } from './external-pages/landing-page/plans/plans.component';
import { DownloadPage1Component } from './external-pages/download-page1/download-page1.component';
import { ContactUsPage1Component } from './external-pages/contact-us-page1/contact-us-page1.component';
import { CustomerSupportPage1Component } from './external-pages/customer-support-page1/customer-support-page1.component';
import { OrganizationSimpleComponent } from './dashboard/organization-simple/organization-simple.component';

@NgModule({
  declarations: [
    DndDirective,
    AppComponent,
    LandingPageComponent,
    HomeContentComponent,
    FirstPageComponent,
    FooterComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ContainerContentComponent,
    PasswordDetailsComponent,
    NoteDetailsComponent,
    ToolsDetailsComponent,
    ActionLogsComponent,
    SsoContentComponent,
    OrganisationsComponent,
    AddPassphraseComponent,
    HardwareKeyComponent,
    VerifyComponent,
    ForgotPasswordComponent,
    OrganisationContentComponent,
    FaqComponent,
    DndDirective,
    ProfileSettingsComponent,
    DevelopersToolsComponent,
    LogginHistoryComponent,
    TeamsContentComponent,
    SearchPipe,
    SearchByNamePipe,
    SearchContainerPipe,
    PrivacyPolicyComponent,
    NotFoundPageComponent,
    TermsConditionsComponent,
    PlatformsComponent,
    ForgotPasswordRequestComponent,
    SuccessPageComponent,
    ResponsiveSidebarDirective,
    SharingCenterComponent,
    ContainersComponent,
    SidebarContentComponent,
    ContainerContentComponent,
    ListContainersComponent,
    NavbarContentComponent,
    ImportSettingsComponent,
    NavbarContentComponent,
    FilterEmailPipe,
    ContainerCardComponent,
    AuthSettingsComponent,
    TimerConfigurationComponent,
    PlanSettingsComponent,
    BackupPersonComponent,
    ContainerInfoComponent,
    ContainerSecurityComponent,
    ListContainersComponent,
    WelcomeComponent,
    PasswordChangeComponent,
    ChangeMasterPasswordComponent,
    NotificationsComponent,
    NotificationsPopUpComponent,
    NotificationsContentComponent,
    CreateContainerComponent,
    StartMigrationSettingsContentComponent,
    NotebooksDetailsComponent,
    TrashComponent,
    TrashContentComponent,
    AdminPageComponent,
    UsersTableComponent,
    LineChartComponent,
    TooltipComponent,
    TutorialsPageComponent,
    FilterUsersPipe,
    TutorialsPageComponent,
    TutorialCardComponent,
    AdminAllUsersComponent,
    UsersOrderedBySessionsComponent,
    NetworkComponent,
    TeamsComponent,
    PermissionsComponent,
    NetworkMemberComponent,
    SharingCenterContentComponent,
    ProfileSettingsComponent,
    AccountSettingsComponent,
    ProfileSettingsContentComponent,
    AddPassphraseComponent,
    HardwareKeyComponent,
    AddMasterPasswordComponent,
    ImportDataComponent,
    EnterpriseSsoComponent,
    NavbarComponent,
    HeroComponent,
    QuestionsComponent,
    SecureMethodsComponent,
    WhyUseComponent,
    WorkStepsComponent,
    PlansComponent,
    DownloadPage1Component,
    ContactUsPage1Component,
    CustomerSupportPage1Component,
    OrganizationSimpleComponent
  ],
  imports: [
    MatIconModule,
    FormsModule,
    LayoutModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxFileDropModule,
    CarouselModule,
    MarkdownModule.forRoot(),
    QuillModule.forRoot(),
    NgApexchartsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgOptimizedImage,
  ],
  providers: [
    provideHttpClient(withInterceptors([
      (req, next) => {
        const token = localStorage.getItem('token') || '';
        const clonedReq = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        return next(clonedReq);
      }
    ])),
    NgxImageCompressService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
