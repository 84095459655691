
  <div class="content">
    <div class="img-container" fxFlex="50">
      <img
        src="assets/images/login.svg"
        alt="syfly logo"
        (click)="navigateToLandingPage()"
      />
    </div>
  
    <div class="sso-section-container"
    fxFlex="50"
    fxLayout="column"
    fxLayoutGap="50px"
    >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px" >
            <img
              width="100"
              alt="syfly-logo"
              src="assets/images/Enterprise.svg"
            />
            <div class="title-sso">enterprise single sign-on</div> 
          </div>
        
      
        <div class="sso-form-content" fxLayout="column"  fxLayoutGap="25px">
              
                    <div class="notif-text">Log in using syfly Enterprise SSO <br/> Please enter your organization identifier to process</div>
          
                        <div fxLayout="column" fxLayoutGap="10px">
                          <input
                          id="enterpriseID"
                          name="enterpriseID"
                          placeholder="Enterprise Identifier"
                          #enterpriseID="ngModel"
                          class="login-input"
                          [ngClass]="{ 'login-input-error': continueClicked && sso_data.enterpriseID.length === 0 }"
                          [(ngModel)]="sso_data.enterpriseID"
                          required
                        />
                        <!-- Error Message for Enterprise Identifier -->
                        <div *ngIf="continueClicked && sso_data.enterpriseID.length==0" class="password-errors" [class.visible]="continueClicked && sso_data.enterpriseID.length==0">
                          <div class="warn-icon-container">
                            <img
                              width="20"
                              height="20"
                              src="../../../assets/images/dashboard/warning.svg"
                              alt="error"
                            />
                          </div>
                          <p class="error-cont">Enterprise identifier is required</p>
                        </div>

                          <input
                            id="email"
                            name="email"
                            placeholder="email"
                            #email="ngModel"
                            class="login-input"
                            [ngClass]="{'login-input-error': continueClicked && sso_data.enterpriseID.length === 0 }"
                            [(ngModel)]="sso_data.email"
                            required
                          />
                          <!-- Error Message for Enterprise Identifier -->
                          <div class="password-errors" *ngIf="continueClicked && sso_data.email.length === 0" [class.visible]="continueClicked && sso_data.email.length === 0">
                            <div class="warn-icon-container">
                              <img
                                width="20"
                                height="20"
                                src="../../../assets/images/dashboard/warning.svg"
                                alt="error"
                              />
                            </div>
                            <p class="error-cont">email is required</p>
                          </div>
                          <div class="password-errors" *ngIf="error_msg.length != 0" [class.visible]="error_msg.length != 0">
                            <div class="warn-icon-container">
                              <img
                                width="20"
                                height="20"
                                src="../../../assets/images/dashboard/warning.svg"
                                alt="error"
                              />
                            </div>
                            <p class="error-cont">{{error_msg}}</p>
                          </div>
                        </div>
                     
                     
          
                    
                  <div 
                  fxLayout="row" 
                  fxLayoutGap="25px"
                  fxLayoutAlign="center center"
                  class="sso-btn-container"
                  >
                    <button
                     (click)="navigateToLoginPage()"
                      class=" sso-btn btn-cancel"
                      type="submit"
                      fxFlex="45"
                    >
                      Cancel
                    </button>
                    <button
                    (click)="continueClick()"
                      class="sso-btn btn-next"
                      type="submit"
                      fxFlex="45"
                    >
                    Continue
                    </button>
                  </div>

                  <div class="devider" fxFlex></div>
    <div class="minitext">Don't have an account 
    <span (click)="navigateToRegisterPage()" class="higlighted"> create account </span></div>
               
              </div>
    </div>
</div>

