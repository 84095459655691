<div fxLayout="column" fxLayoutGap="30px" class="content">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="title">Trash Bin</div>
    </div>

    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
            <div class="pop-ups-subtitle">Please wait we are fetching your trash</div>
        </div>
    </div>
    <div 
        *ngIf="!loading && (trash==null || (trash.containers.length==0 && trash.passwords.length==0 && trash.notebooks.length==0 && trash.notes.length==0))"
        class="flex flex-col position-el gap-3">
        <img alt="Empty trash"
            [src]="dark ? 'assets/images/dashboard/empty-trash-dark.svg' : 'assets/images/dashboard/empty-trash-light.svg'" />
    </div>


    <div fxLayout="column" fxLayoutGap="20px" *ngIf="!loading && (trash!=null && (trash.containers.length>0 || trash.passwords.length>0 || trash.notebooks.length>0 || trash.notes.length>0))">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="end end" fxLayoutGap="20px">
            <div class=" group">
                <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
                <input placeholder="Search" class="input" [(ngModel)]="searchString" autocomplete="off" />
            </div>

            <button (click)="openDialog()" class="empty-trash">
                <span>Empty trash</span> 
            </button>
        </div>

            <div class="table-container">
                <table aria-describedby="list of containers">
                    <thead>
                        <tr>
                            <th class="header-cell">Name</th>
                            <th class="header-cell">Type</th>
                            <th class="header-cell">Date added</th>
                            <th class="header-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of trash.containers | search :searchString; let i = index;">
                            <td class="table-column-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img alt="icon" class="main-icon" [src]="item.icon.data" />
                                <span>{{ item.name }}</span>
                            </td>

                            <td class="table-column-date">
                                <span>Container</span>
                            </td>

                            <td class="table-column-date">
                                <span>{{ item.createdAt | date }}</span>
                            </td>

                            <td class="action-btns">
                                <button (click)="restoreContainer(item)"> <img [src]="dark ? 'assets/images2/dashboard/restore-dark.svg' : 'assets/images2/dashboard/restore-light.svg'" alt="restart icon"> </button>
                                <button (click)="deleteContainer(item)"> <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg" alt="trash icon"></button>
                            </td>
                        </tr>
                        <tr *ngFor="let item of trash.passwords | search :searchString; let i = index;">
                            <td class="table-column-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img alt="icon" class="main-icon" [src]="item.icon.data" />
                                <span>{{ item.name }}</span>
                            </td>
                            <td class="table-column-date">
                                <span>Password</span>
                            </td>
                            <td class="table-column-date">
                                <span>{{ item.createdAt | date }}</span>
                            </td>
                            <td class="action-btns">
                                <button (click)="restorePassword(item)"> <img [src]="dark ? 'assets/images2/dashboard/restore-dark.svg' : 'assets/images2/dashboard/restore-light.svg'" alt="restart icon"> </button>
                                <button (click)="deletePassword(item)"> <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg" alt="trash icon"></button>
                            </td>
                        </tr>
                        <tr *ngFor="let item of trash.notebooks | search :searchString; let i = index;">
                            <td class="table-column-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img alt="icon" class="main-icon" [src]="item.icon.data" />
                                <span>{{ item.name }}</span>
                            </td>
                            <td class="table-column-date">
                                <span>Notebook</span>
                            </td>

                            <td class="table-column-date">
                                <span>{{ item.createdAt | date }}</span>
                            </td>
                            <td class="action-btns">
                                <button (click)="restoreNotebook(item)"> <img [src]="dark ? 'assets/images2/dashboard/restore-dark.svg' : 'assets/images2/dashboard/restore-light.svg'" alt="restart icon"> </button>
                                <button (click)="deleteNotebook(item)"> <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg" alt="trash icon"></button>
                            </td>
                        </tr>
                        <tr *ngFor="let item of trash.notes | search :searchString; let i = index;">
                            <td class="table-column-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img alt="icon" [src]="dark ? 'assets/images/dashboard/container-content/notebook-icon-dark.svg' : 'assets/images/dashboard/container-content/notebooks-icon.svg'" />
                                <span>{{ item.title }}</span>
                            </td>
                            <td class="table-column-date">
                                <span>Note</span>
                            </td>

                            <td class="table-column-date">
                                <span>{{ item.createdAt | date }}</span>
                            </td>
                            <td class="action-btns">
                                <button (click)="restoreNote(item)"> <img [src]="dark ? 'assets/images2/dashboard/restore-dark.svg' : 'assets/images2/dashboard/restore-light.svg'" alt="restart icon"> </button>
                                <button (click)="deleteNote(item)"> <img src="assets/images2/dashboard/organisation-content/trash-red-icon.svg" alt="trash icon"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
</div>

<ng-template #deleteDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabledButton" class="flex justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" (click)="cancelDelete()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">Delete Definitely</span>
                <span class="delete-dialog-description">Are you sure to delete definitely the content of your recycle
                    bin? Deleted items cannot be restored!</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
                    [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn full-width" (click)="cancelDelete()"
                        [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="emptyTrash()"
                        [disabled]="disabledButton">Delete</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>