<mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="selectedSidebar" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div class="note-details-wrapper" fxLayout="column" fxLayoutGap="10px">
            <button class="back-button-wrapper" (click)="goBack()">
                <img src="assets/images/dashboard/arrow-left.svg" alt="return" class="" />
                <span>Back</span>
            </button>

            <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="mt-36">
                    <mat-spinner diameter="50" color="accent"></mat-spinner>
            </div>

            <div *ngIf="!loading">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="full-width">
                    <div class="title-box" *ngIf="edit || noteID==0" >
                        <img class="edit-icon" [src]="dark ? 'assets/images/dashboard/rename-dark.svg' : 'assets/images/dashboard/rename.svg'" alt="rename"/>
                        <input class="full-width note-title" placeholder="Enter your note name" [(ngModel)]="noteName" (ngModelChange)="nameChanged($event)" />
                    </div>
                    <div fxLayoutGap="5px" *ngIf="!edit && noteID!=0">
                        <div class="full-width note-title"> {{selectedNoteData?.title}}</div>
                    </div> 
                    <div *ngIf="noteID==0">
                        <button class="new-container-button" (click)="saveNote()" [disabled]="disabled">Save</button>
                    </div>
                    <div *ngIf="noteID!=0 && selectedNoteData?.owner==user.id" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" [ngClass]="{'opaque': disabled, '': !disabled}">
                        <button *ngIf="!edit"  class="delete-button" (click)="deleteItem()" [disabled]="disabled">Move To Trash</button>
                        <button *ngIf="edit" class="cancel-button" (click)="toggleEdit()" [disabled]="disabled">Cancel</button>
                        <button *ngIf="edit" class="new-container-button" (click)="saveNote()" [disabled]="disabled">Save</button>
                        <button *ngIf="!edit" class="new-container-button" (click)="toggleEdit()" [disabled]="disabled">Update</button>
                    </div>
                </div>

                <div class="containers-devider"></div>

                <div *ngIf="edit || noteID==0">

                    <div fxLayout="row" fxLayoutAlign="end center">
                        <div (click)="toggleEditor()">
                            <img *ngIf="!dark" class="cursor-pointer w-[90px]"
                                [src]="isMarkdown ? 'assets/images/dashboard/markdown-active-light.svg': 'assets/images/dashboard/wysiwyg-active-light.svg'"
                                alt="toggle-editor">
                            <img *ngIf="dark" class="cursor-pointer w-[90px]"
                                [src]="isMarkdown ? 'assets/images/dashboard/markdown-active-dark.svg': 'assets/images/dashboard/wysiwyg-active-dark.svg'"
                                alt="toggle-editor">
                        </div>
                    </div>
                    <div *ngIf="isMarkdown" class="markdown-toolbar">
                        <mat-button-toggle-group [ngModel]="markdownToolbar">
                            <mat-button-toggle value="bold"
                                (click)="switchMarkdownToolbarClick('bold')"><mat-icon>format_bold</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="italic"
                                (click)="switchMarkdownToolbarClick('italic')"><mat-icon>format_italic</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="orderedList"
                                (click)="switchMarkdownToolbarClick('orderedList')"><mat-icon>format_list_numbered</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="unorderedList"
                                (click)="switchMarkdownToolbarClick('unorderedList')"><mat-icon>format_list_bulleted</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="checkbox"
                                (click)="switchMarkdownToolbarClick('checkbox')"><mat-icon>check_box</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="code"
                                (click)="switchMarkdownToolbarClick('code')"><mat-icon>code</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="quote"
                                (click)="switchMarkdownToolbarClick('quote')"><mat-icon>format_quote</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="h1" (click)="switchMarkdownToolbarClick('h1')"><span
                                    class="style-header">H1</span></mat-button-toggle>
                            <mat-button-toggle value="h2" (click)="switchMarkdownToolbarClick('h2')"><span
                                    class="style-header">H2</span></mat-button-toggle>
                            <mat-button-toggle value="h3" (click)="switchMarkdownToolbarClick('h3')"><span
                                    class="style-header">H3</span></mat-button-toggle>
                            <mat-button-toggle value="h4" (click)="switchMarkdownToolbarClick('h4')"><span
                                    class="style-header">H4</span></mat-button-toggle>
                            <mat-button-toggle value="h5" (click)="switchMarkdownToolbarClick('h5')"><span
                                    class="style-header">H5</span></mat-button-toggle>
                            <mat-button-toggle value="h6" (click)="switchMarkdownToolbarClick('h6')"><span
                                    class="style-header">H6</span></mat-button-toggle>
                            <mat-button-toggle value="hr" (click)="switchMarkdownToolbarClick('hr')">
                                <hr class="style-header" />
                            </mat-button-toggle>
                            <mat-button-toggle value="link"
                                (click)="switchMarkdownToolbarClick('link')"><mat-icon>insert_link</mat-icon></mat-button-toggle>
                            <mat-button-toggle value="image"
                                (click)="switchMarkdownToolbarClick('image')"><mat-icon>insert_photo</mat-icon></mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div *ngIf="isMarkdown" fxLayout="row" fxLayout.xs="column" class="markdown-container">
                        <textarea rows="50" #markdownTextarea class="textarea-binding" fxFlex.xs="100" fxFlex="50"
                            [(ngModel)]="markdownData" placeholder="Insert text here ..."
                            (ngModelChange)="onMarkdownChange($event)"
                            (keyup.enter)="insertAdditionalNewLine($event)"></textarea>
                        <markdown class="markdown-binding text-wrap" fxFlex.xs="100" fxFlex="50" [data]="markdownData"></markdown>
                    </div>
                    <div *ngIf="!isMarkdown" class="full-width notebook-wysiwyg-container">
                        <quill-editor class="full-width" [modules]="modules" [(ngModel)]="wysiwygData.data"
                            (onContentChanged)="onWysiwygChange($event)"></quill-editor>
                    </div>
                </div>

                <div *ngIf="!edit && noteID!=0">
                    <markdown class="text-wrap" [data]="this.selectedNoteData?.markdownData"></markdown>
                </div>
            </div>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- delete item -->
<ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabled" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabled" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/delete-icon.svg" />

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDeleteItem()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <span class="delete-dialog-title">Move Item to Trash</span>
                <span class="delete-dialog-description">Are you sure to move this item to trash</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
                [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabled">Cancel</button>
                <button class="save-btn delete full-width" (click)="moveToTrash()" [disabled]="disabled">Move to Trash</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>