import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaService } from 'src/app/services/media/media.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ActivatedRoute } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { MatDialog } from '@angular/material/dialog';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';


@Component({
  selector: 'app-password-details',
  templateUrl: './password-details.component.html',
  styleUrl: './password-details.component.scss'
})
export class PasswordDetailsComponent {
  @ViewChild('start') start: any;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;

  containerIndex: any;
  type: any;
  containerId: any;
  passwordId: any;
  passwordIndex: any;
  preview: any;
  fileName = '';
  icon = 1;
  name = '';
  userName = '';
  password = '';
  url = '';
  notesData = '';
  notes = { data: '', deltaJson: [] };
  files = [];
  filesToShow = [];
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', 'large'] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link']
    ]
  };
  user: any = { firstName: '', lastName: '', profilePicture: '', email: '', id: 0 };
  comments = [];
  comment = '';
  editC = [];
  newComment = '';
  userPlan: any;
  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };
  disabled: boolean = false;
  disableEdit: boolean = true;
  show: boolean = false;
  selectedSidebar = 'containers';

  get dark() {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get ownPasswords () {
    return this.containerService.ownPasswords;
  }

  get sharedPasswords () {
    return this.containerService.sharedPasswords;
  }

  get selectedPasswordData () {
    return this.containerService.selectedPasswordData;
  }

  constructor(public dialog: MatDialog, private location: Location, private _snackBar: MatSnackBar, private media: MediaService, private sanitizer: DomSanitizer, private containerPassword: ContainerPasswordService, private theme: ThemeService, private localstorage: LocalStorageService, private route: ActivatedRoute, private containerService: ContainerService, private dexieService: DexieService, private encryptDecrypt: EncryptDecryptService,) {
    this.userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };
    this.user = { firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email: JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };
    this.type = this.route.snapshot.paramMap.get('type');
    this.containerId = Number(this.route.snapshot.paramMap.get('containerId'));
    this.passwordId = Number(this.route.snapshot.paramMap.get('passwordId'));
    this.setData();
  }

  ngOnInit() {
    this.setData()
  }

  sanitizeIcon(base64String: string, mediaType: string) {
    return mediaType === 'application/octet-stream'
      ? this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${base64String}`)['changingThisBreaksApplicationSecurity']
      : this.sanitizer.bypassSecurityTrustUrl(`data:${mediaType};base64,${base64String}`)['changingThisBreaksApplicationSecurity'];
  }

  async setPassword(password: any, binary: any) {
      // Decrypt password and data
      const passwordDecryption = await this.encryptDecrypt.decryptData(password?.password, binary);
      const dataDecryption = await this.encryptDecrypt.decryptData(password?.passData, binary);
      const dataDecrypted = JSON.parse(dataDecryption);

      // const res: any = await firstValueFrom(this.media.getIcon(p.icon));
      const icon = password?.iconData;
      let iconData = icon.data;
      let size = JSON.parse(password.size);

      if (!iconData.includes('https://www.google.com/s2/favicons?sz=64') && !iconData.includes('assets/images/predefined-icons') && !icon.data.includes(';base64,')) {
          const passData = new Uint8Array(JSON.parse(iconData).data);
          const passBase64String = btoa(passData.reduce((data, byte) => data + String.fromCharCode(byte), ''));
          iconData = this.sanitizeIcon(passBase64String, icon.type);
          
      }
      // Prepare the result object
      return {
            ...password,
            password: passwordDecryption,
            ...dataDecrypted,
            icon: { id: icon.id, data: iconData },
            size: { ...size, memory: Number(size.memory) },
            owner: password?.owner,
      };
  }

  async setData() {
    if(!Number.isNaN(this.containerId)){
      this.selectedSidebar = 'containers';
      if (this.type == 'own') {
        this.containerIndex = this.ownContainers.findIndex(container=>container.id==this.containerId);
        let decryptedPassword = await this.setPassword(this.ownContainers[this.containerIndex]?.passwords?.find((pass) => pass.id === this.passwordId), this.ownContainers[this.containerIndex]?.decryptedOwnerKey);
        this.containerService.setSelectedPasswordData(decryptedPassword);
      } else if (this.type == 'shared') {
        this.containerIndex = this.sharedContainers.findIndex(container=>container.id==this.containerId);
        let decryptedPassword = await this.setPassword(this.sharedContainers[this.containerIndex]?.passwords?.find((pass) => pass.id === this.passwordId), this.sharedContainers[this.containerIndex]?.decryptedRecipientKey);
        this.containerService.setSelectedPasswordData(decryptedPassword);
      } else {
        this.containerIndex = this.deadManSwitchContainers.findIndex(container=>container.id==this.containerId);
        let decryptedPassword = await this.setPassword(this.deadManSwitchContainers[this.containerIndex]?.passwords?.find((pass) => pass.id === this.passwordId), this.ownContainers[this.containerIndex]?.decryptedBackUpPersonKey);
        this.containerService.setSelectedPasswordData(decryptedPassword);
      }
    }else{
      this.selectedSidebar = 'home';
      if (this.type == 'own') {
        let decryptedPassword = await this.setPassword(this.ownPasswords?.find((pass) => pass.id === this.passwordId), this.ownPasswords?.find((pass) => pass.id === this.passwordId)?.decryptedKey);
        this.containerService.setSelectedPasswordData(decryptedPassword);
      }else if (this.type == 'shared'){
        let decryptedPassword = await this.setPassword(this.sharedPasswords?.find((pass) => pass.id === this.passwordId), this.sharedPasswords?.find((pass) => pass.id === this.passwordId)?.decryptedKey);
        this.containerService.setSelectedPasswordData(decryptedPassword);
      }
    }

  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  toggleShow() {
    this.show = !this.show;
  }

  editItem() {
    this.disableEdit = !this.disableEdit;
    this.name = this.selectedPasswordData?.name;
    this.userName = this.selectedPasswordData?.userName;
    this.url = this.selectedPasswordData?.url;
    this.password = this.selectedPasswordData?.password
    this.preview = this.selectedPasswordData?.icon?.data;
    this.comments = this.selectedPasswordData?.comments ?? [];
    this.notes = this.selectedPasswordData?.notes ?? { data: '', deltaJson: [] };
    this.notesData = this.selectedPasswordData?.notes.data ?? '';
    this.files = this.selectedPasswordData?.files ?? [];
    this.icon = this.selectedPasswordData?.icon?.id;
    this.filesToShow = this.files?.map((file) => { return { name: file.name, extension: file.extension, icon: file.icon?.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(file.data).data)], { type: file.type }))) } });
  }

  deleteItem() {
    this.dialog.open(this.deleteItemDialog, { width: '400px' });
  }

  cancelDeleteItem() {
    this.dialog.closeAll();
    this.preview = null
  }

  moveToTrashClassifiedPassword(){
    this.disabled = true;
    this.containerPassword.moveToTrashPassword(this.passwordId, this.containerId)
      .subscribe({
        next: async (res: any) => {
            let currentData: any = 
                this.type=='own' ? await this.dexieService.getOwnContainers() 
                : (this.type=='shared' ? await this.dexieService.getSharedContainers() 
                : this.dexieService.getDeadManSwitchContainers());
            let containerIndex = currentData.findIndex(item => item.id==this.containerId);
            let passIndex = currentData[containerIndex].passwords.findIndex(pass=> pass.id==this.passwordId);
            currentData[containerIndex].passwords.splice(passIndex, 1);
      
            if(this.type=='own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
                this.successDelete();
            } else if(this.type=='shared'){
                this.dexieService.setSharedContainers(currentData);
                this.containerService.setSharedContainers(currentData);
                this.successDelete();
            } else if(this.type=='backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
                this.successDelete();
            }
        },
        error: (error: HttpErrorResponse) => {
          this.dialog.closeAll();
          this.disabled = false;
          this.openSnackBar('Cannot delete password!');
        }
      });
  }

  moveToTrashUnclassifiedPassword(){
    this.disabled = true;
    this.containerPassword.moveToTrashPassword(this.passwordId, this.containerId)
      .subscribe({
        next: async (res: any) => {
          let passwords = this.type=='own' ? await this.dexieService.getOwnPasswords() : await this.dexieService.getSharedPasswords();
          let passIndex = passwords.findIndex((pass: any)=> pass.id==this.passwordId);
          passwords.splice(passIndex, 1)
          if(this.type=='own'){
              this.dexieService.setOwnPasswords(passwords);
              this.containerService.setOwnPasswords(passwords);
              this.successDelete();
          }else if(this.type=='shared'){
              this.dexieService.setSharedPasswords(passwords);
              this.containerService.setSharedPasswords(passwords);
              this.successDelete();      
          }
        },
        error: (error: HttpErrorResponse) => {
          this.dialog.closeAll();
          this.disabled = false;
          this.openSnackBar('Cannot delete password!');
        }
      });
  }

  moveToTrash() {
      if(!Number.isNaN(this.containerId)){
        this.moveToTrashClassifiedPassword();
      } else {
        this.moveToTrashUnclassifiedPassword();
      }
  }

  goBack(){
    this.location.back();
  }

  save() {
    if (this.name.length == 0) this.openSnackBar('Name is required!');
    else if (this.userName.length == 0) this.openSnackBar('Username is required!');
    else if (this.password.length == 0) this.openSnackBar('Password is required!');
    else {
        this.savePasswordAfterEdit();
    }
  }

  async savePasswordAfterEdit() {
    await this.setData();
    this.disabled = true;
    let data = { comments: this.comments, files: this.files, notes: this.notes };
    this.media.getIcon(this.icon)
      .subscribe({
        next: async (res: any) => {
          let iconData: any;
          if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
            iconData = res.icon;
          } else {
            let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
            let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let passBase64String = btoa(string_pass_char);
            iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
          }
          let containerID = !Number.isNaN(this.containerId) ? (this.type == 'shared' ? this.sharedContainers[this.containerIndex].id : ( this.type == 'own' ? this.ownContainers[this.containerIndex].id : this.deadManSwitchContainers[this.containerIndex].id)) : undefined;
          let passEncryption = '';
          let dataEncryption = '';

          if (!Number.isNaN(this.containerId)){
            if (this.type == 'shared') {
              passEncryption = await this.encryptDecrypt.encryptData(this.password, this.sharedContainers[this.containerIndex].decryptedRecipientKey);
              dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.sharedContainers[this.containerIndex].decryptedRecipientKey)
            } else if (this.type == 'own') {
              passEncryption = await this.encryptDecrypt.encryptData(this.password, this.ownContainers[this.containerIndex].decryptedOwnerKey);
              dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.ownContainers[this.containerIndex].decryptedOwnerKey)
            } else {
              passEncryption = await this.encryptDecrypt.encryptData(this.password, this.deadManSwitchContainers[this.containerIndex].decryptedBackUpPersonKey);
              dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.deadManSwitchContainers[this.containerIndex].decryptedBackUpPersonKey)
            }
          }else{
              passEncryption = await this.encryptDecrypt.encryptData(this.password,  this.selectedPasswordData.decryptedKey);
              dataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data),  this.selectedPasswordData.decryptedKey);
          }
          let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption };
          const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data, false);

          if (restStorage > 0) {
            this.containerPassword.updatePassword(dataToSave, size, this.selectedPasswordData.id, containerID)
              .subscribe({
                next: async (result: any) => {
                  if(containerID ==undefined){
                        let passwords: any = this.type=='own' ? await this.dexieService.getOwnPasswords() : await this.dexieService.getSharedPasswords();
                        let activeItem = passwords.findIndex((ps: any) => ps.id === this.passwordId);
                        passwords[activeItem] = { ...passwords[activeItem], url: this.url, name: this.name, userName: this.userName, icon: iconData.data, iconData: iconData, size: JSON.stringify(size) };
                        let decryptedPassword = await this.setPassword(passwords[activeItem], passwords[activeItem]?.decryptedKey);
                        this.containerService.setSelectedPasswordData(decryptedPassword);
                    
                        if (this.type == 'own') {
                          this.dexieService.setOwnPasswords(passwords);
                          this.containerService.setOwnPasswords(passwords);
                          this.successEdit();

                      } else if (this.type == 'shared') {
                          this.dexieService.setSharedPasswords(passwords);
                          this.containerService.setSharedPasswords(passwords);
                          this.successEdit();
                      }
                  }else{
                      let currentData: any = this.type=='own' ? await this.dexieService.getOwnContainers() : (this.type=='shared' ? await this.dexieService.getSharedContainers() : await this.dexieService.getDeadManSwitchContainers());
                      let pass = currentData[this.containerIndex].passwords;
                      let activeItem = pass.findIndex((ps) => ps.id === this.passwordId);
                      pass[activeItem] = { ...pass[activeItem], url: this.url, name: this.name, userName: this.userName, icon: iconData.data, iconData: iconData, size: JSON.stringify(size) };
                      let newMemory = JSON.parse(result.newMemory)
                      currentData[this.containerIndex] = { ... currentData[this.containerIndex], usedMemory: { ...newMemory, memory: Number(newMemory.memory), passwords: pass } };
                      let decryptedPassword = await this.setPassword(pass[activeItem], currentData[this.containerIndex]?.decryptedOwnerKey);
                      this.containerService.setSelectedPasswordData(decryptedPassword);
                      if (this.type == 'own') {
                          this.dexieService.setOwnContainers(currentData);
                          this.containerService.setOwnContainers(currentData);
                          this.successEdit();
                      } else if (this.type == 'shared') {
                          this.dexieService.setSharedContainers(currentData);
                          this.containerService.setSharedContainers(currentData);
                          this.successEdit();
                      } else {
                          this.dexieService.setDeadManSwitchContainers(currentData);
                          this.containerService.setDeadManSwitchContainers(currentData);
                          this.successEdit();
                      }
                  }
                },
                error: (error: HttpErrorResponse) => {
                  this.disabled = false;
                  this.openSnackBar('Error while updating the password!');
                }
              });

          } else {
            this.disabled = false;
            this.openSnackBar('Password cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
          }
          this.disabled = false;
        },
        error: (error: HttpErrorResponse) => {
          this.disabled = false;
        }
      });
  }

  calculateMemory(dataToSave: any, icon: any, data: any, isNotebook: boolean = false) {
    let memory;

    if (isNotebook) {
      memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon }));
    } else {
      // General memory calculation
      memory = Buffer.byteLength(JSON.stringify({ ...dataToSave, icon: icon, files: [] }));
      data.files.forEach((file: any) => {
        memory += file.size;
      });
    }

    let size = {};

    if (memory < 999) {
      size = { memory, unit: 'B' };

    } else if ((memory >= 1000) && (999999 > memory)) {
      size = { memory: (memory / 1000), unit: 'KB' };

    } else if ((memory >= 1000000) && (999999999 > memory)) {
      size = { memory: (memory / 1000000), unit: 'MB' };

    } else if ((memory >= 1000000000) && (999999999999 > memory)) {
      size = { memory: (memory / 1000000000), unit: 'GB' };

    } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
      size = { memory: (memory / 1000000000000), unit: 'TB' };

    }

    const totalMemory = this.userPlan?.memory?.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory?.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory?.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory?.memory * this.scale[container.usedMemory.unit])
    });
    return { size, restStorage }
  }

  onContentChanged($event: any) {
    this.notes.deltaJson = $event.content['ops'];
    this.notes.data = this.notesData;
  }

  extractFavicon() {
    // update this to not
    if (!this.disableEdit) {
      if (this.url === '') {
        this.openSnackBar('You need to enter an url first!');
      } else {
        this.containerPassword.extractFavicon(this.url)
          .subscribe({
            next: (res: any) => {
              if (res.faviconUrl.includes('https://www.google.com/s2/favicons?sz=64&domain_url=') || res.faviconUrl.includes('assets/images/predefined-icons')) {
                this.preview = res.faviconUrl;
              } else {
                let icon_data = new Uint8Array(JSON.parse(res.faviconUrl).data);
                let string_icon_char = icon_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
                let iconBase64String = btoa(string_icon_char);
                this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
              }
              this.icon = res.id;
              this.openSnackBar(res.message);
            },
            error: (error: HttpErrorResponse) => {
              this.openSnackBar('Favicon cannot be extracted!');
            }
          });
      }
    }
  }

  onIconChange(event: any) {
    const file: File = event.target.files[0];
    const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml"];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if (!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if (!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
        .subscribe({
          next: (res: any) => {
            this.icon = res.id;
            let my_data = new Uint8Array(JSON.parse(res.data).data);
            let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
            let base64String = btoa(string_char);
            this.preview = this.sanitizer.bypassSecurityTrustUrl('data:' + res.type + ';base64,' + base64String);
            this.openSnackBar('File uploaded successfully!');
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Cannot save icon!');
          }
        });
    }
  }

  toggleSidebar() {
    this.start.toggle();
  }

  removeFile(index: any) {
    this.files.splice(index, 1);
    this.filesToShow.splice(index, 1);
  }

  onFileChange(e: any) {
    const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.json', '.env', '.mp3', '.dmg', '.webp', '.webm'];
    let file = e.target.files[0];
    if (file) {
      if (file.size > 8000000) {
        this.openSnackBar('File should be at most 8 MB!');

      } else {
        let fileName = file.name;
        let dotIndex = fileName.indexOf('.');
        let name = fileName.slice(0, dotIndex);
        let extension = fileName.slice(dotIndex);
        if (extensions.includes(extension)) {
          this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
        } else {
          this.media.getIconByName(extension)
            .subscribe({
              next: (res: any) => {
                let icon = res.icon;
                const formData = new FormData();
                formData.append("file", file);
                this.containerPassword.getFileData(formData)
                  .subscribe({
                    next: (res: any) => {
                      this.files.push({ name, icon: { id: icon.id, data: icon.data }, size: file.size, data: res.data, extension, type: file.type });
                      this.filesToShow.push({ name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                    },
                    error: (error: HttpErrorResponse) => {
                    }
                  });
              },
              error: (error: HttpErrorResponse) => {
              }
            });
        }
      }
    }
  }

  addComment() {
    this.name = this.selectedPasswordData?.name;
    this.userName = this.selectedPasswordData?.userName;
    this.url = this.selectedPasswordData?.url;
    this.password = this.selectedPasswordData?.password
    this.comments = this.selectedPasswordData?.comments ?? [];
    this.notes = this.selectedPasswordData?.notes ?? { data: '', deltaJson: [] };
    this.files = this.selectedPasswordData?.files ?? [];
    this.icon = this.selectedPasswordData?.icon?.id;
    this.comments.push({ owner: this.user, comment: this.comment, createdAt: new Date() });
    this.editC.push(false);
    this.comment = '';
    if (this.disableEdit) {
        this.savePasswordAfterEdit();
    }
  }

  editComment(index: any) {
    this.editC[index] = true;
    this.newComment = this.comments[index].comment;
  }

  saveEditComment(index: any) {
    this.name = this.selectedPasswordData?.name;
    this.userName = this.selectedPasswordData?.userName;
    this.url = this.selectedPasswordData?.url;
    this.password = this.selectedPasswordData?.password
    this.comments = this.selectedPasswordData?.comments ?? [];
    this.notes = this.selectedPasswordData?.notes ?? { data: '', deltaJson: [] };
    this.files = this.selectedPasswordData?.files ?? [];
    this.icon = this.selectedPasswordData?.icon?.id;
    this.comments[index].comment = this.newComment;
    this.editC[index] = false;
    if (this.disableEdit) {
        this.savePasswordAfterEdit();
    }
  }

  cancelEditComment() {
    this.editC = this.editC.map((e) => false);
  }

  deleteComment(index: any) {
    this.name = this.selectedPasswordData?.name;
    this.userName = this.selectedPasswordData?.userName;
    this.url = this.selectedPasswordData?.url;
    this.password = this.selectedPasswordData?.password
    this.comments = this.selectedPasswordData?.comments ?? [];
    this.notes = this.selectedPasswordData?.notes ?? { data: '', deltaJson: [] };
    this.files = this.selectedPasswordData?.files ?? [];
    this.icon = this.selectedPasswordData?.icon?.id;
    this.comments.splice(index, 1);
    this.editC.splice(index, 1);
    if (this.disableEdit) {
        this.savePasswordAfterEdit();
    }
  }

  timeDifference(date: any) {
    let time_difference = new Date().getTime() - new Date(date).getTime();
    if (time_difference < 1000) {
      return `${time_difference} milliseconds ago`;
    } else if ((time_difference / 1000) < 60) {
      return `${Math.floor(time_difference / 1000)} seconds ago`;
    } else if ((time_difference / 60000) < 60) {
      return `${Math.floor(time_difference / 60000)} minutes ago`;
    } else if ((time_difference / 3600000) < 24) {
      return `${Math.floor(time_difference / 3600000)} hours ago`;
    } else if ((time_difference / 86400000) < 7) {
      return `${Math.floor(time_difference / 86400000)} days ago`;
    } else if ((time_difference / 604800000) < 4) {
      return `${Math.floor(time_difference / 604800000)} weeks ago`;
    } else {
      return new Date(date).toISOString().split('T')[0];
    }
  }

  successEdit() {
    this.dialog.closeAll();
    this.comment = '';
    this.newComment = '';
    this.disabled = false;
    this.disableEdit = true;
    this.openSnackBar('Password updated successfully!');
  }

  successDelete() {
    this.comment = '';
    this.newComment = '';
    this.dialog.closeAll();
    this.goBack();
    this.disabled = false;
    this.openSnackBar('Password deleted successfully!');
  }
}
