import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {OrganizationService} from "src/app/services/organization/organization.service"
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-logs',
  templateUrl: './action-logs.component.html',
  styleUrl: './action-logs.component.scss',
  providers: [DatePipe], 
})
export class ActionLogsComponent {
  subscription: Subscription;
  startDate: Date = new Date('2024-12-01');
  endDate: Date = new Date('2025-12-15');

  get selectedOrganization(): any {
    return this.organizationService.selectedOrganization;
  }

  get selectedOrgActionLogs(): any {
    return this.organizationService.selectedOrgActionLogs;
  }

 constructor(private organizationService: OrganizationService,private _snackBar: MatSnackBar, private datePipe: DatePipe) {
  this.subscription = this.organizationService.selectedOrganization$.subscribe(
    (organization) => {
      if (organization) {
        this.getOrgActionLogs()
      }
    });
}


  get filteredLogs() {
    if (!this.startDate || !this.endDate) {
      return this.selectedOrgActionLogs;
    }

    return this.selectedOrgActionLogs.filter(log => {
      const logDate = new Date(log.createdAt);
      return logDate >= this.startDate && logDate <= this.endDate;
    });
  }

  formatDate(date){
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  // Export logs to CSV
  exportToCSV() {
    const headers = ['Timestamp', 'Browser', 'Member'];
    const rows = this.selectedOrgActionLogs.map(log => [this.formatDate(log.createdAt), log.action, log.ownerData.firstName+" "+log.ownerData.lastName]);

    let csvContent = 'data:text/csv;charset=utf-8,' + headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'action_log.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }


getOrgActionLogs(){
this.organizationService.getOrganizationActionLogs(this.selectedOrganization.id)  
      .subscribe({
                next: (res: any) => {
                this.organizationService.setSelectedOrgActionLogs(res.data)
                this.openSnackBar("Action Logs has been reloaded")  
              },
                error: (error: HttpErrorResponse) => {
                  console.log(error)
                }
              });
}



}
