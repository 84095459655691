import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { NetworksService } from 'src/app/services/networks/networks.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrl: './teams.component.scss'
})

export class TeamsComponent {

  teamSearch = '';
  userEmail = '';
  groupName = '';
  selectedGroup = { id: 0, coordinatorID: 0, name: '', members: [] };
  selectedGroupID = 0;
  groups = [];
  users = [];
  loading = true;
  disabled = false;

  @ViewChild('addGroupDialog') addGroupDialog: TemplateRef<any>;
  @ViewChild('addMemberDialog') addMemberDialog: TemplateRef<any>;
  @ViewChild('deleteGroupDialog') deleteGroupDialog: any;

  get dark(): any {
    return this.theme.dark;
  }

  constructor(private groupsService: GroupsService, private localstorageService: LocalStorageService, private theme: ThemeService, public dialog: MatDialog, private _snackBar: MatSnackBar, private networkService: NetworksService) {
    let userID = JSON.parse(this.localstorageService.getUser())['id'];
    this.groupsService.getGroups(userID).subscribe({
      next: (res: any) => {
        this.groups = res;
        this.selectedGroup = res[0];
        this.selectedGroupID = res[0]?.id;
        this.networkService.getNetwork(userID).subscribe({
            next: (res: any)=>{
                this.users = res?.individuals || [] ;
                this.loading = false;
            }, error: (err: any)=>{
            }
        });
      }, error: (err: any) => {
      }
    });
  }

  // toggleChecked(index: number, methode: string): void {
  //   this.members[index].accessMethod = methode
  // }

  openAddGroupDialog() {
    this.dialog.open(this.addGroupDialog, { width: '400px' });
  }

  openAddMemberDialog() {
    this.dialog.open(this.addMemberDialog, { width: '400px' });
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  cancel() {
    this.groupName = '';
    this.userEmail = '';
    this.dialog.closeAll();
  }

  createTeam() {
    if (!this.disabled) {
      if (this.groupName.length == 0) {
        this.openSnackBar('Please give a name for your Team!');
      } else {
        this.disabled = true;
        let data = { coordinatorID: JSON.parse(this.localstorageService.getUser())['id'], name: this.groupName, members: [] };
        this.groupsService.createGroup(data).subscribe({
          next: (res: any) => {
            this.groups.push({ ...data, id: res.id });
            this.cancel();
            this.disabled = false
            this.selectedGroupID = res.id;
            this.selectedGroup = { ...data, id: res.id };
            this.openSnackBar('Team created successfully!');
          }, error: (err: any) => {
            this.openSnackBar('Some error occured, please try again later!');
          }
        })
      }
    }
  }

  onChangeSelectedGroup(event: any) {
    this.selectedGroup = this.groups.filter((gr) => gr.id == this.selectedGroupID)[0];
  }

  addMember() {
    this.disabled = true;
    this.groupsService.addMemberToGroup(this.selectedGroupID, this.userEmail).subscribe({
      next: (res: any) => {
        this.selectedGroup.members.push(res.member);
        this.openSnackBar('Member added successfully!');
        this.disabled = false;
        this.dialog.closeAll();
        this.userEmail = '';
      }, error: (err: any) => {
        this.disabled = false;
        this.openSnackBar('An issue occured when adding this member, please try again later, or try to add another memeber!');
      }
    })
  }

  openConfirmDeleteTeam(){
    this.dialog.open(this.deleteGroupDialog, { width: '400px' });
  }

  deleteTeam(){
    this.disabled = true;
    this.groupsService.deleteGroup(this.selectedGroupID).subscribe({
      next: (res)=> {
        this.groups = this.groups.filter((group) =>group.id !== this.selectedGroupID);
        this.selectedGroup = this.groups[0];
        this.selectedGroupID = this.groups[0].id;
        this.openSnackBar('Team deleted successfully!');
        this.disabled = false;
        this.dialog.closeAll();
      }, error: (err) =>{
        this.disabled = false;
        this.openSnackBar('An issue occured when deleting this team, please try again later!');
      }
    })
  }

  removeMember(member){
    this.disabled = true;
    this.groupsService.removeMemberFromGroup(this.selectedGroupID, member.id).subscribe({
      next: (res)=> {
        this.selectedGroup.members = this.selectedGroup.members.filter((mem) => mem.id !== member.id);
        let index = this.groups.findIndex(g => g.id == this.selectedGroupID);
        this.groups[index] = {...this.groups[index], members: this.groups[index].members.filter((mem) => mem.id !== member.id) };
        this.openSnackBar('Member removed successfully!');
        this.disabled = false;
        this.dialog.closeAll();
      }, error: (err) =>{
        this.disabled = false;
        this.openSnackBar('An issue occured when removing this member, please try again later!');
      }
    })
  }
}
