<mat-sidenav-container *ngIf="!loading; else showLoading">
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
        <app-sidebar-content [selected]="'home'" [isOpen]="false"
            (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content" >

        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <app-welcome *ngIf="empty; else content"></app-welcome>
        <ng-template #content>
            <div fxLayout="column" fxLayoutGap="30px" class="container-details-wrapper">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start"
                    fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.lt-md="15px">

                    <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
                        <div class="title">Home</div>
                        <button mat-button [matMenuTriggerFor]="menu1" class="new-container-button add">
                            <img src="assets/images/dashboard/container-content/plus.svg" alt="plus">
                            <span style="color:#fff">Add</span></button>

                        <mat-menu #menu1="matMenu" class="dropdown-content custom-menu menu1">
                            <button mat-menu-item (click)="addContainer()">
                                <div class="option">
                                    <img [src]="dark ? 'assets/images/dashboard/sidebar/containers-icon-dark.svg' : 'assets/images/dashboard/sidebar/containers-icon.svg'"
                                        alt="pass icon" style="width: 27px; height: 28px">
                                    <a>Container</a>
                                </div>
                            </button>
                            <button mat-menu-item (click)="passwordDialog()">
                                <div class="option">
                                    <img [src]="dark ? 'assets/images/dashboard/container-content/passwords-icon-dark.svg' : 'assets/images/dashboard/container-content/passwords-icon.svg'"
                                        alt="pass icon" style="width: 27px; height: 28px">
                                    <a>Password</a>
                                </div>
                            </button>
                            <button mat-menu-item>
                                <div class="option" (click)="notebookDialog()">
                                    <img [src]="dark ? 'assets/images/dashboard/container-content/notebook-icon-dark.svg' : 'assets/images/dashboard/container-content/notebooks-icon.svg'"
                                        alt="notebook" style="width: 27px; height: 28px" />
                                    <a>Notebook</a>
                                </div>
                            </button>

                        </mat-menu>
                    </div>
                </div>

                <div class="containers-devider"></div>

                <div class="filter-section">
                    <div>
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedFilter"
                            (change)="filterData($event)">
                            <mat-button-toggle value="all">View all</mat-button-toggle>
                            <mat-button-toggle value="passwords">
                                <img [src]="dark || selectedFilter=='passwords' ? 'assets/images/dashboard/container-content/passwords-icon-dark.svg' : 'assets/images/dashboard/container-content/passwords-icon.svg'"
                                    alt="pass icon" style="width: 21px; height: 20px" class="icon-filter">
                                Passwords</mat-button-toggle>
                            <mat-button-toggle value="notebooks">
                                <img [src]="dark || selectedFilter=='notebooks' ? 'assets/images/dashboard/container-content/notebook-icon-dark.svg' : 'assets/images/dashboard/container-content/notebooks-icon.svg'"
                                    alt="note icon" style="width: 21px; height: 20px" class="icon-filter">
                                Notebooks</mat-button-toggle>
                        </mat-button-toggle-group>

                    </div>
                    <!-- Search Bar -->
                    <div class="group" fxHide.gt-sm>
                        <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" />
                        <input placeholder="Search" class="input" [(ngModel)]="searchString"
                            (ngModelChange)="updateSearchString()" autocomplete="off" />
                    </div>
                </div>

                    <div class="table-container">
                        <table aria-describedby="list of containers">
                            <thead>
                                <tr>

                                    <th class="header-cell">All</th>
                                    <th class="header-cell">Owner</th>
                                    <th class="header-cell">Shared With</th>
                                    <th class="header-cell">Container</th>
                                    <th class="header-cell">Last Edited</th>
                                    <th class="header-cell"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of homeData | search: searchValue; let i = index">

                                    <td class="table-column-name"  (click)="selectItem(item)">
                                        <div class="icon-container">
                                            <img alt="icon" class="main-icon" [src]="item.icon" />
                                            <img *ngIf="item.type == 'Password'"
                                                src="assets/images2/dashboard/container-content/type-password-icon.svg"
                                                class="overlay-icon" alt="password">
                                            <img *ngIf="item.type == 'Notebook'"
                                                src="assets/images2/dashboard/container-content/type-notebook-icon.svg"
                                                class="overlay-icon" alt="notebook">

                                        </div>
                                        <span>{{ item.name }}</span>
                                    </td>

                                    <td class="table-column-date"  (click)="selectItem(item)">
                                        <span *ngIf="item.ownerData?.email == user?.email"> Me</span>
                                        <span *ngIf="item.ownerData?.email != user?.email && (item.ownerData?.firstName.length>0 ||  item.ownerData?.lastName.length>0)">{{item.ownerData?.firstName}} {{item.ownerData?.lastName}}</span>
                                        <span *ngIf="item.ownerData?.email != user?.email && item.ownerData?.firstName.length==0 &&  item.ownerData?.lastName.length==0">{{item.ownerData?.email}}</span>
                                    </td>

                                    <td  (click)="selectItem(item)">
                                        <div class="viewer" fxLayout="row" *ngIf="item?.recipients?.length !== 0">
                                            <div class="recipients-wrapper"
                                                *ngFor="let r of item?.recipients ; let i = index">

                                                <div class="recipient">
                                                    <div *ngIf="i<3" [ngClass]="{
                                                        'user-icon-yellow': i % 2 === 0,
                                                        'user-icon-blue': i % 2 !== 0
                                                        }" [matTooltip]="r">
                                                        {{r.slice(0, 1).toUpperCase() }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div *ngIf="item?.recipients?.length>3" class="more">
                                                    +{{item?.recipients?.length-3}}</div>
                                            </div>
                                        </div>
                                        <div mat-cell class="table-column-date" *ngIf="item?.recipients?.length === 0">
                                            Not shared
                                        </div>
                                    </td>

                                    <td class="table-column-container" >
                                        <button *ngIf="(item.container == '' || item.container == null) && item.ownership=='own'" class="add-container" (click)="moveItem(item)">
                                            <img src="assets/images2/dashboard/plus-blue.svg" alt="plus" > Add to Container
                                        </button>
                                        <span *ngIf="item.container != '' && item.container != null" (click)="selectItem(item)">{{ item.container}}</span>
                                    </td>

                                    <td class="table-column-date"  (click)="selectItem(item)">
                                        <span>{{ item.updatedAt | date }}</span>
                                    </td>

                                    <td>
                                        <div class="dropdown-container">
                                            <button mat-button [matMenuTriggerFor]="menu">
                                                <img src="assets/images/dashboard/dots-icon.svg" alt="dots" />
                                            </button>
                                            <mat-menu #menu="matMenu" class="dropdown-content custom-menu">
                                                <button mat-menu-item (click)="editItem(item)" *ngIf="item.owner==user.id">
                                                    <div class="flex option">
                                                        <img class="p-1"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'"
                                                            alt="edit" />
                                                        <a class="p-1">Edit</a>
                                                    </div>
                                                </button>
                                                <button mat-menu-item (click)="shareItem(item)" *ngIf="item.owner==user.id">
                                                    <div class="flex option">
                                                        <img class="p-1"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/share-icon-dark.svg' : 'assets/images/dashboard/container-card/share-icon.svg'"
                                                            alt="share" />
                                                        <a class="p-1">Share</a>
                                                    </div>
                                                </button>
                                                <button mat-menu-item (click)="moveItem(item)" *ngIf="item.owner==user.id">
                                                    <div class="option">
                                                        <img [src]="dark ? 'assets/images/dashboard/container-card/move-icon-dark.svg' : 'assets/images/dashboard/container-card/move-icon.svg'"
                                                            alt="move" />
                                                        <a>Move</a>
                                                    </div>
                                                </button>
                                                <button mat-menu-item (click)="copyItem(item)">
                                                    <div class="option">
                                                        <img [src]="dark ? 'assets/images/dashboard/container-card/copy-icon-dark.svg' : 'assets/images/dashboard/container-card/copy-icon.svg'"
                                                            alt="copy" />
                                                        <a>Clone</a>
                                                    </div>
                                                </button>
                                                <button mat-menu-item (click)="deleteItem(item)" *ngIf="item.owner==user.id">
                                                    <div class="flex option">
                                                        <img class="p-1"
                                                            [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/trash-icon.svg'"
                                                            alt="delete" />
                                                        <a class="p-1">Move to Trash</a>
                                                    </div>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </ng-template>
    </mat-sidenav-content>
<!-- 
    <ng-template #showLoading>
            <mat-sidenav-content class="sidebar-content">
                <div class="relative h-full">
                    <div class="sidebar-content absolute flex flex-col position-el gap-3">
                        <mat-spinner diameter="50" color="accent"></mat-spinner>
                        <div class="pop-ups-subtitle">Please wait we are fetching your data</div>
                    </div>
                </div>
            </mat-sidenav-content>
    </ng-template> -->

</mat-sidenav-container>

<!-- add notebook dialog -->
<ng-template #addNotebookDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="25px">
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="delete-dialog-title">{{addNotebook ? 'Create' : 'Edit'}} Notebook</span>
                        <span class="delete-dialog-description">Please select an icon and enter a name for this Notebook.</span>
                    </div>

                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon" (click)="cancel()" class="pointer" />
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                    <div class="group" for="myfile">
                        <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                            <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
                        </div>

                        <input type="file" id="myfile" (change)="onIconChange($event,true)" accept="image/*" />
                        <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                    </div>
                </div>


                <div fxLayout="column" fxLayoutGap="8px">
                    <span for="notebook_name" class="dialog-label">Notebook name</span>
                    <input [(ngModel)]="notebook_name" autocomplete="off" class="dialog-input" placeholder="Name" />
                </div>


                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center"
                    [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                    <button *ngIf="addNotebook" class="save-btn" (click)="createNotebook()" [disabled]="disabledButton">Create</button>
                    <button *ngIf="!addNotebook" class="save-btn" (click)="updateNotebook()" [disabled]="disabledButton">Save</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<!-- add password dialog -->
<ng-template #addPasswordDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="delete-dialog-title">Add Password</span>


                    <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon"
                        (click)="cancel()" class="pointer" />
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                    <div class="group" for="myfile">
                        <div class="profile-photo-wrapper" [ngClass]="{'placeholder': !preview, 'no-bg': preview}">
                            <img *ngIf="preview" class="profile-photo" [src]="preview" alt="container-icon">
                        </div>

                        <input type="file" id="myfile" (change)="onIconChange($event, false)" accept="image/*" />
                        <img class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                    </div>
                    <div fxLayout="column" fxLayoutGap="0px">
                        <label class="dialog-label">Upload Icon</label>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" class="password-row">
                    <div fxLayout="column" fxLayoutGap="8px" class="password-item">
                        <span class="dialog-label">Name</span>
                        <input autocomplete="off" class="dialog-input" placeholder="Name" [(ngModel)]="name" />
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px" class="password-item">
                        <span class="dialog-label">User Name</span>
                        <input autocomplete="off" class="dialog-input" placeholder="User Name" [(ngModel)]="userName" />
                    </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" class="password-item">
                    <span class="dialog-label">Url</span>
                    <div class="url-wrapper">
                        <div class="extract-wrapper">
                            <label class="form-label underline pointer" (click)="extractFavicon()">extract icon </label>
                        </div>
                        <input autocomplete="off" type="text" [(ngModel)]="url" placeholder="Url"
                            class=" dialog-input dialog-input1 ">
                    </div>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" class="password-item">
                    <span class="dialog-label">Password</span>
                    <div class="dialog-input-wrapper">
                        <input autocomplete="off" class="dialog-input" placeholder="Password" [(ngModel)]="password"
                            [type]="show ? 'text' : 'password'" />
                        <img class="hide-show-button" [src]="show ? 'assets/images/show.svg': 'assets/images/hide.svg'"
                            alt="show-hide" (click)="toggleShow()" />
                    </div>

                </div>

                <div fxLayout="column" fxLayoutGap="8px" class="password-item wysiwyg-container">
                    <span class="dialog-label">Notes</span>
                    <quill-editor [(ngModel)]="notesData" [modules]="modules"
                        (onContentChanged)="onContentChanged($event)" [readOnly]="false"></quill-editor>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" class="password-item">
                    <span class="dialog-label">Files</span>
                    <label for="fileInput" class="flex justify-center items-center files-block" fxLayout="column"
                        fxLayoutGap="5px" fxLayoutAlign="center center">
                        <img src="assets/images/dashboard/container-content/import-icon.svg" alt="alt">
                        <p class="file-input">Import File</p>

                        <input id="fileInput" type="file" style="display: none;" (change)="onFileChange($event)" />
                    </label>
                </div>

                <div *ngIf="filesToShow.length>0 " fxLayout="column" fxLayoutGap="15px">
                    <div *ngFor="let file of filesToShow; let i = index;" fxLayout="row"
                        fxLayoutAlign="space-between center" fxLayoutGap="5px" class="file-container">
                        <div fxLayoutAlign="start center" fxLayoutGap="8px">
                            <img [src]="file.icon" alt="file-icon" width="30px" />
                            <a class="form-label underline pointer" [href]="file.data"
                                [download]="file.name + file.extension">{{file.name}}{{file.extension}}</a>
                        </div>
                        <img src="assets/images/dashboard/container-content/x-close.svg" alt="x icon"
                            (click)="removeFile(i)" class="pointer" />
                    </div>
                </div>


                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center"
                    [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                    <button class="cancel-btn" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn " (click)="addPassword()" [disabled]="disabledButton">Create</button>
                </div>
            </div>

        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #verifyMasterPasswordDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                <span class="dialog-title">Verify Master Password</span>
                <div class="relative full-width">
                    <input autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input"
                        [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPassword()" />
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show"
                        (click)="toggleShow()" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width"
                [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="verifyMasterPassword()"
                    [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #addMasterPasswordDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" class="w-[60px]" />
                <div class="pop-ups-subtitle">This password will be used to encrypt the key of all your containers that
                    support standard security, and you will be able to change it whenever you want, from settings
                    section!</div>
                <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="center start" class="full-width">
                    <div fxLayout="column" fxLayoutGap="10px" class="full-width">
                        <span class="dialog-label">Enter Master Password</span>
                        <div fxLayout="row" class="full-width relative">
                            <input [type]="show ? 'text' : 'password'" id="masterPass1" name="masterPass1"
                                #masterPass1="ngModel" class="dialog-input" placeholder="Enter Master Password"
                                [(ngModel)]="masterPasswordValue" autocomplete="off" />
                            <img class="eye-div" alt="show-hide"
                                [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                (click)="toggleShow()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                                class="pointer" />
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="(masterPass1.dirty || masterPass1.touched) && masterPasswordValue.length<6">
                        Password should contain minimum 6 characters
                    </div>

                    <div fxLayout="column" fxLayoutGap="10px" class="full-width">
                        <span class="dialog-label">Enter Master Password Again</span>
                        <div fxLayout="row" class="full-width relative">
                            <input [type]="show ? 'text' : 'password'" id="masterPass2" name="masterPass2"
                                #masterPass2="ngModel" class="dialog-input" placeholder="Enter Password Again"
                                [(ngModel)]="confirmMasterPasswordValue" autocomplete="off" />
                            <img class="eye-div" alt="show-hide"
                                [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                (click)="toggleShow()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                                class="pointer" />
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="masterPasswordValue.length>0 && confirmMasterPasswordValue.length>0 && (masterPass2.dirty || masterPass2.touched) && masterPasswordValue!=confirmMasterPasswordValue">
                        Passwords doesn't match!
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width"
                [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="addMasterPassword()"
                    [disabled]="disabledButton">Save</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #showLoading>
    <div class="relative h-full">
        <div class="sidebar-content absolute flex flex-col position-el gap-3">
            <mat-spinner diameter="50" color="accent"></mat-spinner>
            <div class="pop-ups-subtitle">Please wait we are fetching your data</div>
        </div>
    </div>
</ng-template>

<ng-template #passphrase>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                <span class="dialog-title">Verify Passphrase</span>
                <div class="relative full-width">
                    <input *ngIf="!moveNewContainer && !copyIntoContainer" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphrase()" />
                    <input *ngIf="moveNewContainer" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphraseAndMove()" />
                    <input *ngIf="copyIntoContainer" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="passphraseValue" (keydown.enter)="verifyPassphraseAndCopy()" />
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="toggleShow()" />
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="!moveNewContainer && !copyIntoContainer" class="save-btn full-width" (click)="verifyPassphrase()" [disabled]="disabledButton">Verify</button>
                <button *ngIf="moveNewContainer" class="save-btn full-width" (click)="verifyPassphraseAndMove()" [disabled]="disabledButton">Move</button>
                <button *ngIf="copyIntoContainer" class="save-btn full-width" (click)="verifyPassphraseAndCopy()" [disabled]="disabledButton">Clone</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #masterPasswordDecrypt>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/passphrase.svg" width="60" />
                <span class="dialog-title">Verify Master Password</span>
                <div class="relative full-width">
                    <input *ngIf="!rsaEncryption" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMasterPasswordAndDecrypt()" />
                    <input *ngIf="rsaEncryption" autocomplete="off" [type]="show ? 'text' : 'password'" class="dialog-input" [(ngModel)]="masterPasswordValue" (keydown.enter)="verifyMPandRSAdecryption()" />
                    <img class="eye" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " alt="hide-show" (click)="toggleShow()" />
                </div>
            </div>
    
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="!rsaEncryption" class="save-btn full-width" (click)="verifyMasterPasswordAndDecrypt()" [disabled]="disabledButton">Verify</button>
                <button *ngIf="rsaEncryption" class="save-btn full-width" (click)="verifyMPandRSAdecryption()" [disabled]="disabledButton">Verify</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #hardwareKey>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/hardware-key.svg" width="50" />
                <span class="dialog-title">Verify Hardware Key</span>
                <input *ngIf="!moveNewContainer && !copyIntoContainer" autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKey()" />
                <input *ngIf="moveNewContainer" autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKeyAndMove()" />
                <input *ngIf="copyIntoContainer" autocomplete="off" type="text" class="dialog-input" [(ngModel)]="hardwareKeyValue" (keydown.enter)="verifyHardwareKeyAndCopy()" />
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="!moveNewContainer && !copyIntoContainer" class="save-btn full-width" (click)="verifyHardwareKey()" [disabled]="disabledButton">Verify</button>
                <button *ngIf="moveNewContainer" class="save-btn full-width" (click)="verifyHardwareKeyAndMove()" [disabled]="disabledButton">Move</button>
                <button *ngIf="copyIntoContainer" class="save-btn full-width" (click)="verifyHardwareKeyAndCopy()" [disabled]="disabledButton">Clone</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #questionAnswer>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img alt="icon" src="assets/images/dashboard/pop-ups/question.svg" width="50" />
                <span class="dialog-title">Verify Answer</span>
                <mat-select class="dialog-input" [(value)]="question" panelClass="typeSelectPanelClass">
                    <mat-option value="1">What's your Chilhood Nickname?</mat-option>
                    <mat-option value="2">What's the Name of your Aunt?</mat-option>
                    <mat-option value="3">Where did you born?</mat-option>
                    <mat-option value="4">What's the Name of your Uncle?</mat-option>
                    <mat-option value="5">What's your Secondary School?</mat-option>
                </mat-select>
                <input *ngIf="!moveNewContainer && !copyIntoContainer"  autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswer()" />
                <input *ngIf="moveNewContainer" autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswerAndMove()" />
                <input *ngIf="copyIntoContainer" autocomplete="off" type="text" class="dialog-input" [(ngModel)]="answerValue" (keydown.enter)="verifyQuestionAnswerAndCopy()" />
            </div>

            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="!moveNewContainer && !copyIntoContainer"  class="save-btn full-width" (click)="verifyQuestionAnswer()" [disabled]="disabledButton">Verify</button>
                <button *ngIf="moveNewContainer" class="save-btn full-width" (click)="verifyQuestionAnswerAndMove()" [disabled]="disabledButton">Move</button>
                <button *ngIf="copyIntoContainer" class="save-btn full-width" (click)="verifyQuestionAnswerAndCopy()" [disabled]="disabledButton">Clone</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div>
                <span class="dialog-label">Decrypting the data...</span>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<!-- delete item -->
<ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <img src="assets/images2/dashboard/delete-icon.svg"/>

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </div>
                <span class="delete-dialog-title">Move Item to Trash</span>
                <span class="delete-dialog-description">Are you sure to move this item to trash</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width"
                [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn delete full-width" (click)="moveToTrash()" [disabled]="disabledButton">Move to Trash</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #moveDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span class="dialog-label">Please select the container you wanna {{moving ? 'move' : 'clone'}} this item {{moving ? 'to' : 'into'}}:</span>
                <mat-select class="dialog-input" [(value)]="newContainer" panelClass="typeSelectPanelClass">
                    <mat-option value="" disabled>Select Container</mat-option>
                    <mat-option class="full-width" *ngFor="let container of ownContainers" [value]="container.id+',own'" [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.iconData" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Owned By you</span>
                        </div>
                    </mat-option>
                    <mat-option class="full-width" *ngFor="let container of sharedContainers" [value]="container.id+',shared'" [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.iconData" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Shared by: {{container.ownerData.email}}</span>
                        </div>
                    </mat-option>
                    <mat-option class="full-width" *ngFor="let container of deadManSwitchContainers" [value]="container.id+',backup'"  [disabled]="container.id==selectedItem.containerID && moving && !copying">
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <img alt="icon" class="container-icon" [src]="container?.iconData" />
                            <span>{{container.name}}</span>
                        </div>
                        <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                            <span>Shared by: {{container.ownerData.email}}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button *ngIf="moving" class="save-btn full-width" (click)="moveSelectedItem()" [disabled]="disabledButton">Move</button>
                <button *ngIf="copying" class="save-btn full-width" (click)="copySelectedItem()" [disabled]="disabledButton">Clone</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #confirmDialog>
    <mat-dialog-content >
        <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <span class="dialog-label">You didn't selected a container to clone this item into, it will be encrypted using your public key.</span>
            <div fxLayout="row" fxLayoutGap="15px" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn full-width" (click)="copyRSA()" [disabled]="disabledButton">Confirm</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #shareItemDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/images2/dashboard/share-icon.svg"/>

                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
            </div>
            <span class="dialog-title">Share with people</span>
            <div  *ngIf="loadingRecipients" class="flex  justify-center items-center justify-items">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length==0">
                <span class="dialog-label">This item is not shared yet with any person.</span>
            </div>
            <div *ngIf="!loadingRecipients && recipients.length>0">
                <span class="dialog-label">The following users can read the data of this item:</span>
                <div *ngFor="let recipient of recipients">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" >
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && (recipient.firstName !== '' || recipient.lastName !== '')"
                                class="user-profile-picture-icon">{{ recipient.firstName!.toUpperCase().slice(0, 1)}}{{recipient.lastName!.toUpperCase().slice(0, 1) }}</span>
                            <span *ngIf="(!recipient.profilePicture || recipient.profilePicture.trim() === '-' || recipient.profilePicture.trim() === '_' || recipient.profilePicture.trim().length===0) && recipient.firstName === '' && recipient.lastName === ''"
                                class="user-profile-picture-icon">{{ recipient.email!.toUpperCase().slice(0, 1)}}{{recipient.email!.toUpperCase().slice(0, 1) }}</span>
                            <img *ngIf="recipient.profilePicture && recipient.profilePicture.trim() !== '-' && recipient.profilePicture.trim() !== '_' && recipient.profilePicture.trim().length!==0"
                                class="user-profile-picture" alt="profile" [src]="recipient.profilePicture" referrerpolicy="no-referrer" />
                            <div fxLayout="column" fxLayoutAlign="center start">
                                <div class="recipient-name">{{recipient.firstName}} {{recipient.lastName}}</div>
                                <div class="recipient-email">{{recipient.email}}</div>
                            </div>
                        </div>
                        <button class="recipient-remove" (click)="removeRecipientItem(recipient)">remove</button>
                    </div>
                </div>
            </div>
            <span class="dialog-label">Enter your recipient email</span>
            <div fxLayout="row" > 
                <input autocomplete="off" type="email" class="dialog-input share" [(ngModel)]="newRecipientEmail" placeholder="Add new recipient from here"/>
                <button class="save-btn share" (click)="addRecipientItem()" [disabled]="disabledButton">Add</button>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '': !disabledButton}">
                <button class="cancel-btn" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                <button class="save-btn" (click)="cancel()" [disabled]="disabledButton">Done</button>
            </div>      
        </div>
    </mat-dialog-content>
</ng-template>