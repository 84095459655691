<div class="content">
    <div fxLayout="row" fxLayoutGap="20px" fxAlign="start center">
        <div>
            <div class="org-icon-container" *ngIf="!selectedOrganization.icon">
                <img src="assets/images2/dashboard/organisation-icon.svg" />
            </div>
            <div class="org-icon-container" *ngIf="selectedOrganization.icon">
                <img [src]="selectedOrganization.icon" />
            </div>
        </div>

        <div fxLayout="column" fxAlign="center center">
            <div class="org-name">{{selectedOrganization.name}}</div>
            <div fxLayout="column" fxLayoutGap="5px" fxAlign="start center">
                <div fxLayout="row" fxLayoutGap="3px" fxAlign="start center">
                    <span class="orgIdentifier-txt">
                        {{
                        selectedOrganization.orgIdentifier
                        ? (selectedOrganization.enableSSO
                        ? (isHidden
                        ? '*'.repeat(selectedOrganization.orgIdentifier.length)
                        : selectedOrganization.orgIdentifier)
                        : '*********')
                        : 'SSO not configured'
                        }}
                    </span>

                    <img *ngIf="selectedOrganization.orgIdentifier" (click)="toggleHideOrgIdentifier()"
                        src="assets/images2/dashboard/organization/eye-off.svg" width="10" />
                </div>
                <div *ngIf="selectedOrganization.orgIdentifier" class="sso-txt"> SSO <span
                        class="enable-disable-txt">{{selectedOrganization.enableSSO ? '(Enabled)' : '(Disbled)'
                        }}</span></div>
            </div>

        </div>
    </div>

    <mat-button-toggle-group [(ngModel)]="selectedFilter">
        <mat-button-toggle value="TeamMates">
            <img [src]="dark || selectedFilter=='members' ? 'assets/images2/dashboard/organisation-content/members-icon-light.svg' : 'assets/images2/dashboard/organisation-content/members-icon.svg'"
                alt="pass icon" style="width: 21px; height: 20px" class="icon-filter">
            My TeamMates</mat-button-toggle>
        <mat-button-toggle value="MyGroup">
            <img [src]="dark || selectedFilter=='groups' ? 'assets/images2/dashboard/organisation-content/groups-icon-light.svg' : 'assets/images2/dashboard/organisation-content/groups-icon.svg'"
                alt="pass icon" style="width: 21px; height: 20px" class="icon-filter">
            My Group</mat-button-toggle>
    </mat-button-toggle-group>
    <div *ngIf="selectedFilter=='TeamMates'">
        <div class="table-container">
            <table aria-describedby="list of containers">
                <thead>
                    <tr>
                        <th class="header-cell">Member</th>
                        <th class="header-cell">Role</th>
                        <th class="header-cell">Join Date</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let user of organization.members; let i = index;">
                        <td class="table-column-user">
                            <div fxLayout="row" fxAlign="start center" fxLayoutGap="20px">
                                <span
                                    *ngIf="(!user.profilePicture || user.profilePicture.trim() === '-' || user.profilePicture.trim() === '_' || user.profilePicture.trim().length===0) && (user.firstName !== '' || user.lastName !== '')"
                                    class="user-profile-picture-icon-mini">{{ user.firstName!.toUpperCase().slice(0,
                                    1)}}{{
                                    user.lastName!.toUpperCase().slice(0, 1) }}</span>
                                <img *ngIf="user.profilePicture && !(user.profilePicture.trim() === '-' || user.profilePicture.trim() === '_' || user.profilePicture.trim().length===0 )"
                                    alt="icon" class="main-icon" [src]="user.profilePicture" />
                                <div fxLayout="column" fxAlign="start center" fxLayoutGap="10px">
                                    <div>
                                        {{user.firstName + " " + user.lastName}}
                                    </div>
                                    <div>
                                        {{user.email}}
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            {{user.id == selectedOrganization.admin ? 'Admin' : 'Simple Member'}}
                        </td>
                        <td>
                            {{formatDate(user.joinDate)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="selectedFilter=='MyGroup'">
        <div *ngIf="!organization.MyGroup">
            you don't belong to any group of this Organization
        </div>
        <div fxLayout="column" fxAlign="start center" fxLayoutGap="20px" *ngIf="organization.MyGroup">
            <div fxLayout="column" fxAlign="start stretch" class="group-header">
                <!-- Group Information -->
                <div fxLayout="row" fxAlign="center center" fxLayoutGap="15px" class="group-info">
                    <h2 class="group-name">{{ organization.MyGroup.name }}</h2>
                    <span class="members-count">{{ organization.MyGroup.members.length }} members</span>
                </div>
            
                <!-- Group Owner (Coordinator) -->
                <div fxLayout="row" fxAlign="center start" fxLayoutGap="10px" class="owner-info">
                    <div class="avatar-container">
                        <span
                            *ngIf="(!organization.MyGroup.coordinator.profilePicture || 
                                    organization.MyGroup.coordinator.profilePicture.trim() === '-' || 
                                    organization.MyGroup.coordinator.profilePicture.trim() === '_' || 
                                    organization.MyGroup.coordinator.profilePicture.trim().length === 0) && 
                                    (organization.MyGroup.coordinator.firstName !== '' || 
                                    organization.MyGroup.coordinator.lastName !== '')"
                            class="coordinator-icon">
                            {{ organization.MyGroup.coordinator.firstName!.toUpperCase().slice(0, 1) }}
                            {{ organization.MyGroup.coordinator.lastName!.toUpperCase().slice(0, 1) }}
                        </span>
            
                        <img *ngIf="organization.MyGroup.coordinator.profilePicture && 
                                    !(organization.MyGroup.coordinator.profilePicture.trim() === '-' || 
                                    organization.MyGroup.coordinator.profilePicture.trim() === '_' || 
                                    organization.MyGroup.coordinator.profilePicture.trim().length === 0)" 
                            alt="Owner Image" 
                            class="coordinator-image" 
                            [src]="organization.MyGroup.coordinator.profilePicture" />
                    </div>
            
                    <div class="coordinator-details">
                        <h3 class="coordinator-name">{{ organization.MyGroup.coordinator.firstName }} {{ organization.MyGroup.coordinator.lastName }}</h3>
                        <p class="coordinator-email">{{ organization.MyGroup.coordinator.email }}</p>
                    </div>
                </div>
            </div>
            
            
            <div fxLayout="row" fxAlign="start center" fxLayoutGap="20px">
                <div *ngFor="let user of organization.MyGroup.members" class="user-card">
                    <div fxLayout="row" fxAlign="start center" fxLayoutGap="20px">
                        <span
                            *ngIf="(!user.profilePicture || user.profilePicture.trim() === '-' || user.profilePicture.trim() === '_' || user.profilePicture.trim().length===0) && (user.firstName !== '' || user.lastName !== '')"
                            class="user-profile-picture-icon-mini">{{ user.firstName!.toUpperCase().slice(0, 1)}}{{
                            user.lastName!.toUpperCase().slice(0, 1) }}</span>
                        <img *ngIf="user.profilePicture && !(user.profilePicture.trim() === '-' || user.profilePicture.trim() === '_' || user.profilePicture.trim().length===0 )"
                            alt="icon" class="member-profileImg" [src]="user.profilePicture" />
                        <div fxLayout="column" fxAlign="start center" fxLayoutGap="10px">
                            <div>
                                {{user.firstName + " " + user.lastName}}
                            </div>
                            <div fxLayout="row" fxAlign="start center" fxLayoutGap="10px">
                                <img src="/assets/images2/dashboard/mail-icon.svg" width="20" />
                                <span>{{user.email}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>