import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { CsvToJsonService } from 'src/app/services/import-export/import-export.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
@Component({
  selector: 'app-migration-settings-content',
  templateUrl: './migration-settings-content.component.html',
  styleUrls: ['./migration-settings-content.component.scss']
})
export class StartMigrationSettingsContentComponent implements OnInit {
  fileImported: boolean = false;
  selectedContainer = 0;
  containerData: any;
  keyToDecrypt: any;
  file: File;
  show = false;
  disabledButton = false;
  isSettings = true;
  showPassword: boolean[] = [];
  selected: boolean[] = [];
  tableData: any[] = [];
  dataToImport : any[] = [];
  passwords : any[] = [];
  selectAll: boolean;
  importedFileName: string;
  icon = 1;
  passphrase = '';
  selection = '';
  hardwareKey = '';
  email = '';
  type = '';
  masterPassword = '';
  passManagerExportSteps = '';

  @Input() passManager = '';
  @Output() passManagerChange = new EventEmitter<string>();
  @ViewChild('loadingDialog') loadingDialog: TemplateRef<any>;
  @ViewChild('passphraseDialog') passphraseDialog: TemplateRef<any>;
  @ViewChild('hardwareKeyDialog') hardwareKeyDialog: TemplateRef<any>;
  @ViewChild('masterPasswordDialog') masterPasswordDialog: TemplateRef<any>;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  @ViewChild('stepsDialog') stepsDialog: TemplateRef<any>;

  scale = { 'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000 };

  get dark(): any {
    return this.theme.dark;
  }

  get ownContainers() {
    return this.container.ownContainers;
  }

  get sharedContainers() {
    return this.container.sharedContainers;
  }

  get deadManSwitchContainers() {
    return this.container.deadManSwitchContainers;
  }

  constructor(private theme: ThemeService, private router: Router, private media: MediaService, private extractPasswords: CsvToJsonService, private container: ContainerService, private containerPassword: ContainerPasswordService, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService, private _snackBar: MatSnackBar, public dialog: MatDialog, private sanitizer: DomSanitizer, private dexieService: DexieService) {
    window.addEventListener("dragover", function (e: Event) {
      e.preventDefault();
    }, false);
    window.addEventListener("drop", function (e: Event) {
      e.preventDefault();
    }, false);
    this.isSettings = window.location.pathname!='/import-data';
  }

  ngOnInit(): void {
    // should be because of OnInit interface implementation
  }

  //convert password for chrome, edge, opera
  converter(file: any) {
    this.importedFileName = file.name;
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const dataString: string = reader.result as string;
      this.fileImported = true;
      if (this.passManager == "chrome") {
        this.tableData = this.extractPasswords.chromeConverter(dataString);
      }
      if (this.passManager == "edge") {
        this.tableData = this.extractPasswords.edgeConverter(dataString);
      }
      if (this.passManager == "firefox") {
        this.tableData = this.extractPasswords.firefoxConverter(dataString);
      }
      if (this.passManager == "opera") {
        this.tableData = this.extractPasswords.operaConverter(dataString);
      }
      if (this.passManager == "safari") {
        this.tableData = this.extractPasswords.safariConverter(dataString);
      }
      if (this.passManager == "lastpass") {
        this.tableData = this.extractPasswords.lastpassConverter(dataString);
      }
      if (this.passManager == "1password") {
        this.tableData = this.extractPasswords.onepasswordConverter(dataString);
      }
      if (this.passManager == "bitwarden") {
        this.tableData = this.extractPasswords.bitwardenConverter(dataString);
      }
      if (this.passManager == "enpass") {
        this.tableData = this.extractPasswords.enpassConverter(dataString);
      }
      if (this.passManager == "nordpass") {
        this.tableData = this.extractPasswords.nordpassConverter(dataString);
      }
      if (this.passManager == "zohovault") {
        this.tableData = this.extractPasswords.zohovaultConverter(dataString);
      }
      if (this.passManager == "keeper") {
        this.tableData = this.extractPasswords.keeperConverter(dataString);
      }
      if (this.passManager == "roboform") {
        this.tableData = this.extractPasswords.roboformConverter(dataString);
      }
      this.showPassword = this.tableData.map((e) => false);
      this.selected = this.tableData.map((e) => false);
    };
  }

    selectAllRows() {
      this.selected = this.selected.map(row => !this.selectAll);
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.dataToImport = this.tableData;
      } else {
        this.dataToImport = [];
      }
    }

    selectRow(index) {
      this.selected[index] = !this.selected[index];
      if (this.selected[index]) {
        this.dataToImport = [...this.dataToImport, this.tableData[index]];
      } else {
        this.dataToImport = this.dataToImport.filter((i) => i != this.tableData[index]);
      }
    }

    onFileDropped($event) {
      const files = Array.from($event);
      this.file = files[0] as File;
      this.converter(this.file);
    }

    onFileSelected(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        this.file = input.files[0];
        this.converter(this.file);
      }
    }


    selectContainer(event: any) {
      this.selection = event.value;
      let value = event.value.split('-');
      this.selectedContainer = Number(value[1]);
      this.type = value[0];
      if (this.type == 'own') {
        this.containerData = this.ownContainers.find((item: any) => item.id === this.selectedContainer);
      } else {
        this.containerData = this.deadManSwitchContainers.find((item: any) => item.id === this.selectedContainer);
      }
    }

    cancel() {
      this.router.navigate(['home']);
    }

    cancelDialog(){
      this.dialog.closeAll();
    }

    openSnackBar(message: string) {
      let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
      snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
    }

    //show password
    togglePassword(index: number) {
      this.showPassword[index] = !this.showPassword[index];
    }

    isPassword() {
      this.show = !this.show;
    }

    async savePasswords(binary: any) {
      let userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };
      let totalMemory = userPlan.memory.memory * this.scale[userPlan.memory.unit];
      let restStorage = totalMemory;
      this.ownContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.sharedContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.deadManSwitchContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });

      this.media.getIcon(1)
        .subscribe({
          next: async (res: any) => {
            let dataToSave = [];
            let iconData: any;
            if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
              iconData = res.icon;
            } else {
              let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
              let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let passBase64String = btoa(string_pass_char);
              iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
            }

            let data = { comments: [], files: [], notes: { data: '', deltaJson: [] } };
            let passDataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);

            for (const p of this.passwords) {
              let passEncryption = await this.encryptDecrypt.encryptData(p.password, binary);
              let memory = Buffer.byteLength(JSON.stringify({ ...p, icon: res.icon }));
              let size = {};

              if (memory < 999) {
                size = { 'memory': memory, 'unit': 'B' };
              } else if ((memory >= 1000) && (999999 > memory)) {
                size = { 'memory': (memory / 1000), 'unit': 'KB' };
              } else if ((memory >= 1000000) && (999999999 > memory)) {
                size = { 'memory': (memory / 1000000), 'unit': 'MB' };
              } else if ((memory >= 1000000000) && (999999999999 > memory)) {
                size = { 'memory': (memory / 1000000000), 'unit': 'GB' };
              } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
                size = { 'memory': (memory / 1000000000000), 'unit': 'TB' };
              }

              restStorage = restStorage - memory;
              dataToSave.push({ password: { url: p.url, name: p.name, icon: p.icon, userName: p.userName, passData: passDataEncryption, password: passEncryption }, size: size });
            }

            if (restStorage > 0) {
              let user = JSON.parse(this.localstorage.getUser());
              this.dialog.open(this.loadingDialog, { width: '400px', autoFocus: false });
              this.containerPassword.addPasswords(dataToSave, this.selectedContainer, user['id'], null)
                .subscribe({
                  next: async (result: any) => {
                    this.dialog.closeAll();
                    if(this.type=='own'){
                      this.dexieService.getOwnContainers().then((dt: any) => {
                          let d = dt;
                          let index = d.findIndex((c: any) => c.id === this.selectedContainer);
                          let pass = d[index].passwords;

                          for (let i = 0; i < result.passwords.length; i++) {
                            pass.push({id: result.passwords[i], ...dataToSave[i].password, size: JSON.stringify(dataToSave[i].size), recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), containerID: this.selectedContainer, iconData, owner: user.id, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}});
                          }
                          
                          let newMemory = JSON.parse(result.newMemory);
                          d[index] = { ...d[index], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedOwnerKey: binary, decrypted: true };
                          this.dexieService.setOwnContainers(d);
                          this.container.setOwnContainers(d);
                        });
                    }else if(this.type=='backup'){
                      this.dexieService.getDeadManSwitchContainers().then((dt: any) => {
                          let d = dt;
                          let index = d.findIndex((c: any) => c.id === this.selectedContainer);
                          let pass = d[index].passwords;

                          for (let i = 0; i < result.passwords.length; i++) {
                            pass.push({id: result.passwords[i], ...dataToSave[i].password, size: JSON.stringify(dataToSave[i].size), recipients: null, deleted: false, createdAt: new Date(), updatedAt: new Date(), containerID: this.selectedContainer, iconData, owner: user.id, ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}});
                          }
                          
                          let newMemory = JSON.parse(result.newMemory);
                          d[index] = { ...d[index], usedMemory: { ...newMemory, memory: Number(newMemory.memory) }, passwords: pass, decryptedBackUpPersonKey: binary, decrypted: true};
                          this.dexieService.setDeadManSwitchContainers(d);
                          this.container.setDeadManSwitchContainers(d);
                        });
                    }
                    this.disabledButton = false;
                    this.openSnackBar('Items added successfully!');
                  },
                  error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Passwords cannot be added!');
                  }
                });
            } else {
              this.openSnackBar('Cannot add items! You reached the limit of your storage! Please upgrade your account to save more data with us!')
            }
          },
          error: (error: HttpErrorResponse) => {
          }
        });
    }

    async savePasswordsRSA() {
      this.disabledButton = true;
      let user = JSON.parse(this.localstorage.getUser());
      let publicKey = JSON.parse(atob(user['publicKey']));
      let userPlan = { ...JSON.parse(this.localstorage.getPlan()), memory: { ...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory) } };
      let totalMemory = userPlan.memory.memory * this.scale[userPlan.memory.unit];
      let restStorage = totalMemory;
      this.ownContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.sharedContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      this.deadManSwitchContainers.forEach((container: any) => {
        restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
      });
      
      let randomKey = await this.encryptDecrypt.getRandom();
      let binary = this.encryptDecrypt.bufferToBinary(randomKey);
      const encryptedKey = await this.encryptDecrypt.encryptDataRSA(binary, publicKey);

      this.media.getIcon(1)
        .subscribe({
          next: async (res: any) => {
            let dataToSave = [];
            let iconData: any;
            if (res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')) {
              iconData = res.icon;
            } else {
              let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
              let string_pass_char = my_password_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
              let passBase64String = btoa(string_pass_char);
              iconData = { id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:' + res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
            }

            let data = { comments: [], files: [], notes: { data: '', deltaJson: [] } };
            let passDataEncryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), binary);

            for (const p of this.passwords) {
              let passEncryption = await this.encryptDecrypt.encryptData(p.password, binary);
              let memory = Buffer.byteLength(JSON.stringify({ ...p, icon: res.icon }));
              let size = {};

              if (memory < 999) {
                size = { 'memory': memory, 'unit': 'B' };
              } else if ((memory >= 1000) && (999999 > memory)) {
                size = { 'memory': (memory / 1000), 'unit': 'KB' };
              } else if ((memory >= 1000000) && (999999999 > memory)) {
                size = { 'memory': (memory / 1000000), 'unit': 'MB' };
              } else if ((memory >= 1000000000) && (999999999999 > memory)) {
                size = { 'memory': (memory / 1000000000), 'unit': 'GB' };
              } else if ((memory >= 1000000000000) && (999999999999999 > memory)) {
                size = { 'memory': (memory / 1000000000000), 'unit': 'TB' };
              }

              restStorage = restStorage - memory;
              dataToSave.push({ password: { url: p.url, name: p.name, icon: p.icon, userName: p.userName, passData: passDataEncryption, password: passEncryption }, size: size });
            }

            if (restStorage > 0) {
              this.dialog.open(this.loadingDialog, { width: '400px', autoFocus: false });
              this.containerPassword.addPasswords(dataToSave, undefined, user['id'], encryptedKey)
                .subscribe({
                  next: async (result: any) => {
                    this.dialog.closeAll();
                    this.dexieService.getOwnPasswords().then((dt: any) => {
                          let pass = dt;
                          for (let i = 0; i < result.passwords.length; i++) {
                            pass.push({id: result.passwords[i], ...dataToSave[i].password, size: JSON.stringify(dataToSave[i].size), recipients: [], deleted: false, decrypted: true, createdAt: new Date(), updatedAt: new Date(), containerID: undefined, iconData, icon: this.container.setIcon(iconData), owner: user.id, type: 'Password', ownership: 'own', ownerData: {firstName: user.firstName, lastName: user.lastName, email: user.email, id: user.id, profilePicture: user.profilePicture}});
                          }
                          this.dexieService.setOwnPasswords(pass);
                          this.container.setOwnPasswords(pass);
                        });
                    this.disabledButton = false;
                    this.openSnackBar('Items added successfully!');
                  },
                  error: (error: HttpErrorResponse) => {
                    this.openSnackBar('Passwords cannot be added!');
                  }
                });
            } else {
              this.openSnackBar('Cannot add items! You reached the limit of your storage! Please upgrade your account to save more data with us!')
            }
          },
          error: (error: HttpErrorResponse) => {
          }
        });
    }

    async verifyMasterPassword(){
      try{
        this.disabledButton = true;
        let decrypted = await this.encryptDecrypt.decryptKey(this.keyToDecrypt, await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPassword.trim()));
        let binary = this.encryptDecrypt.bufferToBinary(decrypted);
        this.savePasswords(binary);
    
      }catch(err){
        this.disabledButton = false;
        this.openSnackBar('Wrong master password');
        console.log(err);
      }
    }
  
    async verifyPassphrase(){
      try{
        this.disabledButton = true;
        let decrypted = await this.encryptDecrypt.decryptKey(this.keyToDecrypt, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphrase.trim()));
        let binary = this.encryptDecrypt.bufferToBinary(decrypted);
        this.savePasswords(binary);
  
      }catch(err){
        this.disabledButton = false;
        this.openSnackBar('Wrong passphrase');
        console.log(err);
      }
    }
  
    async verifyHardwareKey(){
      try{
        this.disabledButton = true;
        let decrypted = await this.encryptDecrypt.decryptKey(this.keyToDecrypt, this.hardwareKey.trim().slice(0,12));
        let binary = this.encryptDecrypt.bufferToBinary(decrypted);
        this.savePasswords(binary);
  
      }catch(err){
        this.disabledButton = false;
        this.openSnackBar('Wrong hardware key');
        console.log(err);
      }
    }
  
    import(index: any){
      this.email = this.localstorage.getEmail();
      if(index!==-1){
            this.passwords = [this.tableData[index]];
      }else{
            this.passwords = this.dataToImport;
      }

      if(this.selectedContainer==0){
            this.dialog.open(this.confirmDialog, { width: '400px', autoFocus: false });
      }else{
          if(this.type=='own') {
            if(this.containerData.ownerEncryptionMode===2) {
              this.keyToDecrypt = new Uint8Array(JSON.parse(this.containerData['ownerKey']).data);
              this.dialog.open(this.passphraseDialog, { width: '400px', autoFocus: false });
            } else if(this.containerData.ownerEncryptionMode===3){
              this.keyToDecrypt = new Uint8Array(JSON.parse(this.containerData['ownerKey']).data);
              this.dialog.open(this.hardwareKeyDialog, {  width: '400px', autoFocus: false });
            } else {
              this.keyToDecrypt = new Uint8Array(JSON.parse(this.containerData['ownerKey']).data);
              this.dialog.open(this.masterPasswordDialog, {  width: '400px', autoFocus: false });
            }
          
          }else if(this.containerData.backUpPersonEncryptionMode===2) {
              this.keyToDecrypt = new Uint8Array(JSON.parse(this.containerData['backUpPersonKey']).data);
              this.dialog.open(this.passphraseDialog, { width: '400px', autoFocus: false});
            } else if(this.containerData.backUpPersonEncryptionMode===3){
              this.keyToDecrypt = new Uint8Array(JSON.parse(this.containerData['backUpPersonKey']).data);
              this.dialog.open(this.hardwareKeyDialog, { width: '400px',  autoFocus: false});
            } 
  
        }
      
    }

    goBack(){
      this.passManager = '';
      this.passManagerChange.emit('');
    }

    openDialog(){
      if(this.dark){
        switch(this.passManager){
          case 'chrome': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Chrome dark.png'; break;
          case 'edge': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/edge Dark.png'; break;
          case 'firefox': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Firefox Dark.png'; break;
          case 'opera': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Opera Dark.png'; break;
          case 'safari': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/safari dark.png'; break;
          case 'lastpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/LastPass Dark.png'; break;
          case '1password': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/1Password Dark.png'; break;
          case 'bitwarden': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Bitwarden Dark.png'; break;
          case 'enpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/enpass Dark.png'; break;
          case 'nordpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/NordPassword Dark.png'; break;
          case 'zohovault': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/ZohoVault dark.png'; break;
          case 'keeper': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Keeper Dark.png'; break;
          case 'roboform': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/RoboForm Dark.png'; break;
          default: '';
        }
      }else if(!this.dark){
        switch(this.passManager){
          case 'chrome': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Chrome Light.png'; break;
          case 'edge': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/edge light.png'; break;
          case 'firefox': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Firefox light.png'; break;
          case 'opera': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Opera light.png'; break;
          case 'safari': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/safari light.png'; break;
          case 'lastpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/LastPass Light.png'; break;
          case '1password': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/1Password light.png'; break;
          case 'bitwarden': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Bitwarden Light.png'; break;
          case 'enpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Enpass light.png'; break;
          case 'nordpass': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/NordPassword Light.png'; break;
          case 'zohovault': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/ZohoVault light.png'; break;
          case 'keeper': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/Keeper Light.png'; break;
          case 'roboform': this.passManagerExportSteps = 'assets/images2/dashboard/import-settings/RoboForm light.png'; break;
          default: '';
        }
      }
      this.dialog.open(this.stepsDialog, {width: '500px'});
    }
}
