import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { UserService } from 'src/app/services/user/user.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';

@Component({
  selector: 'app-change-master-password',
  templateUrl: './change-master-password.component.html',
  styleUrls: ['./change-master-password.component.scss']
})

export class ChangeMasterPasswordComponent {
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>; 
  @ViewChild('masterPassword') masterPasswordDialog: TemplateRef<any>; 
  @ViewChild('recoveryKeyPopup') recoveryKeyPopup: TemplateRef<any>; 
  @ViewChild('newMasterPasswordDialog') newMasterPasswordDialog: TemplateRef<any>; 
  @ViewChild('enterRecoveryKeyDialog') enterRecoveryKeyDialog: TemplateRef<any>; 
  @ViewChild('start') start: any;
  
  firstRK = true;
  hidePassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  disabled = false;
  show = false;
  confirm = false;
  hasPassword = false;
  hasRecoveryKey = false;
  closeButton = false;
  currentPassword = '';
  password = '';
  confirmPassword = '';
  waitingText = '';
  masterPasswordValue = '';
  recoveryKeyValue = '';
  recoveryKey = '';
  
  get dark(): any{
    return this.theme.dark;
  }

  constructor(private theme: ThemeService, private user: UserService, private _snackBar: MatSnackBar, private router: Router, private localstorage: LocalStorageService, public dialog: MatDialog, private encryptDecrypt: EncryptDecryptService, private container: ContainerService, private dexieService: DexieService, private clipboard: Clipboard, private notebookService: ContainerNoteService) {
    let userData = JSON.parse(this.localstorage.getUser());
    if(userData?.publicKey!=null && userData?.privateKey!=null){
      this.hasPassword = true;
    }

    if(userData?.recoveryKey!=null){
      this.hasRecoveryKey = true;
    }
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  toggleSidebar(){
    this.start.toggle();
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }
  
  toggleNewPassword() {
    this.hideNewPassword = !this.hideNewPassword;
  }

  toggleConfirmPassword() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  isPassword(){
    this.show = !this.show;
  }
  
  cancel(){    
    this.router.navigate(['home']);
  } 

  cancelDialog(){
    this.dialog.closeAll();
    this.closeButton = false;
  }

  async openConfirmDialog(){
    if(this.password.trim()!==this.confirmPassword.trim()){
      this.openSnackBar("Passwords don't match! Please try again.");
    } else if (this.password.trim()==this.currentPassword.trim()){
      this.openSnackBar("Please enter a different password.");
    }else if(this.currentPassword.trim().length===0 && this.hasPassword){
        this.openSnackBar("Current Password is required!");
    }else if(this.password.trim().length<6){
        this.openSnackBar("Master Password should be at least 6 characters!");
    }else if(this.hasPassword){

      // here we are verifying the user entered the right current used master password, if verified he gonna be asked to confirm the password change
      // the verification consist of decrypting the private key of the user, if the decrypion succeeded, then it is the right current master password
      try{
        this.disabled = true;
        let userData = JSON.parse(this.localstorage.getUser());
        let currentBinaryKey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.currentPassword.trim())));
        let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], currentBinaryKey);
        this.localstorage.setPrivateKey(privateKeyDecrypted);
        await this.updateRSAKeysForUsersThatHasAlreadyMP(userData);
        this.disabled = false;
        this.dialog.open(this.confirmDialog, {
          width: '400px',
          disableClose: true
        });
      }catch(err){
        this.disabled = false;
        this.dialog.closeAll();
        this.openSnackBar("Please verify the current password!");
      }
    }else if(!this.hasPassword){

        // here we are generating the private and public keys, encrypt the private key, and convert the public key to base64 format
      const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
      let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
      let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
      let hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
      // saving the private and public key to the user profile and the localstorage, and then we add the recipient key
      this.localstorage.setPrivateKey(base64PrivateKey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPublicKey(base64PublicKey);
      let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true};
      this.user.updateProfile(data, this.localstorage.getEmail())
      .subscribe(async (res: any)=>{
        this.localstorage.setUser(JSON.stringify(res.user));
        this.openSnackBar("Master Password added successfully!");
        this.getRecoveryKey()
      }); 
    }
  }

  async save(){
    // if we are here, then the user confirmed the master password change, and we gonna proceed to make the changes
    // changes consist on reencrypting the private key of the user, and all the ownerkeys related to standard secure containers
    // so we decrypt all the keys using the current password, and encrypt them using the new master password
        try{
          this.disabled = true;
          let hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim());
          this.localstorage.setMasterPassword(hashedMasterPassword);
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
          let privateKeyEncrypted = await this.encryptDecrypt.encryptData(this.localstorage.getPrivateKey(), binarykey);
          let data = {privateKey: privateKeyEncrypted};
          this.user.updateProfile(data, this.localstorage.getEmail())
              .subscribe( (res: any)=>{ 
                  this.localstorage.setUser(JSON.stringify(res.user));
                  
                  // update own  & shared containers

                  this.dexieService.getOwnContainers().then(async (data: any)=>{
                    let containersToUpdate = [];

                    let d = data;

                    for(const [index, cont] of d.entries()){
                      if(cont.ownerEncryptionMode===1){
                          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(cont.ownerKey).data), await this.encryptDecrypt.getPBKDF2Hash1M(this.currentPassword.trim()));
                          let binary = this.encryptDecrypt.bufferToBinary(decrypted);
                          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim()));
                          d[index] = {...d[index], ownerKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedOwnerKey: binary, decrypted: true};
                          containersToUpdate.push({containerID: cont.id, ownerKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))});
                      }
                    }

                    // update local own containers
                    this.dexieService.setOwnContainers(d);

                    this.dexieService.getSharedContainers().then(async (data: any)=>{
                      let containersToUpdate2 = [];

                      let d2 = data;

                      for(const [index, cont] of d2.entries()){
                        if(cont.recipientEncryptionMode==='-'){
                            if(cont.recipientKey.includes('type') && cont.recipientKey.includes('Buffer') && cont.recipientKey.includes('data')){
                                let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(cont.recipientKey).data), await this.encryptDecrypt.getPBKDF2Hash1M(this.currentPassword.trim()));
                                let binary = this.encryptDecrypt.bufferToBinary(decrypted);
                                let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.currentPassword.trim()));
                                d2[index] = {...d2[index], recipientKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedRecipientKey: binary, decrypted: true};
                                containersToUpdate2.push({containerID: cont.id, key: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))});
                            }else{
                                const decryptedData = await this.encryptDecrypt.decryptDataRSA(cont.recipientKey, JSON.parse(atob(this.localstorage.getPrivateKey())));
                                const bufferKey = this.encryptDecrypt.binaryToBuffer(decryptedData);
                                let encryptedKey = await this.encryptDecrypt.encryptKey(bufferKey, this.localstorage.getMasterPassword());
                                d2[index] = {...d2[index], recipientKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedRecipientKey: decryptedData, decrypted: true};
                                containersToUpdate2.push({containerID: cont.id, key: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))});
                            }
                        }
                      }

                      // update local shared containers
                      this.dexieService.setSharedContainers(d2);

                      // update in the database
                      this.container.updateKeys({own: containersToUpdate, shared: containersToUpdate2}, this.localstorage.getEmail())
                            .subscribe((res)=> {
                                this.openSnackBar('Your Keys updated successfully!');
                                this.currentPassword = '';
                                this.password = '';
                                this.confirmPassword = '';
                                this.disabled = false;
                                const recoveryKey = this.encryptDecrypt.generateSecureRandomString(32);
                                this.setRecoveryKey(recoveryKey, 'reset');
                                this.dialog.closeAll();
                            });
                    });
                });          
        }); 
      }catch(err){
        this.disabled = false;
        this.waitingText = '';
        this.dialog.closeAll();
        this.localstorage.removeMasterPassword();
        this.localstorage.removePrivateKey();
        this.localstorage.removePublicKey();
        this.openSnackBar('Wrong current master password!');
      }
    }

    async setRecoveryKey(recoveryKey, from){
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(recoveryKey)));
        const encryptedMasterPassword = await this.encryptDecrypt.encryptData(this.localstorage.getMasterPassword(), binarykey);
        this.hasRecoveryKey = true;
        this.user.updateProfile({recoveryKey: encryptedMasterPassword}, this.localstorage.getEmail())
          .subscribe({
            next: (res) => {
              let newUser = {...JSON.parse(this.localstorage.getUser()), recoveryKey: encryptedMasterPassword};
              this.localstorage.setUser(JSON.stringify(newUser));
              this.confirm = false;
              this.hasRecoveryKey = true;
              this.disabled = false;
              this.recoveryKey = recoveryKey;
              if(from==='reset') {
                this.firstRK = false;                                       
                this.dialog.closeAll();
              }
              this.openSnackBar(`Recovery Key ${from==='reset' ? 'Updated' : 'Added'} Successfully!`);
              this.dialog.open(this.recoveryKeyPopup, {disableClose: true, width: '450px'});
            }, 
            error: (err) => { 
              this.openSnackBar('Error adding the recovery key!');
            }
          });
    }

    async getRecoveryKey(){
      const recoveryKey = this.encryptDecrypt.generateSecureRandomString(32);
      if(this.localstorage.getMasterPassword()){
        this.setRecoveryKey(recoveryKey, '');
      }else{
          this.dialog.open(this.masterPasswordDialog).afterClosed().subscribe(async (result) => {
            if(this.confirm){
                this.setRecoveryKey(recoveryKey, '');
            }else{
                this.openSnackBar('Master password not verified!');
            }
          });
      }
    }

    async updateRSAKeysForUsersThatHasAlreadyMP(userData: any){
      if(!userData.rsaKeysUpdated){
          const {extractedPublicKey, extractedPrivateKey} = await this.encryptDecrypt.generateRSAKeys(); 
          let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
          let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
          this.localstorage.setPublicKey(base64PublicKey);
          this.localstorage.setPrivateKey(base64PrivateKey);
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
          let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
          let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true};
            this.user.updateProfile(data, this.localstorage.getEmail())
            .subscribe(async (res: any)=>{
              this.localstorage.setUser(JSON.stringify(res.user)); 
            }); 
      }else{
        // do nothing
      }
    }

    async verifyMasterPassword(){
      try{
        this.disabled = true;
        let hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
        let userData = JSON.parse(this.localstorage.getUser());
        let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
        let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
        this.localstorage.setMasterPassword(hashedMasterPassword);
        this.localstorage.setPrivateKey(privateKeyDecrypted);
        this.localstorage.setPublicKey(userData['publicKey']);
        await this.updateRSAKeysForUsersThatHasAlreadyMP(userData);
        this.confirm = true;
        this.dialog.closeAll();
        
      }catch(err){
        this.disabled = false;
        this.confirm = false;
        this.localstorage.removeMasterPassword();
        this.localstorage.removePrivateKey();
        this.localstorage.removePublicKey();
      }
    }

    async resetMasterPassword(){
        let userData = JSON.parse(this.localstorage.getUser());
        // to decrypt the key in the database using the recovery key entered by the user to get the master password hashed
        let binarykeyForRecoveryKey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(await this.encryptDecrypt.getPBKDF2Hash1M(this.recoveryKeyValue.trim())));
        const decryptedMasterPassword = await this.encryptDecrypt.decryptData(userData['recoveryKey'], binarykeyForRecoveryKey);

        // now we gonna verify if the master password hashed is the right one, we gonna decrypt the private key
        let currentBinaryKey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(decryptedMasterPassword));
        let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], currentBinaryKey);
        this.localstorage.setPrivateKey(privateKeyDecrypted);
        this.localstorage.setPrivateKey(userData['publicKey']);
        await this.updateRSAKeysForUsersThatHasAlreadyMP(userData);

        // now if all is good we gonna ask for a new master password
        this.dialog.open(this.newMasterPasswordDialog, { width: '400px', disableClose: true }).afterClosed().subscribe(async (result) => {
            if(this.confirm){
              try{
                  this.disabled = true;
                  let hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim());
                  this.localstorage.setMasterPassword(hashedMasterPassword);
                  let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
                  let privateKeyEncrypted = await this.encryptDecrypt.encryptData(this.localstorage.getPrivateKey(), binarykey);
                  let data = {privateKey: privateKeyEncrypted};
                  this.user.updateProfile(data, this.localstorage.getEmail())
                      .subscribe( (res: any)=>{ 
                          this.localstorage.setUser(JSON.stringify(res.user));
                          
                          // update own & shared containers

                          this.dexieService.getOwnContainers().then(async (data: any)=>{
                            let containersToUpdate = [];

                            let d = data;
                            
                            for(const [index, cont] of d.entries()){
                                if(cont.ownerEncryptionMode===1){
                                    let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(cont.ownerKey).data), decryptedMasterPassword);
                                    let binary = this.encryptDecrypt.bufferToBinary(decrypted);
                                    let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.password.trim()));
                                    d[index] = {...d[index], ownerKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))};
                                    containersToUpdate.push({containerID: cont.id, ownerKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedOwnerKey: binary, decrypted: true});
                                }
                            }
                            // update local own containers
                            this.dexieService.setOwnContainers(d);

                            this.dexieService.getSharedContainers().then(async (data: any)=>{
                              let containersToUpdate2 = [];

                              let d2 = data;

                              for(const [index, cont] of d2.entries()){
                                  if(cont.recipientEncryptionMode==='-'){
                                      if(cont.recipientKey.includes('type') && cont.recipientKey.includes('Buffer') && cont.recipientKey.includes('data')){
                                          let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(cont.recipientKey).data), decryptedMasterPassword);
                                          let binary = this.encryptDecrypt.bufferToBinary(decrypted);
                                          let encryptedKey = await this.encryptDecrypt.encryptKey(decrypted, decryptedMasterPassword);
                                          d2[index] = {...d2[index], recipientKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedRecipientKey: binary, decrypted: true};
                                          containersToUpdate2.push({containerID: cont.id, key: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))});
                                      }else{
                                          const decryptedData = await this.encryptDecrypt.decryptDataRSA(cont.recipientKey, JSON.parse(atob(this.localstorage.getPrivateKey())));
                                          const bufferKey = this.encryptDecrypt.binaryToBuffer(decryptedData);
                                          let encryptedKey = await this.encryptDecrypt.encryptKey(bufferKey, this.localstorage.getMasterPassword());
                                          d2[index] = {...d2[index], recipientKey: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey)), decryptedRecipientKey: decryptedData, decrypted: true};
                                          containersToUpdate2.push({containerID: cont.id, key: JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey))});
                                      }
                                  }
                              }

                              // update local shared containers
                              this.dexieService.setSharedContainers(d2);

                              // update in the database
                              this.container.updateKeys({own: containersToUpdate, shared: containersToUpdate2}, this.localstorage.getEmail())
                                    .subscribe((res)=> {
                                      this.openSnackBar('Your Keys updated successfully!');
                                      this.currentPassword = '';
                                      this.password = '';
                                      this.confirmPassword = '';
                                      this.recoveryKeyValue = '';
                                      this.disabled = false;
                                      this.dialog.closeAll();
                                      const recoveryKey = this.encryptDecrypt.generateSecureRandomString(32);
                                      this.setRecoveryKey(recoveryKey, 'reset');
                                  });
                            });
                        });          
                }); 
              }catch(err){
                this.disabled = false;
                this.waitingText = '';
                this.dialog.closeAll();
                this.localstorage.removeMasterPassword();
                this.localstorage.removePrivateKey();
                this.localstorage.removePublicKey();
              }
            }else{
                this.openSnackBar('Master password not valid!');
            }
        });
    }

    addNewMasterPassword(){
      if(this.password.trim()!==this.confirmPassword.trim()){
          this.openSnackBar("Passwords don't match! Please try again.");
          this.confirm = false;
      }else if(this.password.trim().length<6){
            this.openSnackBar("Master Password should be at least 6 characters!");
            this.confirm = false;
      }else{
        this.confirm = true;
        this.dialog.closeAll();
      }
    }

    openEnterRecoveryKeyDialog(){
        this.dialog.open(this.enterRecoveryKeyDialog, { width: '400px' });
    }

    copyKey(){
        this.clipboard.copy(this.recoveryKey);
        this.closeButton = true;
        this.openSnackBar('Recovery Key Copied!');
    }
}
