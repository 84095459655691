import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  loading: any;
  loading$ = new Subject<any>();

  ownContainers: any;
  ownContainers$ = new Subject<any>();

  sharedContainers: any;
  sharedContainers$ = new Subject<any>();

  deadManSwitchContainers: any;
  deadManSwitchContainers$ = new Subject<any>();

  homeData: any;
  homeData$ = new Subject<any>();

  containerContentData: any;
  containerContentData$ = new Subject<any>();

  ownNotebooks: any;
  ownNotebooks$ = new Subject<any>();

  sharedNotebooks: any;
  sharedNotebooks$ = new Subject<any>();

  ownPasswords: any;
  ownPasswords$ = new Subject<any>();

  sharedPasswords: any;
  sharedPasswords$ = new Subject<any>();


  selectedPasswordData: any;
  selectedPasswordData$ = new Subject<any>();

  selectedNotebookData: any;
  selectedNotebookData$ = new Subject<any>();

  selectedNoteData: any;
  selectedNoteData$ = new Subject<any>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.loading$.subscribe((value) => {
      this.loading = value;
    });

    this.ownContainers$.subscribe((value) => {
      this.ownContainers = value;
    });

    this.sharedContainers$.subscribe((value) => {
      this.sharedContainers = value;
    });

    this.deadManSwitchContainers$.subscribe((value) => {
      this.deadManSwitchContainers = value;
    });

    this.homeData$.subscribe((value) => {
      this.homeData = value;
    });

    this.containerContentData$.subscribe((value) => {
      this.containerContentData = value;
    });

    this.ownNotebooks$.subscribe((value) => {
      this.ownNotebooks = value;
    });

    this.sharedNotebooks$.subscribe((value) => {
      this.sharedNotebooks = value;
    });

    this.ownPasswords$.subscribe((value) => {
      this.ownPasswords = value;
    });

    this.sharedPasswords$.subscribe((value) => {
      this.sharedPasswords = value;
    });

    this.selectedNotebookData$.subscribe((value) => {
      this.selectedNotebookData = value;
    });

    this.selectedPasswordData$.subscribe((value) => {
      this.selectedPasswordData = value;
    });

    this.selectedNoteData$.subscribe((value) => {
      this.selectedNoteData = value;
    });

    this.loading$.next(false);
    this.ownContainers$.next([]);
    this.sharedContainers$.next([]);
    this.deadManSwitchContainers$.next([]);
    this.homeData$.next([]);
    this.containerContentData$.next([]);
    this.ownPasswords$.next([]);
    this.sharedPasswords$.next([]);
    this.ownNotebooks$.next([]);
    this.sharedNotebooks$.next([]);
    this.selectedNotebookData$.next({});
    this.selectedPasswordData$.next({});
    this.selectedNoteData$.next({});
  }

  setLoading(val: boolean) {
    this.loading$.next(val);
    return this.loading;
  }

  setIcon(icon: any) {
    if (icon instanceof Object) {
      if (icon.data.includes('assets/images/predefined-icons') === false && icon.data.includes('https://') === false  && icon.data.includes(';base64,') === false) {
        let my_data = new Uint8Array(JSON.parse(icon.data).data);
        let string_char = my_data.reduce((data, byte) => { return data + String.fromCharCode(byte) }, '');
        let base64String = btoa(string_char);
        return (icon.type.includes('svg') || icon.name.includes('svg')) ? this.sanitizer.bypassSecurityTrustUrl('data:' + 'image/svg+xml' + ';base64,' + base64String)['changingThisBreaksApplicationSecurity'] : this.sanitizer.bypassSecurityTrustUrl('data:' + icon.type + ';base64,' + base64String)['changingThisBreaksApplicationSecurity'];
      } else {
        return icon.data;
      }
    } else {
      return icon;
    }
  }

  setMemory(memory: any) {
    return { unit: JSON.parse(memory).unit, memory: Number(JSON.parse(memory).memory) };
  }

  setRecipients(recipients) {
    return recipients.map((el) => el.email);
  }

  setContainer(container: any) {
    let usedMemory = this.setMemory(container?.usedMemory);
    let icon = this.setIcon(container?.icon);
    let recipients = this.setRecipients(container?.recipients);
    return { decrypted: false, ...container, usedMemory, icon: container?.icon?.id, iconData: icon, recipients, updatedAt: new Date(Date.parse(container?.updatedAt)), ownerEncryptionMode: Number(container?.ownerEncryptionMode), decryptedOwnerKey: '', decryptedRecipientKey: '', activeBackUpPerson: container?.active, backUpPersonEncryptionMode: Number(container?.backUpPersonEncryptionMode), decryptedBackUpPersonKey: '' };
  }

  setOwnNotebooks(data: any) {
    this.ownNotebooks$.next(data);
    return this.ownNotebooks;
  }

  setSharedNotebooks(data: any) {
    this.sharedNotebooks$.next(data);
    return this.sharedNotebooks;
  }

  setOwnPasswords(data: any) {
    this.ownPasswords$.next(data);
    return this.ownPasswords;
  }

  setSharedPasswords(data: any) {
    this.sharedPasswords$.next(data);
    return this.sharedPasswords;
  }

  setOwnContainers(containers: any) {
    this.ownContainers$.next(containers);
    return this.ownContainers;
  }

  setSharedContainers(containers: any) {
    this.sharedContainers$.next(containers);
    return this.sharedContainers;
  }

  setDeadManSwitchContainers(containers: any) {
    this.deadManSwitchContainers$.next(containers);
    return this.deadManSwitchContainers;
  }

  setHomeData(data: any) {
    this.homeData$.next(data);
    return this.homeData;
  }

  setContainerContentData(data: any) {
    this.containerContentData$.next(data);
    return this.containerContentData;
  }

  setSelectedPasswordData(data: any) {
    this.selectedPasswordData$.next(data);
    return this.selectedPasswordData;
  }

  setSelectedNotebookData(data: any) {
    this.selectedNotebookData$.next(data);
    return this.selectedNotebookData;
  }

  setSelectedNoteData(data: any) {
    this.selectedNoteData$.next(data);
    return this.selectedNoteData;
  }

  getContainerData(passwords: any, notebooks: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/get-container-data`, { passwords, notebooks });
  }

  getElementsSize(passwords: any, notebooks: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/get-elements-size`, { passwords, notebooks });
  }

  getRecipientsData(recipients: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/get-recipients-data`, { recipients });
  }

  addRecipient(recipientEmail: any, containerID: any, recipientKey: any, encryptionMode: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/add-recipient`, { recipientEmail, containerID, recipientKey, encryptionMode });
  }

  removeRecipient(recipientEmail: any, containerID: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/remove-recipient`, { recipientEmail, containerID });
  }

  updateContainerInfo(data: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/update-container-info`, { data });
  }

  updateContainerTimer(data: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/update-container-timer`, { data });
  }

  addContainer(data: any) {
    return this.http.post(`${environment.apiUrl}/container-v2/create-container`, { data });
  }

  moveToTrashContainer(id: any) {
    return this.http.delete(`${environment.apiUrl}/container-v2/move-to-trash-container?id=${id}`);
  }

  updateKeys(data: any, email: any) {
    return this.http.put(`${environment.apiUrl}/keys/update-keys`, { data, email });
  }

  updateOwnerKey(containerID: any, key: any) {
    return this.http.put(`${environment.apiUrl}/keys/update-owner-key`, { containerID, key });
  }

  updateRecipientKey(containerID: any, key: any, email: any) {
    return this.http.put(`${environment.apiUrl}/keys/update-recipient-key`, { containerID, key, email });
  }

  updateBackupPersonKey(containerID: any, key: any) {
    return this.http.put(`${environment.apiUrl}/keys/update-backup-person-key`, { containerID, key });
  }

  updateContainerType(containerID: any, ownerEncryptionMode: any, recipientEncryptionMode: any, ownerKey: any, recipientKey: any) {
    return this.http.put(`${environment.apiUrl}/keys/update-container-type`, { containerID, ownerEncryptionMode, recipientEncryptionMode, ownerKey, recipientKey });
  }
}
