<div *ngIf="ownContainers.length==0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="mt-36">
    <img
        [src]="dark ? 'assets/images2/dashboard/sharing-center/no-data-found-dark.svg' : 'assets/images2/dashboard/sharing-center/no-data-found-light.svg'" />
    <div class="not-found-text">No containers created yet, please try to add some container, and then activate the
        backup person from here!</div>
</div>

<div fxLayout="column" fxLayoutGap="20px" *ngIf="ownContainers.length!=0">
    <div fxLayout="row" fxLayoutAlign="end center">
        <div class="group">
            <img id="search-icon" class="icon" src="assets/images/dashboard/search-icon.svg" alt="search-icon" />
            <input placeholder="Search" class="input" [(ngModel)]="containerSearch" autocomplete="off" />
        </div>
    </div>
    <div>
        <div class="table-container">
            <table aria-describedby="list of own containers">
                <thead>
                    <tr>
                        <th>Container Name</th>
                        <th>Recipient</th>
                        <th>Status</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let row of ownContainers | searchContainer :containerSearch; let i = index">
                        <td>{{row.name}}</td>
                        <td class="text-center">
                            <input autocomplete="off" type="text" class="recipient-email"
                                [value]="row.backUpPerson ? row.backUpPerson : ''" (keyup)="onKey($event)"
                                placeholder="Enter recipient email" />
                        </td>
                        <td>
                            <button *ngIf="row.deadManSwitch" class="add-item-button"
                                (click)="updateContainer(i, false)" [disabled]="disabledButton"
                                [ngClass]="{'opaque': disabledButton, '': !disabledButton}">Deactivate</button>
                            <button *ngIf="!row.deadManSwitch" class="add-item-button"
                                (click)="updateContainer(i, true)">Activate</button>
                        </td>
                        <td (click)="openEditMessage(i)">
                            <button *ngIf="row.deadManSwitch" class="add-item-button" (click)="openEditMessage(i)"
                                [disabled]="disabledButton"
                                [ngClass]="{'opaque': disabledButton, '': !disabledButton}">Edit</button>
                            <button *ngIf="!row.deadManSwitch" class="add-item-button" [disabled]="true"
                                matTooltip="No recipient to edit">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <ng-template #passphraseDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
                </div>
                <div class="pop-ups-title">Verify Passphrase</div>
                <div fxLayout="column" fxLayoutGap="25px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="dialog-label">Enter Passphrase</span>
                        <div fxLayout="row">
                            <input [type]="show ? 'text' : 'password'" class="settings-input"
                                placeholder="Enter Passphrase" [(ngModel)]="passphrase" autocomplete="off"
                                (keydown.enter)="verifyPassphrase()" />
                            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                    (click)="isPassword()" class="pointer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" matDialogClose (click)="verifyPassphrase()">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <ng-template #masterPasswordDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
                </div>
                <div class="pop-ups-title">Verify Master Password</div>
                <div fxLayout="column" fxLayoutGap="25px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="dialog-label">Enter Master Password</span>
                        <div fxLayout="row">
                            <input [type]="show ? 'text' : 'password'" class="settings-input"
                                placeholder="Enter Password" [(ngModel)]="password" autocomplete="off" />
                            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                                <img alt="show-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                    (click)="isPassword()" class="pointer" />
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" matDialogClose (click)="verifyMasterPassword()">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to verify the hardware key of the container -->
    <ng-template #hardwareKeyDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/hardware-key.svg" />
                </div>
                <div class="pop-ups-title"> Verify your security Key</div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <input autocomplete="off" class="settings-input" placeholder="Please connect your Key and touch it"
                        [(ngModel)]="hardwareKey" (keydown.enter)="verifyHardwareKey()" />
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" cdkFocusInitial (click)="verifyHardwareKey()">Verify</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to select the timer of the container -->
    <ng-template #timerDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase"
                        [src]="dark ? 'assets/images/dashboard/expiration-dark.svg' : 'assets/images/dashboard/expiration.svg'" />
                </div>
                <div class="pop-ups-title"> Expiration</div>

                <div class="select-container">
                    <label for="timer" class="dialog-label">
                        Set your timer
                    </label>
                    <mat-select name="timer" id="timer" class="settings-select" panelClass="typeSelectPanelClass"
                        [(ngModel)]="timer"><!--(selectionChange)="selectTimer($event)"-->
                        <mat-option *ngFor="let t of timerList" [value]="t.value">
                            {{t.name}}
                        </mat-option>
                    </mat-select>
                    <div class="custom-icon sm:flex hidden">
                        <img src="assets/images/dashboard/timer.svg" alt="timer" />
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <div fxFlex class="select-container">
                        <label for="reminder" class="dialog-label">
                            Reminder
                        </label>

                        <mat-select name="reminder" id="reminder" class="settings-select"
                            panelClass="typeSelectPanelClass"
                            [(ngModel)]="reminder"><!-- (selectionChange)="selectReminder($event)"-->
                            <mat-option *ngFor="let r of reminderList" [value]="r.value">
                                {{r.name}}
                            </mat-option>
                        </mat-select>
                        <div class="custom-icon sm:flex hidden">
                            <img src="assets/images/dashboard/reminder.svg" alt="reminder" />
                        </div>
                    </div>
                    <div fxFlex class="select-container">
                        <label for="frequency" class="dialog-label">
                            Frequency
                        </label>
                        <mat-select name="frequency" id="frequency" class="settings-select"
                            panelClass="typeSelectPanelClass"
                            [(ngModel)]="frequency"><!--(selectionChange)="selectFrequency($event)"-->
                            <mat-option *ngFor="let f of frequencyList" [value]="f.value">
                                {{f.name}}
                            </mat-option>
                        </mat-select>
                        <div class="custom-icon sm:flex hidden">
                            <img src="assets/images/dashboard/frequency.svg" alt="frequency" />
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
                    <button class="pop-ups-cancel-button" [mat-dialog-close]="true" cdkFocusInitial>Cancel</button>
                    <button class="pop-ups-button" cdkFocusInitial (click)="openRecipientEncryptionMode()">Next</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to select how the recipient will access of the container -->
    <ng-template #recipientEncryptionModeDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div class="pop-ups-subtitle">Choose how you want "{{recipient}}" access to this container</div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
                    <div [ngClass]="{'dialog-box': !activeP, 'dialog-box-active': activeP}" fxLayout="row"
                        fxLayoutAlign="center center" fxLayoutGap="20px" (click)="toggleActive('P')">
                        <div>
                            <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
                        </div>
                        <div fxLayout="column" fxLayoutGap="20px">
                            <div [ngClass]="{'dialog-box-title': !activeP, 'dialog-box-title-active': activeP}">
                                They will enter a passphrase
                            </div>
                            <div class="dialog-box-description">
                                All your data will be encrypted using a passphrase and they will have to enter the
                                password to access your data.
                            </div>
                            <div class="dialog-box-subdescription" fxLayout="row" fxLayoutAlign="start start"
                                fxLayoutGap="5px">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.99998 5.33325V7.99992M7.99998 10.6666H8.00665M14.6666 7.99992C14.6666 11.6818 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                            [attr.stroke]="iconColor" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span>
                                    If they do not have the passphrase or lose it, data will be inaccessible
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <svg *ngIf="activeP" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                                <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                            </svg>
                            <svg *ngIf="!activeP" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle *ngIf="dark" cx="12" cy="12" r="11" stroke="#5E6A74" stroke-width="2" />
                                <circle *ngIf="!dark" cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33"
                                    stroke-width="2" />
                            </svg>
                        </div>
                    </div>
                    <div [ngClass]="{'dialog-box': !activeH, 'dialog-box-active': activeH}" fxLayout="row"
                        fxLayoutAlign="center center" fxLayoutGap="20px" (click)="toggleActive('H')">
                        <div>
                            <img alt="hardware-key" src="assets/images/dashboard/pop-ups/hardware-key.svg" />
                        </div>
                        <div fxLayout="column" fxLayoutGap="20px">
                            <div [ngClass]="{'dialog-box-title': !activeH, 'dialog-box-title-active': activeH}">
                                I’ll setup a YubiKey for them
                            </div>
                            <div class="dialog-box-description">
                                All your data will be encrypted using a hardware token you will provide. They will have
                                to insert the hardware token (YubiKey only) to access the data.
                            </div>
                            <div class="dialog-box-subdescription" fxLayout="row" fxLayoutAlign="start start"
                                fxLayoutGap="5px">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.99998 5.33325V7.99992M7.99998 10.6666H8.00665M14.6666 7.99992C14.6666 11.6818 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                            [attr.stroke]="iconColor" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span>
                                    If they lose the key, data will be inaccessible.
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <svg *ngIf="activeH" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#286286" />
                                <path d="M16.6666 8.5L10.2499 14.9167L7.33325 12" stroke="white" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#286286" />
                            </svg>
                            <svg *ngIf="!activeH" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle *ngIf="dark" cx="12" cy="12" r="11" stroke="#5E6A74" stroke-width="2" />
                                <circle *ngIf="!dark" cx="12" cy="12" r="11" stroke="#3E3E3E" stroke-opacity="0.33"
                                    stroke-width="2" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" cdkFocusInitial (click)="choose()">Next</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to define the recipient passphrase of the container -->
    <ng-template #recipientPassphraseDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/passphrase.svg" />
                </div>
                <div class="pop-ups-title">Add a passphrase</div>
                <div fxLayout="column" fxLayoutGap="25px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="dialog-label">Enter Passphrase</span>
                        <div fxLayout="row" class="relative" style="position: relative;">
                            <input [type]="show ? 'text' : 'password'" id="passphrase1" name="passphrase1"
                                #passphrase1="ngModel" class="settings-input" placeholder="Enter Passphrase"
                                [(ngModel)]="passphrase" (ngModelChange)="onChange($event)" autocomplete="off" />
                            <img class="eye-div" alt="show-hide"
                                [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                (click)="isPassword()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                                class="pointer" />
                        </div>
                    </div>
                    <div class="error" *ngIf="errorLength && (passphrase1.dirty || passphrase1.touched)">
                        Passphrase should contain minimum 8 characters
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <span class="dialog-label">Enter Passphrase Again</span>
                        <div fxLayout="row" class="relative" style="position: relative;">
                            <input [type]="show ? 'text' : 'password'" id="passphrase2" name="passphrase2"
                                #passphrase2="ngModel" class="settings-input" placeholder="Enter Passphrase Again"
                                [(ngModel)]="confirmPassphrase" (ngModelChange)="onChangeConfirm($event)"
                                autocomplete="off" />
                            <img class="eye-div" alt="show-hide"
                                [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg'"
                                (click)="isPassword()"
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                                class="pointer" />
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="confirmPassphrase.length>0 && errorLength && (passphrase2.dirty || passphrase2.touched)">
                        Please review your passphrase structure!
                    </div>
                    <div class="error"
                        *ngIf="passphrase.length>0 && confirmPassphrase.length>0 && errorMatch && !errorLength && (passphrase2.dirty || passphrase2.touched)">
                        Passphrases doesn't match!
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" matDialogClose (click)="addPassphrase()">Add</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to define the recipient hardwarekey of the container -->
    <ng-template #recipientHardwareKeyDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img alt="passphrase" src="assets/images/dashboard/pop-ups/hardware-key.svg" />
                </div>
                <div class="pop-ups-title"> Add a security Key</div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <input autocomplete="off" class="settings-input" placeholder="Please connect your Key and touch it"
                        [(ngModel)]="hardwareKey" />
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" cdkFocusInitial (click)="addHardwareKey()">Add</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to let the user enter any message he wants to tell to the designed recipient, including the passphrase, or the hardware location if needed -->
    <ng-template #recipientMessageDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div class="pop-ups-subtitle">
                    Enter a message you want to tell "{{recipient}}", this message will be attached to the email that
                    will be sent to them in case you are not available.
                    You can communicate the passphrase or the location of the key in this message.
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <textarea rows="10" class="settings-input" placeholder="Write a message for your recipient here."
                        [(ngModel)]="message" (keydown.enter)="add()"></textarea>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button class="pop-ups-button" cdkFocusInitial (click)="add()" [disabled]="disabledButton"
                        [ngClass]="{'opaque': disabledButton, '': !disabledButton}">Add</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

    <!-- This pop up is to let the user enter any message he wants to tell to the designed recipient, including the passphrase, or the hardware location if needed -->
    <ng-template #recipientEditMessageDialog>
        <mat-dialog-content>
            <div class="dialog-content" fxLayout="column" fxLayoutGap="30px">
                <div class="pop-ups-subtitle">
                    Edit the message that will be sent to: {{recipientOfEdit}}
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <textarea rows="10" class="settings-input" placeholder="Write a message for your recipient here."
                        [(ngModel)]="messageToEdit" (keydown.enter)="editMessage()"></textarea>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
                    <button class="pop-ups-cancel-button" [mat-dialog-close]="true" cdkFocusInitial
                        [disabled]="disabledButton"
                        [ngClass]="{'opaque': disabledButton, '': !disabledButton}">Cancel</button>
                    <button class="pop-ups-button" cdkFocusInitial (click)="editMessage()" [disabled]="disabledButton"
                        [ngClass]="{'opaque': disabledButton, '': !disabledButton}">Edit</button>
                </div>
            </div>
        </mat-dialog-content>
    </ng-template>

</div>