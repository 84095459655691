import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchContainer'
})
export class SearchContainerPipe implements PipeTransform {

    transform(value: any, searchQuery: string):any {
        if(!searchQuery){
            return value
        }

        return value.filter((item: any) => {
            return item.name?.toLowerCase().includes(searchQuery.trim().toLowerCase()) 
                    || item.description?.toLowerCase().includes(searchQuery.trim().toLowerCase())
                    || item.passwords?.findIndex((itemPass)=>this.containsPass(itemPass, searchQuery))!=-1
                    || item.notebooks?.findIndex((itemNotebook)=>this.containsNotebooks(itemNotebook, searchQuery))!=-1;
        });
    }

    containsPass(itemPass, searchQuery){
        return itemPass.name.toLowerCase().includes(searchQuery.trim().toLowerCase()) || 
                itemPass.url.toLowerCase().includes(searchQuery.trim().toLowerCase()) || 
                itemPass.userName.toLowerCase().includes(searchQuery.trim().toLowerCase());
                // || itemPass.password.toLowerCase().includes(searchQuery.trim().toLowerCase());
    }

    containsNotebooks(itemNotebook, searchQuery){
        return itemNotebook.name.toLowerCase().includes(searchQuery.trim().toLowerCase());
        //  ||  itemNotebook.notes.findIndex((itemNote)=>this.containsNotes(itemNote, searchQuery))!=-1;
    }

    // containsNotes(itemNote, searchQuery){
    //     return itemNote.title.toLowerCase().includes(searchQuery.trim().toLowerCase()) || 
    //             itemNote.markdownData.toLowerCase().includes(searchQuery.trim().toLowerCase()) || 
    //             itemNote.wysiwygData.data.toLowerCase().includes(searchQuery.trim().toLowerCase());
    // }
}
