import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { UserService } from 'src/app/services/user/user.service';
import { MediaService } from 'src/app/services/media/media.service';

@Component({
  selector: 'app-list-containers',
  templateUrl: './list-containers.component.html',
  styleUrls: ['./list-containers.component.scss'],
})

export class ListContainersComponent {
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('passphrase') passphraseDialog: any;
  @ViewChild('masterPassword') masterPasswordDialog: any;
  @ViewChild('hardwareKey') hardwareKeyDialog: any;
  @ViewChild('questionAnswer') questionAnswerDialog: any;
  @ViewChild('loadingDialog') loadingDialog: any;
  @ViewChild('editDialog') editDialog: any;
  @ViewChild('shareDialog') shareDialog: any;
  @ViewChild('IconsDialog') IconsDialog: TemplateRef<any>; 
  @ViewChild('assignmentDialog') assignmentDialog: TemplateRef<any>;
  @ViewChild('addPassphraseDialog') addPassphraseDialog: TemplateRef<any>;
  @ViewChild('addHardwareKeyDialog') addHardwareKeyDialog: TemplateRef<any>;
  @ViewChild('addAnswerQuestionDialog') addAnswerQuestionDialog: TemplateRef<any>;

  iconsArray = [
    {icon: 832, src: 'assets/images/predefined-icons/social-media.svg', name: 'Social Media'},
    {icon: 833, src: 'assets/images/predefined-icons/work.svg', name: 'Work'},
    {icon: 834, src: 'assets/images/predefined-icons/home.svg', name: 'Home'},
    {icon: 835, src: 'assets/images/predefined-icons/emails.svg', name: 'Emails'},
    {icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg', name: 'Credit Cards'},
    {icon: 837, src: 'assets/images/predefined-icons/bank.svg', name: 'Bank'},
    {icon: 838, src: 'assets/images/predefined-icons/admission-tickets.svg', name: 'Admission Tickets'},
    {icon: 839, src: 'assets/images/predefined-icons/backpack.svg', name: 'Backpacks'},
    {icon: 840, src: 'assets/images/predefined-icons/basketball.svg', name: 'Basketball'},
    {icon: 841, src: 'assets/images/predefined-icons/candy.svg', name: 'Candy'},
    {icon: 843, src: 'assets/images/predefined-icons/chestnut.svg', name: 'Chestnut'},
    {icon: 844, src: 'assets/images/predefined-icons/coffee.svg', name: 'Coffee'},
    {icon: 845, src: 'assets/images/predefined-icons/envelope.svg', name: 'Envelope'},
    {icon: 846, src: 'assets/images/predefined-icons/jack-o-lantern.svg', name: 'Jack O Lantern'},
    {icon: 847, src: 'assets/images/predefined-icons/maple-leaf.svg', name: 'Maple Leaf'},
    {icon: 848, src: 'assets/images/predefined-icons/wood.svg', name: 'Wood'},
    {icon: 849, src: 'assets/images/predefined-icons/moon-cake.svg', name: 'Moon Cake'},
    {icon: 850, src: 'assets/images/predefined-icons/mushroom-2.svg', name: 'Mushroom 2'},
    {icon: 851, src: 'assets/images/predefined-icons/mushroom-3.svg', name: 'Mushroom 3'},
    // {icon: 852, src: 'assets/images/predefined-icons/mushroom.svg', name: 'Mushroom'},
    {icon: 853, src: 'assets/images/predefined-icons/skull.svg', name: 'Skull'},
    {icon: 854, src: 'assets/images/predefined-icons/soccer-ball.svg', name: 'Soccer ball'},
    {icon: 855, src: 'assets/images/predefined-icons/spider-web.svg', name: 'Spider Web'},
    {icon: 856, src: 'assets/images/predefined-icons/spider.svg', name: 'Spider'},
    {icon: 857, src: 'assets/images/predefined-icons/teacup.svg', name: 'Teacup'},
  ];

  dialogRef: any;
  containerRSAEncrypted: any;
  containerToEdit: any;
  preview: any;
  decrypted: any;
  passphraseForRecipientValue = '';
  confirmPassphraseForRecipientValue = '';
  hardwareKeyForRecipientValue = '';
  answerForRecipientValue = '';
  newRecipientEmail = '';
  src = '';
  email = '';
  searchString = '';
  masterPasswordValue = '';
  passphraseValue = '';
  hardwareKeyValue = '';
  answerValue = '';
  question = '3';
  sharing = false;
  confirm = false;
  activeA = true;
  activeB = false;
  activeC = false;
  show = false;
  plan: any;
  isTableView = false;
  selectedDropdown: string | null = null;
  selectedContainer: any;
  key: any;
  id: any;
  indexOfContainer: any;
  type: any;
  disabled = false;
  deletionInProgress = false;
  rsaEncryption = false;
  loadingRecipients = false;
  passwords = [];
  notebooks = [];
  recipients = [];

  get dark(): any {
    return this.theme.dark;
  }

  get ownContainers(): any {
    return this.containerService.ownContainers;
  }

  get sharedContainers(): any {
    return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any {
    return this.containerService.deadManSwitchContainers;
  }

  get allContainers(): any[] {
    return [...this.ownContainers, ...this.sharedContainers, ...this.deadManSwitchContainers];
  }

  get searchValue() {
    this.searchString = this.search.search;
    return this.search.search;
  }

  constructor(private search: SearchService, private media: MediaService, private localstorage: LocalStorageService, private theme: ThemeService, private router: Router, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private sanitizer: DomSanitizer, private userService: UserService, public dialog: MatDialog, private _snackBar: MatSnackBar, private dexieService: DexieService) {
    this.plan = JSON.parse(this.localstorage.getPlan());
    this.email = this.localstorage.getEmail();
  }

  switchView(value) {
    this.isTableView = value;
  }

  toggleDropdown(containerId: string): void {
    if (this.selectedDropdown === containerId) {
      this.selectedDropdown = null;
    } else {
      this.selectedDropdown = containerId;
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', { horizontalPosition: 'center', verticalPosition: 'top', duration: 5000 });
    snackBarRef.onAction().subscribe(() => this._snackBar.dismiss());
  }

  newContainer() {
    if (this.plan['containers'] != "unlimited" && (this.ownContainers.length + this.sharedContainers.length + this.deadManSwitchContainers.length) >= Number(this.plan['containers'])) {
      this.openSnackBar("Your reached the allowed number of containers, please upgrade your plan to add more!");
    } else {
      this.router.navigate(['containers', 'create-container']);
    }
  }

  updateSearchString() {
    this.search.setSearch(this.searchValue);
  }

  cancel() {
    this.dialog.closeAll();
  }

  async selectContainer(cont: any) {
    this.id = cont?.id;
    this.type = cont?.type;
    const [container, decryptedKeyAttribute, encryptionModeAttribute] = await this.dataToUse();
    if (!container) return;

    const decryptedKey = container[decryptedKeyAttribute].length > 0 && container.decrypted;

    if (decryptedKey) {
      // Navigate if already decrypted
      this.router.navigate(['container-content', this.type, this.indexOfContainer]);
      return;
    }

    this.passwords = container.passwords;
    this.notebooks = container.notebooks;

    const encryptionMode = container[encryptionModeAttribute];

    if (!encryptionMode) return;

    if ((encryptionMode === 1 || encryptionMode === '-') && this.localstorage.getMasterPassword()) {
      const decryptedBinary = this.encryptDecrypt.bufferToBinary(await this.encryptDecrypt.decryptKey(this.key, this.localstorage.getMasterPassword()));
      await this.optionBDecryption(this.indexOfContainer, decryptedBinary, this.type);
    } else {
      this.handleSpecialEncryption(encryptionMode);
    }
  }

  async optionBDecryption(index: any, binary: any, type: any) {
    try {

      let updatedContainer = { decrypted: true };

      if (type === 'shared') {
        updatedContainer['decryptedRecipientKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getSharedContainers', 'setSharedContainers');
      } else if (type === 'own') {
        updatedContainer['decryptedOwnerKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getOwnContainers', 'setOwnContainers');
      } else if (type == 'backup') {
        updatedContainer['decryptedBackUpPersonKey'] = binary;
        await this.updateContainerData(index, updatedContainer, type, 'getDeadManSwitchContainers', 'setDeadManSwitchContainers');
      }


    } catch (error) {
      console.error("Error during decryption process:", error);
    }
  }

  // Helper function for updating Dexie and ContainerService
  async updateContainerData(index: any, updatedData: any, type: any, getFn: string, setFn: string) {
    try {
      let data = await this.dexieService[getFn]();
      data[index] = { ...data[index], ...updatedData };
      await this.dexieService[setFn](data);
      this.containerService[setFn](data);
      this.dialog.closeAll();
      this.router.navigate(['container-content', type, index]);
    } catch (error) {
      console.error(`Error updating container data for type: ${type}`, error);
    }
  }

  async dataToUse() {
    this.rsaEncryption = false;
    if (this.type === 'shared') {
      this.indexOfContainer = this.sharedContainers.findIndex((el: any) => el.id === this.id);
      let container = this.sharedContainers[this.indexOfContainer];
      if (container.recipientKey != undefined && container.recipientKey !== null) {
        if (container.recipientKey.includes('type') && container.recipientKey.includes('Buffer') && container.recipientKey.includes('data')) {
          this.key = new Uint8Array(JSON.parse(container.recipientKey).data);
          return [container, 'decryptedRecipientKey', 'recipientEncryptionMode'];
        } else {
          this.rsaEncryption = true;
          this.containerRSAEncrypted = container;

          if (this.localstorage.getMasterPassword()) {
            const decryptedData = await this.encryptDecrypt.decryptDataRSA(this.containerRSAEncrypted.recipientKey, JSON.parse(atob(this.localstorage.getPrivateKey())));
            const bufferKey = this.encryptDecrypt.binaryToBuffer(decryptedData);
            let encrypted = await this.encryptDecrypt.encryptKey(bufferKey, this.localstorage.getMasterPassword());
            let aesKey = this.encryptDecrypt.toBuffer(encrypted);

            this.containerService.updateKeys({ own: [], shared: [{ containerID: this.containerRSAEncrypted.id, key: JSON.stringify(aesKey) }] }, this.localstorage.getEmail())
              .subscribe(async (res) => {
                await this.optionBDecryption(this.indexOfContainer, decryptedData, this.type);
                this.openSnackBar('Your Key updated successfully!');
                this.disabled = false;
                this.rsaEncryption = false;
                this.dialog.closeAll();
              });
            return [null, null, null];

          } else {
            this.dialog.open(this.masterPasswordDialog, { width: '400px' });
            return [null, null, null];
          }
        }
      } else {
        this.openSnackBar("Key not found, it seems that the owner of this container didn't confirmed you as a recipient, please verify with them!");
        return [null, null, null];
      }

    } else if (this.type === 'own') {
      this.indexOfContainer = this.ownContainers.findIndex((el: any) => el.id === this.id);
      let container = this.ownContainers[this.indexOfContainer];
      this.key = new Uint8Array(JSON.parse(container.ownerKey).data);
      return [container, 'decryptedOwnerKey', 'ownerEncryptionMode'];

    } else if (this.type === 'backup') {
      this.indexOfContainer = this.deadManSwitchContainers.findIndex((el: any) => el.id === this.id);
      let container = this.deadManSwitchContainers[this.indexOfContainer];
      this.key = new Uint8Array(JSON.parse(container.backUpPersonKey).data);
      return [container, 'decryptedBackUpPersonKey', 'backUpPersonEncryptionMode'];

    } else {
      return [null, null, null];
    }
  }

  handleSpecialEncryption(encryptionMode) {
    switch (encryptionMode) {
      case "passphrase":
      case 2:
        this.dialog.open(this.passphraseDialog, { width: '400px' });
        break;
      case "hardwareKey":
      case 3:
        this.dialog.open(this.hardwareKeyDialog, { width: '400px' });
        break;
      case "questionAnswer":
        this.dialog.open(this.questionAnswerDialog, { width: '400px' });
        break;
      default:
        this.dialog.open(this.masterPasswordDialog, { width: '400px' });
    }
  }

  async updateRSAKeysForUsersThatHasAlreadyMP(userData: any) {
    if (!userData.rsaKeysUpdated) {
      const { extractedPublicKey, extractedPrivateKey } = await this.encryptDecrypt.generateRSAKeys();
      let base64PublicKey = btoa(JSON.stringify(extractedPublicKey));
      let base64PrivateKey = btoa(JSON.stringify(extractedPrivateKey));
      this.localstorage.setPublicKey(base64PublicKey);
      this.localstorage.setPrivateKey(base64PrivateKey);
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(base64PrivateKey, binarykey);
      let data = { publicKey: base64PublicKey, privateKey: privateKeyEncrypted, rsaKeysUpdated: true };
      this.userService.updateProfile(data, this.localstorage.getEmail())
        .subscribe(async (res: any) => {
          this.localstorage.setUser(JSON.stringify(res.user));
        });
    } else {
      // do nothing
    }
  }

  async verifyMPandReencryption() {
    try {
      this.rsaEncryption = false;
      let userData = JSON.parse(this.localstorage.getUser());
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(userData['publicKey']);
      const decryptedData = await this.encryptDecrypt.decryptDataRSA(this.containerRSAEncrypted.recipientKey, JSON.parse(atob(privateKeyDecrypted)));
      const bufferKey = this.encryptDecrypt.binaryToBuffer(decryptedData);
      let encrypted = await this.encryptDecrypt.encryptKey(bufferKey, this.localstorage.getMasterPassword());
      let aesKey = this.encryptDecrypt.toBuffer(encrypted);
      this.containerService.updateKeys({ own: [], shared: [{ containerID: this.containerRSAEncrypted.id, key: JSON.stringify(aesKey) }] }, this.localstorage.getEmail())
        .subscribe(async (res) => {
          await this.optionBDecryption(this.indexOfContainer, decryptedData, this.type);
          this.openSnackBar('Your Key updated successfully!');
          this.disabled = false;
          this.rsaEncryption = false;
          this.dialog.closeAll();
        });
    } catch (error) {
      this.rsaEncryption = true;
      this.openSnackBar("Wrong master password!");
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
    }
  }

  async verifyMasterPassword() {
    try {
      this.disabled = true;
      let userData = JSON.parse(this.localstorage.getUser());
      const hashedMasterPassword = await this.encryptDecrypt.getPBKDF2Hash1M(this.masterPasswordValue.trim());
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(hashedMasterPassword));
      let privateKeyDecrypted = await this.encryptDecrypt.decryptData(userData['privateKey'], binarykey);
      this.localstorage.setMasterPassword(hashedMasterPassword);
      this.localstorage.setPrivateKey(privateKeyDecrypted);
      this.localstorage.setPublicKey(userData['publicKey']);
      await this.updateRSAKeysForUsersThatHasAlreadyMP(userData);
      let decrypted = await this.encryptDecrypt.decryptKey(this.key, hashedMasterPassword);
      let binary = this.encryptDecrypt.bufferToBinary(decrypted);
      this.disabled = false;
      this.dialog.closeAll();
      await this.optionBDecryption(this.indexOfContainer, binary, this.type);
    } catch (err) {
      this.disabled = false;
      this.openSnackBar('Not authorized!');
      this.localstorage.removeMasterPassword();
      this.localstorage.removePrivateKey();
      this.localstorage.removePublicKey();
    }
  }

  async verifyPassphrase() {
    try {
      this.disabled = true;
      this.decrypted = await this.encryptDecrypt.decryptKey(this.key, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseValue.trim()));
      this.disabled = false;
      this.dialog.closeAll();
      if(this.sharing){
        this.sharing = false;
        this.dialog.open(this.assignmentDialog, {width: '500px'});

      }else{
        let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
        await this.optionBDecryption(this.indexOfContainer, binary, this.type);

      }
    } catch (err) {
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }
  async verifyHardwareKey() {
    try {
      this.disabled = true;
      this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.hardwareKeyValue.trim().slice(0, 12));
      this.disabled = false;
      this.dialog.closeAll();
      if(this.sharing){
        this.sharing = false;
        this.dialog.open(this.assignmentDialog, {width: '500px'});

      }else{
        let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
        await this.optionBDecryption(this.indexOfContainer, binary, this.type);

      }
    } catch (err) {
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }
  async verifyQuestionAnswer() {
    try {
      this.disabled = true;
      this.decrypted = await this.encryptDecrypt.decryptKey(this.key, this.answerValue.trim().toLowerCase());
      let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
      this.dialog.closeAll();
      await this.optionBDecryption(this.indexOfContainer, binary, this.type);
    } catch (err) {
      this.disabled = false;
      this.openSnackBar('Not authorized!');
    }
  }

  isPassword() {
    this.show = !this.show;
  }

  openDeleteDialog(container: any): void {
    if(container.type == 'own'){
      this.indexOfContainer = this.ownContainers.findIndex((el: any) => el.id === container.id);

    }else if(container.type == 'backup'){
      this.indexOfContainer = this.deadManSwitchContainers.findIndex((el: any) => el.id === container.id);

    }
    this.selectedContainer = container;
    this.dialog.open(this.deleteDialog, { width: '400px' });
  }


moveToTrashContainer() {
    this.deletionInProgress = true;
    this.disabled = true;
      this.containerService.moveToTrashContainer(this.selectedContainer.id)
        .subscribe({
          next: async (res: any) => {
            let currentData = this.selectedContainer.type == 'own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getDeadManSwitchContainers();
            currentData.splice(this.indexOfContainer, 1);
            this.deletionInProgress = false;
            this.dialog.closeAll();
            this.disabled = false;
            if(this.selectedContainer.type == 'own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
            }else if(this.selectedContainer.type == 'backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
            }
            this.openSnackBar('Container moved to trash!');
          },
          error: (error: HttpErrorResponse) => {
            this.disabled = false;
            this.openSnackBar('Cannot delete container!');
          }
        });
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    const fileTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/ico',
      'image/svg+xml',
    ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000;

    if (file) {
      if (!validFileType(file.type))
        return this.openSnackBar(
          'You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!'
        );

      if (!validSize(file.size))
        return this.openSnackBar(
          'You need to upload an image with a size at most 10 KB!'
        );

      const formData = new FormData();
      formData.append('icon', file);
      this.media.saveIcon(formData).subscribe({
        next: (res: any) => {
          let my_data = new Uint8Array(JSON.parse(res.data).data);
          let string_char = my_data.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(string_char);
          this.preview = this.sanitizer.bypassSecurityTrustUrl(
            'data:' + res.type + ';base64,' + base64String
          );
          this.src = this.preview;
          this.containerToEdit.icon = res.id;
          this.openSnackBar('File uploaded successfully!');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Cannot save icon!');
        },
      });
    }
  }

  openIconsDialog(){
    this.dialogRef = this.dialog.open(this.IconsDialog);
  }

  selectIcon(icon: any): void {
    this.preview = icon.src;
    this.src = icon.src;
    this.containerToEdit.icon = icon.icon;
  }

  openEditDialog(container){
    this.selectedContainer = container;
    if(container.type == 'own'){
      this.indexOfContainer = this.ownContainers.findIndex((el: any) => el.id === container.id);

    }else if(container.type == 'backup'){
      this.indexOfContainer = this.deadManSwitchContainers.findIndex((el: any) => el.id === container.id);

    }
    this.containerToEdit = {name: container.name, description: container.description, containerID: container.id, icon: container.icon};
    this.preview = container.iconData;
    this.dialog.open(this.editDialog, { width: '500px' });
  }

  closeIconDialog(){
    if(this.dialogRef) this.dialogRef.close();
  }

  saveEditInfoContainer(){
    this.disabled = true;
    this.containerService
        .updateContainerInfo(this.containerToEdit)
        .subscribe({
          next: async (res: any)=> {
            let currentData = this.selectedContainer.type=='own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getDeadManSwitchContainers();
            currentData[this.indexOfContainer] = {...currentData[this.indexOfContainer], name: this.containerToEdit.name, description: this.containerToEdit.description, iconData: this.containerService.setIcon(res.icon), icon: res.icon.id};
            this.openSnackBar('Container Info updated successfully!');
            this.dialog.closeAll();
            this.disabled = false;
            if(this.selectedContainer.type=='own'){
                this.dexieService.setOwnContainers(currentData);
                this.containerService.setOwnContainers(currentData);
            }else if (this.selectedContainer.type=='backup'){
                this.dexieService.setDeadManSwitchContainers(currentData);
                this.containerService.setDeadManSwitchContainers(currentData);
            }
          }, 
          error: (err: any) => {
              console.log(err);
          }
        });
  }

  openShareDialog(container) {
      this.selectedContainer = container;
        this.dialog.open(this.shareDialog, { width: '500px' });
        this.loadingRecipients = true;
        if (container.type == 'own') {
          this.indexOfContainer = this.ownContainers.findIndex((el: any) => el.id === container.id);
          this.containerService.getRecipientsData(this.ownContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        } else if (container.type == 'backup') {
          this.indexOfContainer = this.deadManSwitchContainers.findIndex((el: any) => el.id === container.id);
          this.containerService.getRecipientsData(this.deadManSwitchContainers[this.indexOfContainer].recipients)
          .subscribe({
            next: (res: any) => {
              this.recipients = res.recipients;
              this.loadingRecipients = false;
            }, error: (err: any) => {
              console.log(err);
            }
          })
        }
        
  }

  addRecipient(){
    if(this.newRecipientEmail.length==0){
        this.openSnackBar('Please enter an email address');
    }else if (!this.newRecipientEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
        this.openSnackBar('Please enter a valid email for recipient!');

    } else if(this.recipients.findIndex((e)=>e.email==this.newRecipientEmail)!=-1){
        this.openSnackBar('Already added as a recipient!');

    } else if(this.newRecipientEmail===this.email){
        this.openSnackBar('You cannot add yourself as a recipient!');

    } else if(this.plan.recipients !== 'unlimited' && this.recipients.length + 1 > Number(this.plan.recipients)) {
        this.openSnackBar( `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!` );
    } else {
        
          if(this.selectedContainer.type=='own' && this.selectedContainer.ownerEncryptionMode==1){
                    this.disabled = true;
                    this.containerService.addRecipient(this.newRecipientEmail, this.selectedContainer.id, null, '-')
                      .subscribe({
                        next: (res)=> {
                            if(res!=null){
                                this.recipients.push(res);
                            }
                            this.dexieService.getOwnContainers().then((data: any) => {
                                let d = data;
                                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                                this.dexieService.setOwnContainers(d);
                                this.containerService.setOwnContainers(d);
                                this.disabled = false;
                                this.newRecipientEmail = '';
                                this.dialog.closeAll();
                            });

                        }, error: (err) => {
                          console.log(err);
                        }
                      })
                  } else {
                    // ! we will select the assignment type of the recipient
                      this.sharing = true;
                      if(this.selectedContainer.type=='own' && this.selectedContainer.ownerEncryptionMode==2){
                        this.key = new Uint8Array(JSON.parse(this.selectedContainer.ownerKey).data);
                        this.dialog.open(this.passphraseDialog, {width: '400px'});
                      }else if(this.selectedContainer.type=='own' && this.selectedContainer.ownerEncryptionMode==3){
                        this.key = new Uint8Array(JSON.parse(this.selectedContainer.ownerKey).data);
                        this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                      } else if(this.selectedContainer.type=='backup' && this.selectedContainer.backUpPersonEncryptionMode==2){
                        this.key = new Uint8Array(JSON.parse(this.selectedContainer.backUpPersonKey).data);
                        this.dialog.open(this.passphraseDialog, {width: '400px'});
                      } else if(this.selectedContainer.type=='backup' && this.selectedContainer.backUpPersonEncryptionMode==3){
                        this.key = new Uint8Array(JSON.parse(this.selectedContainer.backUpPersonKey).data);
                        this.dialog.open(this.hardwareKeyDialog, {width: '400px'});
                      }
                  }
      }
  } 

  removeRecipient(recipient){
    this.containerService.removeRecipient(recipient.email, this.selectedContainer.id)
          .subscribe({
            next: async (res: any) => {
              let currentData: any = this.selectedContainer.type =='own' ? await this.dexieService.getOwnContainers() : await this.dexieService.getSharedContainers();
              currentData[this.indexOfContainer].recipients = currentData[this.indexOfContainer].recipients.filter(r => r!= recipient.email);
              this.recipients = this.recipients.filter(r => r.email!= recipient.email);
              if (this.selectedContainer.type == 'own') {
                  this.dexieService.setOwnContainers(currentData);
                  this.containerService.setOwnContainers(currentData);
              } else if (this.selectedContainer.type == 'backup') {
                  this.dexieService.setDeadManSwitchContainers(currentData);
                  this.containerService.setDeadManSwitchContainers(currentData);
              }
              this.openSnackBar('Recipient removed');
            }, error: (err: any) => {
              console.log(err);
            }
          })
  }

  toggleActiveA(): void {
    this.activeA = true;
    this.activeB = false;
    this.activeC = false;
  }

  toggleActiveB(): void {
    this.activeB = true;
    this.activeA = false;
    this.activeC = false;
  }

  toggleActiveC(): void {
    this.activeC = true;
    this.activeA = false;
    this.activeB = false;
  }

  selectAssignment(){
    this.dialog.closeAll();
    if(this.activeA){
      this.dialog.open(this.addHardwareKeyDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabled = true;
          this.confirm = false;
        }
      });

    }else if(this.activeB){
      this.dialog.open(this.addPassphraseDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabled = true;
          this.confirm = false;
        }
      });

    }else{
      this.dialog.open(this.addAnswerQuestionDialog, {width: '500px'}).afterClosed().subscribe(async (result) => {
        if(this.confirm){
          this.disabled = true;
          this.confirm = false;
        }
      });
    }
  }

  async addPassphrase(){
    if(this.passphraseForRecipientValue.length<6){
        this.openSnackBar('Please a correct passphrase!');
        
    }else if(this.passphraseForRecipientValue!=this.confirmPassphraseForRecipientValue ){
        this.openSnackBar('Passphrases are not matching!');
    } else {
        this.disabled = true;
        let encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, await this.encryptDecrypt.getPBKDF2Hash1M(this.passphraseForRecipientValue.trim()));
        let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
        let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
        this.containerService.addRecipient(this.newRecipientEmail, this.selectedContainer.id, recipientKey, 'passphrase')
          .subscribe({
            next: (res)=> {
                if(this.selectedContainer.type=='own'){
                  this.dexieService.getOwnContainers().then((data: any) => {
                    let d = data;
                    d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedOwnerKey: binary, decrypted: true};
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.dialog.closeAll();
                    this.newRecipientEmail = '';
                    this.passphraseForRecipientValue = '';
                    this.confirmPassphraseForRecipientValue = '';
                    this.confirm = true;
                    this.disabled = false;
                  });
                  if(res!=null){
                    this.recipients.push(res);
                  }
                }else if(this.selectedContainer.type=='backup'){
                  this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                    let d = data;
                    d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedBackupPersonKey: binary, decrypted: true};
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.dialog.closeAll();
                    this.newRecipientEmail = '';
                    this.passphraseForRecipientValue = '';
                    this.confirmPassphraseForRecipientValue = '';
                    this.confirm = true;
                    this.disabled = false;
                  });
                  if(res!=null){
                    this.recipients.push(res);
                  }
                }

            }, error: (err) => {
              console.log(err);
            }
          })
        }
  }

  async addHardwareKey(){
    this.disabled = true;
    if(this.hardwareKeyForRecipientValue.length>0){
      let encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.hardwareKeyForRecipientValue.trim().slice(0, 12));
      let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
      let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
      this.containerService.addRecipient(this.newRecipientEmail, this.selectedContainer.id, recipientKey, 'hardwareKey')
          .subscribe({
            next: (res)=> {
                if(this.selectedContainer.type=='own'){
                  this.dexieService.getOwnContainers().then((data: any) => {
                    let d = data;
                    d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedOwnerKey: binary, decrypted: true};
                    this.dexieService.setOwnContainers(d);
                    this.containerService.setOwnContainers(d);
                    this.dialog.closeAll();
                    this.newRecipientEmail = '';
                    this.passphraseForRecipientValue = '';
                    this.confirmPassphraseForRecipientValue = '';
                    this.confirm = true;
                    this.disabled = false;
                  });
                  if(res!=null){
                    this.recipients.push(res);
                  }
                }else if(this.selectedContainer.type=='backup'){
                  this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                    let d = data;
                    d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                    d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedBackupPersonKey: binary, decrypted: true};
                    this.dexieService.setDeadManSwitchContainers(d);
                    this.containerService.setDeadManSwitchContainers(d);
                    this.dialog.closeAll();
                    this.newRecipientEmail = '';
                    this.passphraseForRecipientValue = '';
                    this.confirmPassphraseForRecipientValue = '';
                    this.confirm = true;
                    this.disabled = false;
                  });
                  if(res!=null){
                    this.recipients.push(res);
                  }
                }

            }, error: (err) => {
              console.log(err);
            }
          });
    }else{
      this.openSnackBar('Hardware Key is required!');
    }
  }

  async addAnswerQuestion(){
    this.disabled = true;
    if(this.answerForRecipientValue.length>0){
      let encryptedKey = await this.encryptDecrypt.encryptKey(this.decrypted, this.answerForRecipientValue.trim().toLowerCase())
      let binary = this.encryptDecrypt.bufferToBinary(this.decrypted);
      let recipientKey = JSON.stringify(this.encryptDecrypt.toBuffer(encryptedKey));
      this.containerService.addRecipient(this.newRecipientEmail, this.selectedContainer.id, recipientKey, 'questionAnswer')
      .subscribe({
        next: (res)=> {
            if(this.selectedContainer.type=='own'){
              this.dexieService.getOwnContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedOwnerKey: binary, decrypted: true};
                this.dexieService.setOwnContainers(d);
                this.containerService.setOwnContainers(d);
                this.dialog.closeAll();
                this.newRecipientEmail = '';
                this.passphraseForRecipientValue = '';
                this.confirmPassphraseForRecipientValue = '';
                this.confirm = true;
                this.disabled = false;
              });
              if(res!=null){
                this.recipients.push(res);
              }
            }else if(this.selectedContainer.type=='backup'){
              this.dexieService.getDeadManSwitchContainers().then((data: any) => {
                let d = data;
                d[this.indexOfContainer].recipients = [...d[this.indexOfContainer].recipients, this.newRecipientEmail];
                d[this.indexOfContainer] = {...d[this.indexOfContainer], decryptedBackupPersonKey: binary, decrypted: true};
                this.dexieService.setDeadManSwitchContainers(d);
                this.containerService.setDeadManSwitchContainers(d);
                this.dialog.closeAll();
                this.newRecipientEmail = '';
                this.passphraseForRecipientValue = '';
                this.confirmPassphraseForRecipientValue = '';
                this.confirm = true;
                this.disabled = false;
              });
              if(res!=null){
                this.recipients.push(res);
              }
            }

        }, error: (err) => {
          console.log(err);
        }
      });
    }else{
      this.openSnackBar('Please answer the question!');
    }
  }
}
