import { Component } from '@angular/core';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-loggin-history',
  templateUrl: './loggin-history.component.html',
  styleUrl: './loggin-history.component.scss'
})
export class LogginHistoryComponent {
  loading = true;
  logs = [];
  constructor(private activityLogsService: ActivityLogsService, private localstorageService: LocalStorageService){
      let userId = JSON.parse(this.localstorageService.getUser())['id'];
      this.activityLogsService.getLogs(userId).subscribe({
        next: (data: any) =>{
          this.logs = data;
          this.loading = false;
        }, error: (err: any) =>{
          console.log('Error: ', err);
        }
      });
  }
}
